import { LoadingContainer, Ball } from './style';

interface LoadingProps {
  loadingColor?: 'orange' | 'white';
}

export const LoadingIcon: React.FC<LoadingProps> = ({ loadingColor }) => {
  return (
    <LoadingContainer>
      <Ball loadingcolor={loadingColor} />
      <Ball loadingcolor={loadingColor} />
      <Ball loadingcolor={loadingColor} />
      <Ball loadingcolor={loadingColor} />
    </LoadingContainer>
  );
};
