import { useEffect, useState } from 'react';
import { Header } from '../../components/header';
import { Button } from '../../components/button';
import { Footer } from '../../components/footer';

import CrossFit from '../../assets/images/partners/cross-fit-barigui-logo.jpeg';
import PowerCamp from '../../assets/images/partners/power-camp-logo.jpeg';
import Lequipe from '../../assets/images/partners/lequipe-logo.jpeg';
import RiseBootcamp from '../../assets/images/partners/rise-bootcamp-logo.jpeg';
import SkeletonPartnersPageLoad from '../../components/SkeletonLoad/PartnersLoad';

import {
  Container,
  LoadingContainer,
  ListContainer,
  CardContainer,
  Main,
  CardFooter,
} from './style';

const Partners: React.FC = () => {
  const [isloading, setIsLoading] = useState(true);
  const [images, setImages] = useState(['']);

  useEffect(() => {
    const imagesArray = [CrossFit, PowerCamp, Lequipe, RiseBootcamp];
    setImages(imagesArray);
    setTimeout(() => setIsLoading(false), 1500);
  }, []);

  return (
    <>
      <Header />
      <Container>
        <h1>Conheça nossos Parceiros</h1>
        <LoadingContainer
          style={{
            display: isloading ? '' : 'none',
          }}
        >
          <SkeletonPartnersPageLoad />
          <SkeletonPartnersPageLoad />
        </LoadingContainer>
        <ListContainer
          style={{
            display: isloading ? 'none' : '',
          }}
        >
          <CardContainer>
            <Main>
              <img
                src={images[0]}
                alt="Avatar"
                onLoadedDataCapture={() => setIsLoading(false)}
              />

              <p>
                Desde 2013 contamos com um time de coaches renomados na
                modalidade, uma estrutura completa com equipamentos de primeira
                linha e um ambiente com características que vão transformar seu
                treino no momento mais esperado do dia.
                <br /> <br /> Temos unidades nos bairros Champagnat e Juvevê,
                dando assim duas opções de alto nível para nossos clientes
                treinarem. Ambas as unidades possuem estacionamento gratuito
                próprio ou conveniado e vestiários completos. <br /> Eventos e
                competições internas tornam a experiência de treinar aqui ainda
                mais agradável.
              </p>
            </Main>
            <CardFooter>
              <a
                href="https://crossfitbarigui.com.br/"
                target="_blank"
                rel="noreferrer"
              >
                <Button ButtonColor="blue500">Conhecer +</Button>
              </a>
            </CardFooter>
          </CardContainer>

          <CardContainer>
            <Main>
              <img src={images[1]} alt="Avatar" />

              <p>
                Criamos uma metodologia de treino em grupo que une o que há de
                melhor em ganho de força e de massa muscular. Tudo com a
                orientação de professores e o acompanhamento de um sistema que
                indicará qual carga usar desde o primeiro treino. <br /> Seja
                para se manter saudável, evoluir no seu esporte ou conquistar o
                corpo que sempre sonhou, ficar mais forte é indispensável.
                Alcançar seus objetivos nunca foi tão fácil. <br />
                <br />
                EMPOWER YOUR BODY. BE STRONGER.
              </p>
            </Main>
            <CardFooter>
              <a
                href="https://powercampbrasil.com.br/ "
                target="_blank"
                rel="noreferrer"
              >
                <Button ButtonColor="blue500">Conhecer +</Button>
              </a>
            </CardFooter>
          </CardContainer>

          <CardContainer>
            <Main>
              <img src={images[2]} alt="Avatar" />

              <p>
                Compartilhamos uma paixão. Respiramos e vivemos o ciclismo.
                Unindo o treinamento com potência e o acompanhamento
                individualizado, iremos quebrar barreiras e desafiar seus
                próprios limites. A evolução que você procura está aqui. Juntos,
                podemos ir mais rápido e mais longe. <br />
                <br /> Faça parte do nosso pelotão!
              </p>
            </Main>
            <CardFooter>
              <a href="https://lequipe.cc/" target="_blank" rel="noreferrer">
                <Button ButtonColor="blue500">Conhecer +</Button>
              </a>
            </CardFooter>
          </CardContainer>

          <CardContainer>
            <Main>
              <img src={images[3]} alt="Avatar" />

              <p>
                Com uma metodologia inovadora e baseada nos estudos de
                fortalecimento e queima calórica mais atuais no mundo fitness,
                treinar no Rise Bootcamp fará com que você conquiste o corpo que
                sempre sonhou além de melhorar seu condicionamento
                cardiovascular, sua performance esportiva e se divertir no que
                há de mais novo em treinamento esportivo.
              </p>
            </Main>
            <CardFooter>
              <a
                href="https://risebootcamp.com.br/"
                target="_blank"
                rel="noreferrer"
              >
                <Button ButtonColor="blue500">Conhecer +</Button>
              </a>
            </CardFooter>
          </CardContainer>
        </ListContainer>
      </Container>
      <Footer />
    </>
  );
};

export default Partners;
