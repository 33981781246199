import { useEffect, useState } from 'react';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import api from '../../../services/api';

import FeedBackFormModal from './feedBackModal';
import { StarRate } from '../../../components/starrate';
import { Button } from '../../../components/button';

import {
  FeedbackDataResponse,
  FeedbackProps,
} from '../../../types/hooksInterfaceTypes';

import {
  Background,
  AvaliationContainer,
  StarIcon,
  Container,
  CardContainer,
  BallonEffect,
} from './style';

interface FeedBackProps {
  avarage: number;
  avaliationsTotal: number;
}

export const Feedback: React.FC<FeedBackProps> = ({ avarage }) => {
  const [isNewTransactionModalOpen, setIsNewTransactionModalOpen] =
    useState(false);
  const { id } = useParams();
  const [userFeedback, setUserFeedback] = useState<FeedbackProps[]>([]);

  useEffect(() => {
    api
      .get<FeedbackDataResponse>(`/nutritionist/${id}/review`)
      .then((response) => {
        setUserFeedback(response.data.reviews);
      })
      .catch(() => {
        console.log('No reviews are founded');
      });
  }, [id]);

  function handleOpenNewFeedbackModal() {
    setIsNewTransactionModalOpen(true);
  }

  function handleCloseNewFeedbackModal() {
    setIsNewTransactionModalOpen(false);
  }

  return (
    <Background>
      <AvaliationContainer>
        <h1>Minhas Avaliações</h1>
        {!avarage ? (
          <h2 style={{ fontSize: 24 }}>
            Parece que esse Nutri ainda não possui avaliações 🙁
          </h2>
        ) : (
          <h2>
            {avarage.toFixed(1)}
            <StarIcon icon={faStar} />
            <span> {userFeedback.length} avaliações</span>
          </h2>
        )}
      </AvaliationContainer>
      <Container>
        {userFeedback.map((item) => (
          <CardContainer key={item.review_id}>
            <h2>Nome Completo</h2>
            <p>{item.reviewerFullName}</p>
            <br />
            <h2>Avaliação</h2>
            <p>{item.avaliation}</p>
            <br />
            <StarRate rate={item.rating} disabled />

            <BallonEffect />
          </CardContainer>
        ))}
        <Button ButtonColor="yellow" onClick={handleOpenNewFeedbackModal}>
          Avaliar
        </Button>
      </Container>
      <FeedBackFormModal
        openModal={isNewTransactionModalOpen}
        onCloseModal={handleCloseNewFeedbackModal}
      />
    </Background>
  );
};
