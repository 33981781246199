import { useCallback, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { faX } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import * as Yup from 'yup';

import { useParams } from 'react-router-dom';
import { ControllerInput } from '../../../../components/controllerInput';
import { ControllerTextArea } from '../../../../components/controllerTextArea';
import { Button } from '../../../../components/button';
import { StarRate } from '../../../../components/starrate';
import { FeedbackProps } from '../../../../types/hooksInterfaceTypes';

import getValidationErrors from '../../../../utils/validateErrors';
import ModelSuccessConfirmation from '../../../../components/successModal';
import { useSignUpInfo } from '../../../../hooks/SignUpContext';
import PlataformModalTerms from '../../../../components/termsModal';
import PlataformPrivicyTerms from '../../../../components/privicyPolicy';

import {
  Container,
  CloseModalButton,
  Main,
  Footer,
  AcceptTermsContainer,
  Terms,
  CloseIcon,
  AnimationContainer,
} from './style';
import api from '../../../../services/api';

interface FeedBackModalProps {
  openModal: boolean;
  onCloseModal: () => void;
}

const FeedBackFormModal: React.FC<FeedBackModalProps> = ({
  openModal,
  onCloseModal,
}) => {
  const formRef = useRef<FormHandles>(null);
  const [termsAccepted, setTermsAccepted] = useState(true);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [isPrivicyModalOpen, setIsPrivicyModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { starRate } = useSignUpInfo();
  const { id } = useParams();

  const handleFeedBackNewItem = useCallback(
    async (feedback: FeedbackProps) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          reviewerFullName: Yup.string().required(
            'Nome completo é obrigatório.',
          ),
          reviewerEmail: Yup.string().required('Email é obrigatório.'),
          avaliation: Yup.string().required('Avaliação é obrigatória.'),
        });
        await schema.validate(feedback, {
          abortEarly: false,
        });

        const newFeedback = { ...feedback, rating: starRate };
        api.post(`/nutritionist/${id}/review`, newFeedback);

        setIsModalOpen(true);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const err = getValidationErrors(error);

          formRef.current?.setErrors(err);
        }
      }
    },
    [id, starRate],
  );

  function handleCloseFeedbackModal() {
    setTermsAccepted(true);
    setIsModalOpen(false);
    onCloseModal();
  }

  return (
    <Modal
      isOpen={openModal}
      onRequestClose={handleCloseFeedbackModal}
      closeTimeoutMS={200}
      className="react-modal-content"
      overlayClassName="react-modal-overlay"
    >
      <AnimationContainer initial={{ scale: 0.9 }} animate={{ scale: 1 }}>
        <Container ref={formRef} onSubmit={handleFeedBackNewItem}>
          <CloseModalButton type="button" onClick={handleCloseFeedbackModal}>
            <CloseIcon icon={faX} />
          </CloseModalButton>
          <Main>
            <h1>Como foi sua consulta comigo?</h1>
            <ControllerInput
              type="text"
              name="reviewerFullName"
              label="Nome Completo"
            />

            <ControllerInput type="email" name="reviewerEmail" label="Email" />
            <ControllerTextArea
              name="avaliation"
              label="Avaliação"
              maxLength={250}
            />
            <StarRate />
          </Main>
          <Footer>
            <AcceptTermsContainer>
              <Terms>
                <ControllerInput type="checkbox" name="emailLead" />
                <h3>Aceito receber emails da Health to Go!</h3>
              </Terms>
              <Terms>
                <ControllerInput
                  type="checkbox"
                  name="termsAccepted"
                  onChange={(e) => setTermsAccepted(!e.target.checked)}
                />
                <h3>
                  Li e{' '}
                  <button
                    type="button"
                    onClick={() => setIsTermsModalOpen(true)}
                  >
                    Aceito os Termos de Uso
                  </button>{' '}
                  e{' '}
                  <button
                    type="button"
                    onClick={() => setIsPrivicyModalOpen(true)}
                  >
                    Privacidade
                  </button>{' '}
                  da plataforma.
                </h3>
              </Terms>
            </AcceptTermsContainer>
            <Button
              type="submit"
              ButtonColor="blue500"
              disabled={termsAccepted}
            >
              Enviar
            </Button>
          </Footer>
        </Container>
      </AnimationContainer>
      <PlataformModalTerms
        isOpen={isTermsModalOpen}
        onClick={() => setIsTermsModalOpen(false)}
      />
      <PlataformPrivicyTerms
        isOpen={isPrivicyModalOpen}
        onClick={() => setIsPrivicyModalOpen(false)}
      />
      <ModelSuccessConfirmation
        title="Avaliação enviada!"
        description="Seu feedback logo será publicado no perfil do profissional"
        isOpen={isModalOpen}
        onClick={handleCloseFeedbackModal}
      />
    </Modal>
  );
};

export default FeedBackFormModal;
