import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useMemo,
} from 'react';
import api from '../services/api';

import {
  AuthContextProvider,
  DataState,
  SignInProps,
  UserData,
} from '../types/hooksInterfaceTypes';

const AuthContext = createContext<AuthContextProvider>(
  {} as AuthContextProvider,
);

export const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<DataState>(() => {
    const user = localStorage.getItem('@HealthToGo:user');
    const userSession = sessionStorage.getItem('@HealthToGo:user');

    if (user) {
      return { user: JSON.parse(user) };
    }
    if (userSession) {
      return { user: JSON.parse(userSession) };
    }

    return {} as DataState;
  });
  const [isEditingProfile, setIsEditingProfile] = useState(false);
  const [isProfileChanged, setIsProfileChanged] = useState(false);

  const SignIn = useCallback(
    async ({ email, password, reminderMe }: SignInProps) => {
      const response = await api.post<UserData>('/login', {
        email,
        password,
      });

      const user = response.data;

      if (reminderMe) {
        localStorage.setItem('@HealthToGo:user', JSON.stringify(user));
      }

      sessionStorage.setItem('@HealthToGo:user', JSON.stringify(user));

      setData({ user });
    },
    [],
  );

  const SignOut = useCallback(() => {
    setData({} as DataState);
    sessionStorage.removeItem('@HealthToGo:user');
    localStorage.removeItem('@HealthToGo:user');
    api.post(
      'logout',
      {},
      {
        headers: {
          Authorization: data.user.token,
        },
      },
    );
  }, [data]);

  const HandleCompleteProfileModalState = useCallback(async () => {
    const updatedUser = {
      user: {
        ...data.user,
        completedProfile: true,
      },
    };

    setData(updatedUser);
  }, [data]);

  const StartEditingProfile = useCallback(() => {
    setIsEditingProfile(true);
  }, []);

  const CancelEditingProfile = useCallback(() => {
    setIsEditingProfile(false);
  }, []);

  const SaveProfileChanges = useCallback(() => {
    setIsEditingProfile(false);
    setIsProfileChanged(!isProfileChanged);
  }, [isProfileChanged]);

  const UpdateAvatar = useCallback(
    (image: string) => {
      const userData = localStorage.getItem('@HealthToGo:user');

      const user = {
        ...data.user,
        image,
      };

      if (userData) {
        localStorage.setItem('@HealthToGo:user', JSON.stringify(user));
      }

      if (!userData) {
        sessionStorage.setItem('@HealthToGo:user', JSON.stringify(user));
      }

      setData({
        user,
      });
    },
    [data],
  );

  const providerHooks = useMemo(
    () => ({
      user: data.user,
      isEditingProfile,
      isProfileChanged,
      HandleCompleteProfileModalState,
      StartEditingProfile,
      CancelEditingProfile,
      SaveProfileChanges,
      SignIn,
      SignOut,
      UpdateAvatar,
    }),
    [
      data,
      isEditingProfile,
      isProfileChanged,
      HandleCompleteProfileModalState,
      StartEditingProfile,
      CancelEditingProfile,
      SaveProfileChanges,
      SignIn,
      SignOut,
      UpdateAvatar,
    ],
  );

  return (
    <AuthContext.Provider value={providerHooks}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextProvider {
  const context = useContext(AuthContext);

  if (!context) {
    console.log('Provider mus be used in a ContextProvider');
  }

  return context;
}
