import styled, { css } from 'styled-components';

interface InputContainerProps {
  haveError?: boolean;
  containerHeight?: number;
}

export const Container = styled.div<InputContainerProps>`
  width: 100%;
  position: relative;

  & + div {
    margin-top: 1.5rem;
  }

  textarea {
    display: flex;
    align-items: center;
    width: 100%;
    height: 6rem;
    border: none;
    border-radius: 0.5rem;
    padding: 0.5rem;
    margin-top: 0.3rem;

    font-size: 1.1rem;
    font-weight: 500;
    color: var(--text-title);

    resize: none;

    background-color: var(--card-border);

    ${(props) =>
      props.containerHeight &&
      css`
        height: ${props.containerHeight}rem;
      `}

    ${(props) =>
      props.haveError &&
      css`
        border: 2px solid var(--error-text);
      `}

    @media (max-width: 800px) {
      height: 17rem;
    }
  }
  span {
    position: absolute;
    color: var(--error-text);
    font-weight: 500;
    bottom: -1.5rem;
    right: 0;
  }
`;

export const LabelContainer = styled.label`
  font-weight: 600;
  font-size: 1.1rem;
  color: var(--text-body);
`;
