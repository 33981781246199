import styled from 'styled-components';

export const MapContainer = styled.map`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-height: 20rem;
  width: 100%;

  img {
    display: flex;
    width: 100%;
    max-width: 90rem;
    object-fit: contain;
  }

  @media (max-width: 800px) {
    img {
      max-width: 30rem;
    }
  }
`;
