import { useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import Logo from '../../assets/health_to_go_logo.png';

import getValidationErrors from '../../utils/validateErrors';

import { ControllerInput } from '../../components/controllerInput';
import { LoadingContext } from '../../hooks/LoadingContext';
import { Button } from '../../components/button';

import { Container, ArrowIcon, Main, ResponseContainer } from './style';
import api from '../../services/api';

interface FormData extends React.FormEvent {
  email: string;
}

const RememberPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { SetIsLoading } = LoadingContext();
  const navigate = useNavigate();
  const [responseSuccessful, setResponseSuccessful] = useState(false);
  const [responseError, setResponseError] = useState(false);

  async function RememberEmailForm(form: FormData) {
    try {
      SetIsLoading(true);
      const schema = Yup.object().shape({
        email: Yup.string()
          .required('Email é obrigatório.')
          .email('Digite um Email válido.'),
      });

      await schema.validate(form, {
        abortEarly: false,
      });

      await api.post('/requestresetpassword', form);

      SetIsLoading(false);
      setResponseSuccessful(true);
      return;
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const err = getValidationErrors(error);

        formRef.current?.setErrors(err);
        SetIsLoading(false);
        return;
      }
      SetIsLoading(false);
      setResponseError(true);
    }
  }

  const closeSuccessResponseComponent = () => {
    setResponseSuccessful(false);
    navigate('/signIn');
  };

  const closeErrorResponseComponent = () => {
    setResponseError(false);
  };

  return (
    <Container>
      <Link to="/signIn">
        <ArrowIcon icon={faArrowLeft} />
      </Link>
      <img src={Logo} alt="Health to Go" />
      {!(responseSuccessful || responseError) && (
        <Main ref={formRef} onSubmit={RememberEmailForm}>
          <h1>Recuperar senha</h1>
          <ControllerInput type="email" name="email" label="Email" />

          <Button type="submit" ButtonColor="blue">
            Enviar
          </Button>
        </Main>
      )}
      {responseSuccessful && (
        <ResponseContainer>
          <h1>Link enviado com sucesso!</h1>
          <h2>
            Verifique seu email, e siga as instruções para recuperar sua senha.
          </h2>

          <Button
            type="button"
            ButtonColor="blue"
            onClick={closeSuccessResponseComponent}
          >
            OK
          </Button>
        </ResponseContainer>
      )}
      {responseError && (
        <ResponseContainer>
          <h1>Ops... isso não deu certo.</h1>
          <h2>
            Por favor, verifique seus dados, e tente novamente <br />
            Caso o erro persista, entre em contato com nosso suporte.
          </h2>

          <Button
            type="button"
            ButtonColor="blue"
            onClick={closeErrorResponseComponent}
          >
            OK
          </Button>
        </ResponseContainer>
      )}
    </Container>
  );
};

export default RememberPassword;
