import { AuthProvider } from './AuthContext';
import { GetProfessionalsProvider } from './GetProfessionalsList';
import { LoadingContextProvider } from './LoadingContext';
import { ProfileMetricsContextProvider } from './MetricsContext';
import { SignUpProvider } from './SignUpContext';

export const ContextProvider: React.FC = ({ children }) => {
  return (
    <AuthProvider>
      <GetProfessionalsProvider>
        <ProfileMetricsContextProvider>
          <LoadingContextProvider>
            <SignUpProvider>{children}</SignUpProvider>
          </LoadingContextProvider>
        </ProfileMetricsContextProvider>
      </GetProfessionalsProvider>
    </AuthProvider>
  );
};
