import { Container, LabelContainer } from './style';

interface props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

export const UncontrollerInput: React.FC<props> = ({ label, ...rest }) => {
  return (
    <Container>
      <div>
        <LabelContainer>{label}</LabelContainer>
        <input {...rest} />
      </div>
    </Container>
  );
};
