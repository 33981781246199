import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { motion } from 'framer-motion';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 2.688rem;

  @media (max-width: 800px) {
    margin: 1.5rem;
  }
`;

export const CardContainer = styled.div`
  display: flex;

  background-color: var(--shape);
  width: 100%;
  max-width: 69.188rem;
  padding: 1.5rem 0;
  border-radius: 0.5rem;

  margin-bottom: 2.688rem;

  p,
  pre {
    margin-top: 1.688rem;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.625rem;
    color: var(--text-body);
    white-space: pre-wrap;
  }

  @media (max-width: 800px) {
    margin-bottom: 1.5rem;

    p,
    pre {
      font-size: 1rem;
      line-height: 1.3rem;
    }
  }
`;

export const ProfileHeader = styled.header`
  padding: 1.5rem 3.938rem;
  position: relative;
  width: 100%;

  @media (max-width: 800px) {
    padding: 2% 5%;
  }
`;

export const StatisticsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  h2 {
    font-weight: 700;
    font-size: 1.3rem;
    line-height: 1.563rem;

    color: var(--blue_500);
  }
  @media (max-width: 800px) {
    h2 {
      font-size: 1rem;
      line-height: 1.263rem;
    }
  }
`;
export const StatisticsInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin: 24px 0;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--card-border);

  h3,
  span {
    font-size: 1.1rem;
    line-height: 1.325rem;
    margin-top: 0.5rem;
    color: var(--text-title);

    span {
      color: var(--orange_500);
      font-weight: 600;
    }
  }

  @media (max-width: 800px) {
    margin: 6px 0 12px;
    padding-bottom: 12px;
    h3,
    span {
      font-size: 0.9rem;
      line-height: 1.225rem;
      margin-top: 0.5rem;
    }
  }
`;

export const ProfileOptions = styled.button`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  right: 2%;
  top: 0.5%;
  width: 1.5rem;
  height: 2rem;
  background: none;
  border: none;
`;

export const CircleBlue = styled.div`
  border-radius: 50%;
  width: 0.5rem;
  height: 0.5rem;
  background-color: var(--blue_300);
  margin-bottom: 0.25rem;
`;
export const CircleYellow = styled.div`
  border-radius: 50%;
  width: 0.5rem;
  height: 0.5rem;
  background-color: var(--yellow_500);
  margin-bottom: 0.25rem;
`;
export const CircleOrange = styled.div`
  border-radius: 50%;
  width: 0.5rem;
  height: 0.5rem;
  background-color: var(--orange_500);
`;

export const ProfileOptionsDropDown = styled(motion.div)`
  position: absolute;
  right: 2%;
  top: 10%;

  width: 8rem;
  background-color: var(--blue_300);
  border-radius: 0.5rem;
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
  overflow: hidden;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    color: var(--white-text);
    width: 100%;
    height: 2.5rem;

    & + button {
      border-top: 1px solid var(--white-text);
    }

    :hover {
      filter: brightness(1);
      background-color: var(--blue_500);
    }
  }

  @media (max-width: 800px) {
    right: 2%;
    top: 12%;
  }
`;

export const ProfessionalInfoContainer = styled.div`
  display: grid;
  grid-template-areas:
    'profile profile social'
    'profile profile info';

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
  }
`;

export const ProfessionalDetails = styled.div`
  grid-area: profile;
  display: flex;

  align-items: center;

  img {
    width: 11rem;
    height: 11rem;
    border: solid 0.375rem var(--blue_500);
    border-radius: 50%;
    object-fit: cover;
  }

  div {
    margin-left: 2rem;

    h1 {
      font-weight: 700;
      font-size: 1.75rem;
      line-height: 1.563rem;

      color: var(--blue_500);
    }

    h2,
    h3 {
      font-size: 1.25rem;
      line-height: 1.625rem;
      margin-top: 0.5rem;

      color: var(--orange_500);
    }

    h3 {
      font-weight: 700;
      color: var(--text-title);
      display: flex;
      flex-direction: column;

      span {
        color: var(--blue_500);
        margin-left: 4%;
      }
    }
  }
  @media (max-width: 800px) {
    img {
      width: 8rem;
      height: 8rem;
    }

    div {
      margin-left: 1rem;

      h1 {
        font-size: 1.25rem;
        line-height: 1.263rem;
      }

      h2,
      h3 {
        font-size: 0.95rem;
        line-height: 1.225rem;
        margin-top: 0.5rem;
      }
    }
  }
`;

export const SocialMedia = styled.div`
  grid-area: social;
  display: flex;
  margin-top: 1.25rem;
  justify-content: end;

  a {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: end;
  }

  h3 {
    font-size: 1rem;
    margin-top: 0.5rem;
    color: var(--text-title);
  }

  @media (max-width: 800px) {
    justify-content: start;
    a {
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    h3 {
      margin: 0 0 0 0.5rem;
    }
  }
`;

export const InstagramIcon = styled(FontAwesomeIcon)`
  width: 1.875rem;
  height: 1.875rem;
  color: var(--text-title);

  @media (max-width: 800px) {
    width: 1rem;
    height: 1rem;
  }
`;

export const MoreInfo = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  grid-area: info;

  div {
    display: flex;

    h3 {
      font-weight: 500;
      font-size: 1rem;
      color: var(--blue_500);
      text-align: end;
    }
    span {
      font-weight: 700;
      font-size: 1.02rem;

      color: var(--orange_500);

      margin-left: 0.5rem;
    }
  }

  a {
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-size: 1.1rem;
    color: var(--white-text);
    background: var(--blue_500);

    width: 100%;
    max-width: 25rem;
    height: 2.5rem;
    border-radius: 0.5rem;
    padding: 0.5rem;
    margin-top: 1.5rem;
  }

  @media (max-width: 800px) {
    align-items: flex-start;
    margin-top: 1.25rem;
  }
`;

export const ProfileBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2.563rem 3.938rem;
  width: 100%;

  div {
    margin: 1.5rem 0;
    padding-bottom: 1.5rem;
  }

  h1 {
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 1.563rem;
    color: var(--blue_500);
  }

  p,
  pre {
    margin-left: 20%;
  }

  @media (max-width: 800px) {
    padding: 0 1.5rem;
    div {
      border-bottom: 1px solid var(--card-border);
    }

    h1 {
      font-size: 1.35rem;
      line-height: 1.363rem;
    }

    p,
    pre {
      margin-left: 0;

      font-size: 1rem;
      line-height: 1.3rem;
    }
  }
`;

export const WhatsAppButton = styled(FontAwesomeIcon)`
  color: var(--white-text);
  height: 2rem;
`;

export const ContactContainer = styled.div`
  display: flex;
  align-items: center;

  a {
    position: static;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const Contact = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;

  h2,
  a {
    margin-top: 0.5rem;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.625rem;

    color: var(--text-title);
    margin-left: 20%;
  }

  div {
    margin: 0;
    padding: 0;

    border: none;

    p {
      margin-top: 0;
      margin-left: 23%;
    }

    @media (max-width: 800px) {
      h2 {
        margin-left: 0;

        h2 + div {
          margin-top: 0;
        }
      }
      p {
        margin-left: 3%;
      }
    }
  }
`;
