import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../services/api';

import { useAuth } from '../../../hooks/AuthContext';
import { ProfessionalsProfile } from '../../../types/hooksInterfaceTypes';

import { ProfileEdit } from './profileEdit';
import { ProfileView } from './profileView';
import { Feedback } from '../feedback';
import SkeletonProfilePageLoad from '../../../components/SkeletonLoad/ProfileLoad';

export const ProfileCard: React.FC = () => {
  const { isEditingProfile, user: sessionUser, isProfileChanged } = useAuth();
  const { id } = useParams();

  const [user, setUser] = useState<ProfessionalsProfile>(
    {} as ProfessionalsProfile,
  );
  const [isProfileOwner, setIsProfileOwner] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    api.get<ProfessionalsProfile>(`nutritionist/${id}`).then((response) => {
      const userData = {
        ...response.data,
        healthInsurance: response.data.healthInsurance ? 'Sim' : 'Não',
        onlineAppt: response.data.onlineAppt ? 'Sim' : 'Não',
      };

      if (sessionUser) {
        setIsProfileOwner(Number(id) === sessionUser.nutritionist_id);
      }

      setUser(userData);
      setIsLoading(false);
    });
  }, [id, sessionUser, isProfileChanged]);

  return (
    <>
      {isLoading && <SkeletonProfilePageLoad />}
      {isEditingProfile ? (
        <ProfileEdit user={user} token={sessionUser?.token} />
      ) : (
        <div
          style={{
            display: isLoading ? 'none' : '',
          }}
        >
          <ProfileView isProfileOwner={isProfileOwner} profileData={user} />
          <Feedback avarage={user.rating} avaliationsTotal={1} />
        </div>
      )}
    </>
  );
};
