import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { faRectangleList } from '@fortawesome/free-regular-svg-icons';

import NutritionistsClicks from '../nutritionistsClicks';
import Dashboard from '../dashboard';

import {
  BarsIcon,
  Container,
  NavContainer,
  NavigationTarget,
  PainelContainer,
  ThemeTarget,
  ThemeTargetContainer,
} from './style';

import Logo from '../../../assets/health_to_go_logo.png';

const AdminPainel: React.FC = () => {
  const navigate = useNavigate();
  const [selectedSetting, setSelectedSetting] = useState('Dashboard');
  const [selectedSettingIndex, setSelectedSettingIndex] = useState(0);
  const [selectedThemeSettings, setSelectedThemeSettings] = useState(false);

  const [hideNavBar, setHideNavbar] = useState(false);

  const SettingsComponents = [<Dashboard />, <NutritionistsClicks />];

  const HandleNavigation = (pageName: string, pageIndex?: number) => {
    setSelectedSetting(pageName);
    if (pageIndex || pageIndex === 0) {
      setSelectedSettingIndex(pageIndex);
    }
  };

  const HandleLogout = () => {
    navigate('/admin/painel', { replace: true });
  };

  return (
    <Container>
      <NavContainer isnavclose={hideNavBar ? 'true' : undefined}>
        <img src={Logo} alt="Health to Go" />
        <NavigationTarget
          onClick={() => HandleNavigation('Dashboard', 0)}
          isfocus={selectedSetting === 'Dashboard' ? 'true' : undefined}
        >
          <div />
          <h3>Inicio</h3>
        </NavigationTarget>

        <NavigationTarget
          onClick={() => {
            setSelectedThemeSettings(!selectedThemeSettings);
            HandleNavigation('Nutricionistas');
          }}
          isfocus={selectedSetting === 'Nutricionistas' ? 'true' : undefined}
        >
          <div />
          <h3>Nutricionistas</h3>
        </NavigationTarget>
        <ThemeTargetContainer
          isfocus={selectedThemeSettings ? 'true' : undefined}
        >
          <ThemeTarget
            isfocus={selectedSetting === 'Nutricionistas' ? 'true' : undefined}
            onClick={() => HandleNavigation('Nutricionistas', 1)}
          >
            <div />
            <h3>Acessos</h3>
          </ThemeTarget>
          <ThemeTarget isfocus={undefined}>
            <div />
            <h3>Ativos</h3>
          </ThemeTarget>
          <ThemeTarget isfocus={undefined}>
            <div />
            <h3>Leeds</h3>
          </ThemeTarget>
          <ThemeTarget isfocus={undefined}>
            <div />
            <h3>Todos</h3>
          </ThemeTarget>
        </ThemeTargetContainer>
        <NavigationTarget onClick={() => HandleLogout()} isfocus={undefined}>
          <div />
          <h3>Sair</h3>
        </NavigationTarget>
      </NavContainer>
      <PainelContainer isnavclose={hideNavBar ? 'true' : undefined}>
        <div className="painel-header">
          <button
            className="toggle-nav-button"
            type="button"
            onClick={() => setHideNavbar(!hideNavBar)}
          >
            <BarsIcon icon={faRectangleList} />
          </button>
          <h1 className="painel-title">{selectedSetting}</h1>
        </div>
        {SettingsComponents[selectedSettingIndex]}
      </PainelContainer>
    </Container>
  );
};

export default AdminPainel;
