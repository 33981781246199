import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useMemo,
} from 'react';
import api from '../services/api';

import {
  SignUpContextProvider,
  SignUpProps,
  PaymentProps,
} from '../types/hooksInterfaceTypes';

const AuthContext = createContext<SignUpContextProvider>(
  {} as SignUpContextProvider,
);

export const SignUpProvider: React.FC = ({ children }) => {
  const [data, setData] = useState({});
  const [signUpStep, setSignUpStep] = useState(0);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [starRate, setStarRate] = useState(0);

  const SetSignUpData = useCallback(
    async (signUpData: SignUpProps) => {
      try {
        if (signUpData.name) {
          setData(signUpData);
          return;
        }
        const { whatsApp, cpf, crn, email, password } = signUpData;
        // setSignUpStep(1);
        setData({ ...data, whatsApp, cpf, crn, email, password });
      } catch (err) {
        console.log(err);
        setSignUpStep(4);
      }
    },
    [data],
  );

  const AcceptSignUpTerms = useCallback(() => {
    setTermsAccepted(true);
    setSignUpStep(2);
  }, []);

  const SignUp = useCallback(
    async (formData, token) => {
      try {
        const { whatsApp, cpf, crn, email, password } = formData;
        await api.post(`/signup/${token}`, {
          ...data,
          whatsApp,
          cpf,
          crn: crn.replaceAll('_', ''),
          email,
          password,
        });

        setSignUpStep(3);
      } catch (err) {
        console.log(err);
        setSignUpStep(4);
      }
    },
    [data],
  );

  const SignUpConfirmationOut = useCallback(() => {
    setData({} as SignUpProps);
    setTermsAccepted(false);
    setSignUpStep(0);
  }, []);

  const HandleStarRate = useCallback((rate: number) => {
    setStarRate(rate + 1);
  }, []);

  const providerHooks = useMemo(
    () => ({
      signUpStep,
      SetSignUpData,
      AcceptSignUpTerms,
      SignUp,
      SignUpConfirmationOut,
      HandleStarRate,
      starRate,
    }),
    [
      signUpStep,
      AcceptSignUpTerms,
      SignUpConfirmationOut,
      SetSignUpData,
      SignUp,
      HandleStarRate,
      starRate,
    ],
  );

  return (
    <AuthContext.Provider value={providerHooks}>
      {children}
    </AuthContext.Provider>
  );
};

export function useSignUpInfo(): SignUpContextProvider {
  const context = useContext(AuthContext);

  if (!context) {
    console.log('Provider mus be used in a ContextProvider');
  }

  return context;
}
