import Modal from 'react-modal';

import { useCallback, useState } from 'react';
import SignUpTerms from '../../../../terms/ProfessionalTerms';

import { useSignUpInfo } from '../../../../hooks/SignUpContext';
import { Button } from '../../../../components/button';

import { Container, Footer, Terms } from './style';

const FormSignUpTerms: React.FC = () => {
  const { AcceptSignUpTerms, SignUpConfirmationOut } = useSignUpInfo();
  const [termsAccepted, setTermsAccepted] = useState(true);

  const handleNavigateToDashboard = useCallback(() => {
    AcceptSignUpTerms();
  }, [AcceptSignUpTerms]);

  const handleCloseModal = useCallback(() => {
    SignUpConfirmationOut();
  }, [SignUpConfirmationOut]);

  return (
    <Modal
      isOpen
      onRequestClose={handleCloseModal}
      className="react-modal-content"
      overlayClassName="react-modal-overlay"
    >
      <Container initial={{ scale: 0.9 }} animate={{ scale: 1 }}>
        <pre>{SignUpTerms}</pre>
        <Footer>
          <Terms>
            <input
              type="checkbox"
              onChange={(e) => setTermsAccepted(!e.target.checked)}
            />
            <h3>
              Li e <span>Aceito os termos de uso e privacidade</span> da
              plataforma.
            </h3>
          </Terms>
          <Button
            type="button"
            ButtonColor="blue500"
            onClick={handleNavigateToDashboard}
            disabled={termsAccepted}
          >
            Assinar Termos
          </Button>
        </Footer>
      </Container>
    </Modal>
  );
};

export default FormSignUpTerms;
