import { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import ReactInputMask, { Props as InputProps } from 'react-input-mask';

import { Container, LabelContainer } from './style';

interface props extends InputProps {
  name: string;
  label?: string;
}

export const ControllerMaskedInput: React.FC<props> = ({
  name,
  label,
  ...rest
}) => {
  const inputRef = useRef(null);

  const { defaultValue, fieldName, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref, value) {
        ref.setInputValue(value);
      },
      clearValue(ref) {
        ref.setInputValue('');
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container haveError={!!error}>
      <div>
        <LabelContainer>{label}</LabelContainer>
        <ReactInputMask ref={inputRef} defaultValue={defaultValue} {...rest} />
        {error && <span>{error}</span>}
      </div>
    </Container>
  );
};
