const UserTerms = `TERMOS E CONDIÇÕES GERAIS DE USO - USUÁRIO

1. HEALTH TO GO INTERMEDIAÇÃO DE SERVIÇOS LTDA., inscrita no CNPJ/MF sob o n° 44.233.296/0001-66, com sede na Rua Paulo Ziliotto, 85, 6º andar, apt 605 - Campina do Siqueira - CEP 80740-310, na cidade de Curitiba, Estado do Paraná, é uma pessoa jurídica de direito privado responsável pela plataforma doravante denominada Health to go (“Plataforma” ou “Health to go”).
2. Por intermédio destes Termos e Condições Gerais de Uso (“Termos”), a Health to go apresenta aos usuários (“Usuários”) ou ("Pacientes") as condições essenciais para o uso dos serviços oferecidos na Plataforma pelos ("Profissionais") ou ("Nutricionistas").
3.Ao utilizar a Plataforma ou os serviços ofertados pela Health to go, os Usuários aceitam e se submetem às condições destes Termos e às Políticas de Privacidade, bem como a todos os documentos anexos a estes.
A Health to go é uma plataforma que possibilita que usuários encontrem profissionais por meio de um ambiente virtual.
Os Profissionais são os únicos responsáveis pela veracidade e exatidão de suas informações e conteúdo e dos valores a serem cobrados por seus atendimentos, bem como de seu método de trabalho, de forma que a Health to go não se responsabilizará em razão de cancelamento, atraso, mudança de data ou horário de atendimento ou qualquer outro dano moral ou material ao Usuário, ao qual não tiver dado justa causa.

A Health to Go poderá coletar dados e informações pessoais quando inseridos ou submetidos voluntariamente, bem como durante a navegação na Plataforma e por meio do uso de cookies. A coleta de dados será realizada de acordo com o exposto em nossa política de privacidade.
Os dados e informações pessoais são coletados quando inseridos ou submetidos voluntariamente pelos Usuários ou Profissionais, durante a navegação da Plataforma e por meio de cookies.

Do Agendamento de Atendimentos
A Health to go não gerencia a disponibilidade dos Profissionais nem se responsabiliza pelo agendamento de suas consultas. Todas as informações sobre data, horário e local são de responsabilidade tão somente deste, sendo passadas ao usuário por meio de seu contato (telefone ou e-mail) disponibilizado na plataforma.
Considerando os critérios de informação escolhidos pelo Usuário, será apresentada uma lista de Profissionais, indicando informações sobre a experiência do profissional, modo de trabalho e o valor a ser cobrado pelo atendimento.
O critério de ordenação dos Profissionais, na lista prevista acima, será definido única e exclusivamente pela Health to go, através de algoritmo que pode levar em consideração critérios como especialização, índice de recomendação, índice acesso, escala de avaliação, entre outros.
O Usuário, interessado em agendar o serviço, deverá se manifestar entrando em contato diretamente com o profissional por meio do telefone ou e-mail disponibilizado em seu perfil. No caso de terceira pessoa ou dependente deverá o Usuário informar diretamente ao profissional através do telefone ou e-mail disponibilizado em seu perfil as informações necessárias para que o mesmo possa agendar o atendimento. Sendo que, a Health to go não se responsabiliza pela veracidade, exatidão, autenticidade ou atualização dos dados informados.
A Health to Go não se responsabiliza pela modalidade do atendimento, nem por eventuais atrasos, faltas, ou qualquer outra situação relacionada a prestação do atendimento ou seu agendamento. Portanto, a Health to Go não é responsável por eventuais perdas e danos suportados pela parte prejudicada pelo atraso.

Da avaliação de profissionais
A Health to go disponibiliza uma ferramenta para obtenção de avaliações do profissional em seu perfil, as quais são por usuários. A avaliação ocorre em caráter voluntário, sendo o usuário responsável pela sua identificação, caso deseje realizar uma avaliação. A Health to go não se responsabiliza pela verificação da veracidade das informações submetidas. A Health to go também não se responsabiliza pelo conteúdo submetido na avaliação, sendo este de responsabilidade do autor. A Health to go se mantém aberta caso o profissional julgue o conteúdo ofensivo ou desrespeitoso, podendo entrar em contato pelo e-mail atendimento@healthtogo.com.br para avaliação da questão por parte da Health to Go.  A Health to Go reserva um prazo de até 10 dias úteis para apuração da demanda realizada pelo profissional. O profissional não poderá, por conta própria, realizar a exclusão de uma avaliação de seu perfil, tarefa reservada a Health to go mediante avaliação do caso.

Isenções e limitações de responsabilidade da Health to go.
A Health to go respeita e obedece a todas as leis e regulamentos aplicáveis às suas atividades e espera que todos seus Usuários e Profissionais façam o mesmo. Entretanto, a Health to go não pode garantir e não assume nenhuma responsabilidade pela conformidade de seus Usuários e Profissionais com quaisquer leis ou regulamentos. Ao utilizar a Plataforma ou Serviços da Health to go, o Usuário declara estar ciente, entender e concordar que é exclusiva e totalmente responsável pelo cumprimento de todas e quaisquer leis, normas, regulamentos e obrigações legais e fiscais que possam ser aplicadas para ao seu uso da Plataforma, Serviços e Conteúdos.
A Health to go não garante a exatidão nem autenticidade das informações e conteúdos fornecidos pelos Usuários e Profissionais e não tem nenhuma obrigação de verificá-los ou monitorá-los. Entretanto, a Health to go reserva-se o direito de fazê-lo, diretamente ou através de fornecedores ou terceiros capacitados, para garantir a qualidade dos Serviços prestados e a conformidade com estes Termos ou para cumprir com a lei aplicável ou com ordem de algum órgão competente. Estas verificações incluem, mas não se limitam a:
a) verificar a exatidão, autenticidade e atualidade de informações de identificação pessoal;
b) verificar antecedentes acadêmicos, profissionais, criminais, de crédito, de reputação online ou qualquer outra informação que possa auxiliar a Health to go a avaliar a capacidade ou probabilidade do Usuário e do Profissionais honrarem com as responsabilidades assumidas perante a Health to go, Usuários e/ou Profissionais;
c) cessar, visualizar e monitorar todo e qualquer conteúdo postado ou transmitido pelo Usuário através da Plataforma ou Serviços. O Usuário, ao aceitar os presentes Termos de Uso, autoriza expressamente a realização da verificação por quaisquer das formas estabelecidas acima;
A Health to go não tem acesso e, portanto, não coleta e/ou armazena as informações fornecidas pelo Usuário diretamente aos Profissionais durante os atendimentos online e a eventuais anotações e relatórios de Usuários elaborados pelos Profissionais durante os atendimentos. É dever do Profissional realizar a devida coleta e armazenamento das informações fornecidas pelo Usuário. Portanto, é de responsabilidade do próprio Profissional assegurar a segurança das informações fornecidas pelo Usuário contratante.
A Health to go não endossa nenhum Profissional e não tem qualquer responsabilidade pela conduta dos Profissionais ou de terceiros contatados ou contratados através da Plataforma. A Health to go explicitamente não se responsabiliza por qualquer ato ou omissão de qualquer Especialista em relação a Usuários ou a terceiros. Ao utilizar a Plataforma ou Serviços, você concorda que qualquer ação judicial ou de responsabilidade civil que você procurar obter por ações ou omissões de Profissionais, Usuários ou outros terceiros será limitada a uma reivindicação contra os Profissionais, Usuários individuais ou terceiros que lhe causaram qualquer dissabor e você concorda em não tentar impor a responsabilidade ou intentar recurso legal contra a Health to go, ou seus acionistas, representantes, gestores ou funcionários, com relação a tais ações ou omissões. O mesmo compromisso aplica-se a todas as demais partes envolvidas nas reclamações ou ações.
Como a Health to go não figura como parte nas transações que ocorrem entre os Usuários e os Profissionais, a responsabilidade por todas as obrigações delas decorrentes, sejam fiscais, trabalhistas, consumeristas ou de qualquer outra natureza, são exclusivamente dos Usuários e dos Especialistas.
Caso a Health to go seja polo passivo de interpelação judicial cujos fatos fundem-se em ações do Usuário e/ou Profissional, este será chamado ao polo devendo arcar com todos os ônus que daí decorram.
A Plataforma ou os Serviços da Health to go podem conter links para outros websites. Isto não implica em nenhuma relação de sociedade, de supervisão, de cumplicidade, de endosso ou solidariedade da Health to go para com esses sites, seus conteúdos, produtos ou serviços. Você reconhece e concorda que a Health to go não é responsável pela disponibilidade ou exatidão de tais sites ou recursos ou pelos conteúdos, produtos ou serviços contidos ou disponibilizados através de tais sites ou recursos. Você assume exclusiva e total responsabilidade por todos os riscos e eventuais danos e/ou prejuízos decorrentes do uso de quaisquer desses sites ou de seus conteúdos, produtos ou serviços.

A Health to go não se responsabiliza por/pela(o):
a) Quaisquer vícios, defeitos técnicos e/ou operacionais (problemas, bugs e glitches) ou funcionamentos indevidos que ocorrerem nos dispositivos e equipamentos dos Usuários ou de terceiros e sejam resultantes do uso regular da Plataforma;
b) Qualquer dano direto ou indireto ocasionado por eventos de terceiros, como ataque de hackers, falhas no sistema, no servidor ou na conexão à Internet, inclusive por ações de softwares maliciosos, como vírus, cavalos de Tróia e outros que possam, de algum modo, danificar o equipamento ou a conexão dos Usuários em decorrência do acesso, utilização ou navegação na Plataforma, bem como a transferência de dados, arquivos, imagens, textos contidos neste;
c) Navegação dos Usuários em links externos contidos na Plataforma, sendo seus deveres a leitura de eventuais Termos de Uso e Política de Privacidade do portal acessado e agir conforme o determinado;
d) Verificar, controlar, aprovar ou garantir a adequação ou exatidão das informações ou dados disponibilizados em tais links, não sendo, portanto, responsável por prejuízos, perdas ou danos ocorridos pela visita a tais sites, cabendo ao interessado verificar a confiabilidade das informações e dados ali exibidos antes de tomar alguma decisão ou praticar algum ato;
e) Disponibilidade integral e ininterrupta da Plataforma, cujo correto funcionamento depende do acesso e tráfego de dados entre terminais de computador dos Usuários e os servidores pertencentes ou contratados pela Health to go, não possuindo nenhuma gerência e não sendo responsável por eventuais falhas no tráfego desses dados e no acesso à Plataforma decorrentes de falhas na rede mundial de computadores ou da própria rede e serviços de telecomunicação prestados por provedores de acesso à Internet;
f) Ressarcir seus Usuários por quaisquer gastos com ligações telefônicas, pacotes de dados, SMS, mensagens, e-mails, correspondência ou qualquer outro valor despendido pelo Usuário em razão de contato com a Health to go ou quaisquer outros Usuários, por qualquer motivo que seja.
Os Usuários não possuem qualquer direito para exigir a disponibilidade da Plataforma conforme melhor lhes convêm, tampouco poderão pleitear indenização ou reparação de danos em caso de a Plataforma permanecer fora do ar, independentemente da motivação.
A eventual remoção, bloqueio ou suspensão de qualquer conteúdo ou funcionalidade da Plataforma em decorrência de alguma reclamação, deverá ser sempre compreendida como demonstração de boa-fé e intenção de solução amigável de conflitos, jamais como reconhecimento de culpa ou de qualquer infração pela Health to go a direito de terceiro.
A Health to go se reserva no direito de auxiliar e cooperar com qualquer autoridade judicial ou órgão governamental, podendo enviar informações cadastrais de seus Usuários.

Propriedade Intelectual
O uso comercial da expressão "Health to go" como marca, nome empresarial ou nome de domínio; os conteúdos das telas relativas à Plataforma ou Serviços da Health to go; e os programas, bancos de dados, redes, arquivos que permitem que o Usuário acesse a Plataforma e use Sua Conta são todos de propriedade da Health to go e estão protegidos pelas leis e tratados internacionais de direito autoral, marcas, patentes, modelos e desenhos industriais. O uso indevido e a reprodução total ou parcial dos referidos itens são proibidos, salvo por autorização expressa da Health to go.
Todas as outras marcas comerciais, marcas de serviço, logotipos, nomes comerciais e quaisquer outras designações proprietárias são marcas comerciais ou marcas registradas de suas respectivas partes.
Desde que em conformidade com estes Termos, a Health to go lhe concede uma licença limitada, não exclusiva e intransferível, para (i) acessar e visualizar qualquer Conteúdo da Health to go exclusivamente para fins pessoais e não comerciais e (ii) acessar e visualizar qualquer Conteúdo de Usuário para o qual você tem permissão de acesso, exclusivamente para fins pessoais e não comerciais. Você não tem direito de sublicenciar os direitos de licença concedidos nesta seção. Você se compromete a não usar, copiar, adaptar, modificar, preparar trabalhos derivados com base, distribuir, licenciar, vender, transferir, exibir publicamente, executar publicamente, transmitir ou explorar a Plataforma, Serviços ou Conteúdos, exceto se expressamente permitido nestes Termos. Você se compromete a não remover, alterar ou ocultar quaisquer direitos autorais, marca registrada, marca de serviço ou outros direitos de propriedade incorporadas ou acompanhando a Plataforma, Serviços ou Conteúdos. Nenhuma licença ou direitos são concedidos por implicação ou sob quaisquer direitos de propriedade intelectual pertencentes ou controlados pela Health to go ou de seus licenciadores, exceto para as licenças e direitos expressamente concedidos nestes Termos.
Qualquer intromissão, tentativa de, ou atividade que viole ou contrarie as leis de direito de propriedade intelectual e/ou as proibições estipuladas nestes Termos de Uso, tornarão o responsável passível das ações legais pertinentes, bem como das sanções aqui previstas, sendo ainda responsável pelas indenizações por eventuais danos causados.

Vedações
Fica vedada qualquer tentativa, direta ou indireta, bem-sucedida ou não, de realizar alguma das atividades listadas abaixo. A constatação de tal intento tornará o autor, bem como seus mandantes, defensores, encorajadores e ajudantes, passível das sanções previstas nestes Termos, das ações legais pertinentes e da responsabilidade de indenizar quaisquer partes afetadas por eventuais danos causados.
Em caso de constatada a prática de qualquer das atividades abaixo listadas o Usuário estará sujeito a suspensão e/ou cancelamento de seu acesso a Plataforma, bem como poderão ser adotadas as medidas administrativas e/ou judiciais em face do Usuário.
Tais atividades incluem, mas não se limitam a:
a) Incomodar, agredir, caluniar, injuriar, difamar, perseguir ou assediar de qualquer modo  outro Usuário ou Parceiro.
b) Infringir os direitos de qualquer pessoa ou entidade, incluindo, sem limitação, os direitos de propriedade intelectual, privacidade, publicidade ou contratual, ressalvando que é proibida a gravação por vídeo ou voz dos atendimentos realizados;
c) Copiar, armazenar, ou de qualquer forma acessar qualquer informação contida na Plataforma ou Serviços ou Conteúdos para fins que não sejam expressamente autorizados por estes Termos;
d) Utilizar a Plataforma ou Serviços para transmitir, distribuir, publicar ou enviar qualquer informação relativa a qualquer outra pessoa ou entidade, incluindo, sem limitação, fotografias de outras pessoas sem sua permissão, informações de contato pessoal ou de crédito, débito, cartão telefônico ou números de contas;
e) Manipular os valores ou quaisquer outras informações contidas nos anúncios, na Plataforma ou Serviços;
f) Utilizar a Plataforma ou Serviços para qualquer comercial ou para outros fins que não sejam expressamente autorizados por estes Termos;
g) Usar software manual ou automatizado, dispositivos, scripts robôs, ou outros meios ou processos para acessar, “scrape”, “crawl” ou “spider” qualquer parte da Plataforma, Serviços ou Conteúdos;
h) Modificar, copiar, distribuir, transmitir, exibir, realizar, reproduzir, publicar, licenciar, criar trabalhos derivados, exibir em outra webpage, utilizar em qualquer outro website, transferir ou vender qualquer informação, software, listas de usuários, banco de dados ou outras listas, produtos ou serviços prestados por ou obtidos pela Health to go, incluindo, mas não limitado a, práticas envolvendo “screen scraping”, “database scraping”, ou qualquer outra atividade com o propósito de obter listas de usuários ou outras informações;
i) Usar scripts automatizados para coletar informações ou de outra forma interagir com a Plataforma ou Serviços;
j) Acessar, testar, adulterar, interferir ou danificar a Plataforma ou qualquer sistema da Health to go, dos Usuários, dos Profissionais ou de terceiros através do uso de vírus, cancelar bots, cavalos de Tróia, códigos nocivos, flood pings, ataques de negação de serviço, pacote ou falsificação de IP, roteamento forjado ou informações de endereço eletrônico ou métodos similares ou de tecnologia;
k) Tentar detectar, varrer, ou testar a vulnerabilidade de qualquer sistema ou rede Health to go ou violar qualquer segurança ou medidas de autenticação;
l) Evitar, ignorar, remover, desativar, prejudicar, decodificar, ou evitar qualquer medida tecnológica implementada pela Health to go ou qualquer dos provedores Health to go ou qualquer outro terceiro (incluindo outro USUÁRIO) para proteger a Plataforma, Serviços ou Conteúdos;
m) Forjar qualquer pacote de cabeçalho TCP/IP ou qualquer parte da informação do cabeçalho em qualquer publicação, e-mail ou newsgroup, ou de qualquer forma utilizar a Plataforma, Serviços ou Conteúdos para enviar fonte de identificação alterada, enganosa ou falsa informação;
n) Sistematicamente recuperar dados ou outro conteúdo do nossa Plataforma ou Serviços para criar ou compilar, direta ou indiretamente, em downloads únicos ou múltiplos, a coleta, compilação, banco de dados, diretório ou similar, seja por métodos manuais, através da utilização de bots, crawlers, ou spiders, ou de qualquer outra forma;
o) Tentar decifrar, descompilar, desmontar ou fazer engenharia reversa de qualquer software usado para fornecer a Plataforma, Serviços ou Conteúdos;
p) Personificar qualquer pessoa ou entidade, falsificar ou adulterar a si mesmo ou sua afiliação com qualquer pessoa ou entidade;
q) O Profissional não poderá em nenhuma hipótese realizar qualquer tipo de comunicação com o Usuário por meios escusos a Plataforma, sob pena de suspensão/ cancelamento do acesso;
r) A tentativa ou êxito de conduta configurada como Fraude as funcionalidades, planos ou forma de pagamento utilizados pela Plataforma. Em caso de descumprimento de qualquer dos itens anteriormente listados, a Health to go não se responsabilizará por eventuais perdas e danos que venham a ser suportadas pelas partes envolvidas;

Sanções
Caso algum Usuário não cumpra qualquer dispositivo destes Termos e dos Termos Adicionais ou faça algo que prejudique a Plataforma, a Health to go, os Profissionais ou outros Usuários, a Health to go pode, a seu exclusivo critério, a qualquer momento e sem aviso ou consentimento prévios:
a) Suspender ou cancelar definitivamente a Conta do Usuário e todo e qualquer acesso de tal Usuário à Plataforma e aos Serviços.
b) Tomar ações cabíveis para investigar e julgar violações de qualquer natureza em toda a extensão da lei, podendo envolver ou cooperar com autoridades judiciais e policiais.


Do Canal de Atendimento e Denúncia
A Health to go fornece a seus Usuários e Profissionais um canal de atendimento para críticas, sugestões, denúncias e demais reclamações que o Usuário e/ou Profissional entenda cabível, diretamente no e-mail: atendimento@healthtogo.com.br. Ao utilizar este canal, o Usuário deverá se identificar com os dados pertinentes (nome, endereço, número de telefone e e-mail) e descrever a situação detalhadamente, a fim de que a Health to go verifique eventuais medidas cabíveis previstas nestes Termos.

Notificações
Quaisquer notificações ou outras comunicações permitidas ou exigidas neste documento, incluindo as modificações em relação a estes Termos, serão por escrito e entregues pela Health to go (i) por postagem na Plataforma ou (ii) via e-mail para o endereço de e-mail informado pelo Usuário em Sua Conta.
Quaisquer notificações ou outras comunicações direcionadas a Health to go também deverão ser por escrito e entregues via e-mail para o endereço atendimento@healthtogo.com.br.
Para avisos feitos por e-mail, a data de recebimento será considerada a data em que tal notificação for transmitida.

Legislação Aplicável e Foro de eleição
Todos os itens destes Termos são regidos pelas leis vigentes na República Federativa do Brasil. Para todos os assuntos referentes à interpretação, ao cumprimento ou a qualquer outro questionamento relacionado a estes Termos, as partes concordam e elegem, expressamente, o Foro da Comarca de Curitiba.

Definições
Para os fins deste documento, devem se considerar as seguintes definições e descrições para seu melhor entendimento:
Cloud Computing: Ou computação em nuvem, é tecnologia de virtualização de serviços construída a partir da interligação de mais de um servidor por meio de uma rede de informação comum (p.ex. a Internet), com objetivo de reduzir custos e aumentar a disponibilidade dos serviços sustentados.
Conteúdo: todo e qualquer texto, gráficos, imagens, música, áudio, vídeo, informação ou outros materiais disponibilizados na Plataforma, independentemente de ser Conteúdo de Usuário ou Conteúdo da Health to go.
Conteúdo de Usuário: todo e qualquer conteúdo que um Usuário postar, transferir, publicar, apresentar ou transmitir para ser disponibilizado através da Plataforma.
Conteúdo de Profissional: todo e qualquer conteúdo que um Profissional postar, transferir, publicar, apresentar ou transmitir para ser disponibilizado através da Plataforma.
Conteúdo da Health to go: todo e qualquer conteúdo que a Health to go disponibiliza através do site, incluindo qualquer conteúdo licenciado de terceiros, mas excluindo Conteúdo de Usuários.
Link: Terminologia para endereço de Internet.
Plano: a Health to go oferece aos Usuários a possibilidade de adquirirem planos de pagamento para usufruir os Serviços e produtos da Plataforma, com benefícios e acessos a funcionalidades, que poderão variar de acordo com o plano contratado.
Serviços: Refere-se a todo e qualquer serviço prestado pela Health to go através da Plataforma ou não.
Usuários: pessoa física, plenamente capaz de acordo com o Código Civil, apta a buscar serviços e usufruir dos serviços de nutrição disponibilizados por meio da Plataforma, independentemente de ter se cadastrado. Salvo quando expressamente indicado em contrário, os termos “você”, “seu” ou “sua” se referem ao Usuário.


`;

export default UserTerms;
