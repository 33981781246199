import styled from 'styled-components';
import { Form } from '@unform/web';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const AnimationContainer = styled(motion.div)``;

export const Container = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 1rem;
  position: relative;

  @media (max-width: 800px) {
    justify-content: center;
    padding: 2rem 0;
  }
`;

export const CloseModalButton = styled.button`
  position: absolute;
  right: -4%;
  top: -4%;

  width: 2rem;
  height: 2rem;

  display: flex;
  align-items: center;
  justify-content: center;

  background: none;
  border: none;
`;

export const CloseIcon = styled(FontAwesomeIcon)`
  width: 1rem;
  height: 1rem;

  color: var(--text-title);
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;

  h1 {
    font-size: 1.4rem;
    font-weight: 700;
    color: var(--orange_500);
    margin-bottom: 2rem;
  }

  input {
    background-color: var(--card-border);
    color: var(--text-body);
  }

  textarea {
    height: 7rem;
  }

  @media (max-width: 800px) {
    padding-right: 0;
    margin-top: -2.5rem;

    textarea {
      height: 9rem;
    }
  }
`;

export const Footer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  margin-top: 1.188rem;

  button {
    place-self: flex-end;
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr;

    button {
      margin-right: 0;
      margin-top: 2rem;
      place-self: center;
    }
  }
`;

export const AcceptTermsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  width: 80%;
`;

export const Terms = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 0.5rem;

  h3 {
    font-weight: 600;
    font-size: 0.875rem;
    margin-left: 0.5rem;
    color: var(--text-body);
    button {
      font-weight: 700;
      font-size: 1rem;
      color: var(--orange_500);
      background: none;
      border: none;
      margin: 0;
      height: 1rem;
    }
  }

  div {
    max-width: 1.35rem;
    input {
      height: 1.35rem;
      max-width: 1.35rem;
    }
  }
`;
