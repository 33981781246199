import { useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import Logo from '../../../assets/health_to_go_logo.png';

import getValidationErrors from '../../../utils/validateErrors';

import { ControllerInput } from '../../../components/controllerInput';
import { LoadingContext } from '../../../hooks/LoadingContext';
import { Button } from '../../../components/button';

import { Container, Main, ResponseContainer } from './style';
import api from '../../../services/api';

interface FormData extends React.FormEvent {
  password: string;
  confirmPassword: string;
}

const ResetPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { SetIsLoading } = LoadingContext();
  const navigate = useNavigate();
  const [responseSuccessful, setResponseSuccessful] = useState(false);
  const [responseError, setResponseError] = useState(false);
  const { token } = useParams();

  async function ResetPasswordForm(form: FormData) {
    try {
      SetIsLoading(true);
      const schema = Yup.object().shape({
        password: Yup.string().min(6, 'No minimo 6 caracteres'),
        confirmPassword: Yup.string().oneOf(
          [Yup.ref('password'), null],
          'Confirmação inválida',
        ),
      });

      await schema.validate(form, {
        abortEarly: false,
      });

      await api.post(
        '/resetpassword',
        {
          password: form.password,
        },
        {
          headers: {
            Authorization: token as string,
          },
        },
      );

      SetIsLoading(false);
      setResponseSuccessful(true);
      return;
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const err = getValidationErrors(error);

        formRef.current?.setErrors(err);
        SetIsLoading(false);
        return;
      }
      SetIsLoading(false);
      setResponseError(true);
    }
  }

  const closeSuccessResponseComponent = () => {
    setResponseSuccessful(false);
    navigate('/signIn');
  };

  const closeErrorResponseComponent = () => {
    setResponseError(false);
  };

  return (
    <Container>
      <img src={Logo} alt="Health to Go" />
      {!(responseSuccessful || responseError) && (
        <Main ref={formRef} onSubmit={ResetPasswordForm}>
          <h1>Redefinir senha</h1>
          <ControllerInput type="password" name="password" label="Nova Senha" />
          <ControllerInput
            type="password"
            name="confirmPassword"
            label="Confirmar Senha"
          />

          <Button type="submit" ButtonColor="blue">
            Redefinir
          </Button>
        </Main>
      )}
      {responseSuccessful && (
        <ResponseContainer>
          <h1>Senha redefinida com sucesso!</h1>
          <h2>Você ja pode acessar novamente a plataforma.</h2>

          <Button
            type="button"
            ButtonColor="blue"
            onClick={closeSuccessResponseComponent}
          >
            OK
          </Button>
        </ResponseContainer>
      )}
      {responseError && (
        <ResponseContainer>
          <h1>Ops... isso não deu certo.</h1>
          <h2>
            Por favor, verifique seus dados, e tente novamente. Caso o erro
            persista, entre em contato com nosso suporte.
          </h2>

          <Button
            type="button"
            ButtonColor="blue"
            onClick={closeErrorResponseComponent}
          >
            OK
          </Button>
        </ResponseContainer>
      )}
    </Container>
  );
};

export default ResetPassword;
