import Modal from 'react-modal';

import Logo from '../../../../assets/health_to_go_logo.png';

import { Button } from '../../../../components/button';

import { Container } from './style';

interface ErrorProps {
  isOpen: boolean;
  functionProp(): void;
}

const NotFoundModal: React.FC<ErrorProps> = ({ functionProp, isOpen }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={functionProp}
      closeTimeoutMS={200}
      className="react-modal-content"
      overlayClassName="react-modal-overlay"
    >
      <Container initial={{ scale: 0.9 }} animate={{ scale: 1 }}>
        <h1>Ops! </h1>
        <h2>Não encontramos nenhum nutri 🙁</h2>
        <div>
          <img src={Logo} alt="HealthToGo" />
        </div>
        <Button type="button" ButtonColor="blue500" onClick={functionProp}>
          OK!
        </Button>
      </Container>
    </Modal>
  );
};

export default NotFoundModal;
