import { useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import Logo from '../../assets/health_to_go_logo.png';

import FormSignUp from './componentes/signupForm/formComponent';
import FormSignUpTerms from './componentes/signupTerns/professionalTerms';
import FormSignUpPayment from './componentes/signupPayment/signupPayment';
import FormSignUpConfirmation from '../../components/successModal';
import ErrorModal from '../../components/errorModal';

import { useSignUpInfo } from '../../hooks/SignUpContext';

import { Container, ArrowIcon, SideContainer } from './style';

const SignUp: React.FC = () => {
  const { signUpStep, SignUpConfirmationOut } = useSignUpInfo();
  const navigate = useNavigate();

  const handleCloseModalError = useCallback(() => {
    SignUpConfirmationOut();
  }, [SignUpConfirmationOut]);

  function handleNavigateToLogin() {
    navigate('/signin');
    SignUpConfirmationOut();
  }

  const ComponentsArray = [
    <FormSignUp />,
    <FormSignUpTerms />,
    <FormSignUpPayment />,
    <FormSignUpConfirmation
      title="Muito Obrigado"
      description="Agora você já pode logar em nossa plataforma, e encontrar novos pacientes!"
      onClick={handleNavigateToLogin}
    />,
    <ErrorModal
      message="Parece que você ainda não possui um convite para poder se cadastrar. Entre em contato com nossa equipe e saiba mais."
      functionProp={handleCloseModalError}
    />,
  ];

  return (
    <Container>
      <Link to="/signin">
        <ArrowIcon icon={faArrowLeft} />
      </Link>
      {ComponentsArray[signUpStep]}

      <SideContainer>
        <img src={Logo} alt="Health to Go" />
      </SideContainer>
    </Container>
  );
};

export default SignUp;
