import { useCallback, useState } from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../../hooks/AuthContext';

import Logo from '../../../assets/health_to_go_logo.png';

import { Button } from '../../button';

import { Container } from './style';

interface CompleteProfileModalProps {
  id: number;
  isOpen?: boolean;
  onClick?: () => void;
}

const CompleteProfileModal: React.FC<CompleteProfileModalProps> = ({
  id,
  isOpen = false,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const { HandleCompleteProfileModalState } = useAuth();
  const navigate = useNavigate();

  const HandleSendToProfilePage = useCallback(() => {
    setIsModalOpen(false);
    navigate(`/profile/${id}`);
    HandleCompleteProfileModalState();
  }, [navigate, id, HandleCompleteProfileModalState]);

  const HandleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return (
    <Modal
      isOpen={isModalOpen}
      className="react-modal-content"
      closeTimeoutMS={200}
      onRequestClose={HandleCloseModal}
      overlayClassName="react-modal-overlay"
    >
      <Container initial={{ scale: 0.9 }} animate={{ scale: 1 }}>
        <h1>Estamos quase lá!</h1>
        <h2>
          Complete seu perfil, e comece a ser exibido em nossa plataforma!
        </h2>
        <div>
          <img src={Logo} alt="HealthToGo" />
        </div>
        <Button
          type="button"
          ButtonColor="blue500"
          onClick={HandleSendToProfilePage}
        >
          Vamos lá!
        </Button>
      </Container>
    </Modal>
  );
};

export default CompleteProfileModal;
