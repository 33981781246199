import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { Container } from './style';

const SkeletonPartnersPageLoad: React.FC = () => {
  return (
    <Container>
      <Skeleton
        height={149}
        style={{
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: 269,
        }}
      />
      <br />
      <Skeleton count={5} height={26} />
      <br />
      <Skeleton count={6} height={26} />
    </Container>
  );
};

export default SkeletonPartnersPageLoad;
