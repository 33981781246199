import { Header } from '../../components/header';
import { Footer } from '../../components/footer';

import { Container } from './style';
import { Search } from './search';
import { ProfessionalCard } from './professionalCard';

const Dashboard: React.FC = () => {
  return (
    <Container>
      <Header />
      <Search />
      <ProfessionalCard />
      <Footer />
    </Container>
  );
};

export default Dashboard;
