import Modal from 'react-modal';
import Lottie from 'react-lottie';
import { MotionConfig } from 'framer-motion';

import { useState } from 'react';
import Logo from '../../assets/health_to_go_logo.png';

import { Button } from '../button';

import { Container } from './style';

import animationData from '../../assets/animation/successfully-done.json';

interface SuccessModalProps {
  title: string;
  description: string;
  isOpen?: boolean;
  onClick?: () => void;
}

const ModelSuccessConfirmation: React.FC<SuccessModalProps> = ({
  title,
  description,
  isOpen = true,
  onClick,
}) => {
  const [animationCicle, setAnimationCicle] = useState(true);

  const defaultOptions = {
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  function ChangeCicle() {
    setTimeout(() => setAnimationCicle(false), 3370);
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClick}
      onAfterOpen={ChangeCicle}
      closeTimeoutMS={200}
      className="react-modal-content"
      overlayClassName="react-modal-overlay"
    >
      <MotionConfig transition={{ duration: 1 }}>
        {animationCicle ? (
          <Lottie options={defaultOptions} height={300} width={300} />
        ) : (
          <Container
            transition={{ type: 'spring', stiffness: 100 }}
            initial={{ scale: 0.9 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0.9 }}
          >
            <h1>{title}</h1>
            <h2>{description}</h2>
            <div>
              <img src={Logo} alt="HealthToGo" />
            </div>
            <Button type="button" ButtonColor="blue500" onClick={onClick}>
              OK
            </Button>
          </Container>
        )}
      </MotionConfig>
    </Modal>
  );
};

export default ModelSuccessConfirmation;
