import { Header } from '../../components/header';
import { ProfileCard } from './profileCard';
import { Footer } from '../../components/footer';

import { Container } from './style';

const Profile: React.FC = () => {
  return (
    <Container>
      <Header />
      <ProfileCard />
      <Footer />
    </Container>
  );
};

export default Profile;
