import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Background = styled.div`
  background-color: var(--blue_300);
  width: 100%;
  padding: 0 0 4rem;

  @media (max-width: 800px) {
    padding: 0 0 2rem;
  }
`;

export const AvaliationContainer = styled.div`
  background-color: var(--blue_500);
  margin-bottom: 4rem;
  padding: 2rem;

  h1 {
    font-weight: 700;
    font-size: 2.875rem;
    color: var(--white-text);
    text-align: center;
    margin-bottom: 1.5rem;
  }

  h2 {
    font-weight: 600;
    font-size: 3.875rem;
    color: var(--white-text);
    text-align: center;

    span {
      font-size: 1.5rem;
    }
  }

  @media (max-width: 800px) {
    margin-bottom: 1rem;

    h1 {
      font-size: 1.875rem;
      margin-bottom: 0.5rem;
    }
    h2 {
      font-size: 2.4rem;

      span {
        font-size: 1rem;
      }
    }
  }
`;

export const StarIcon = styled(FontAwesomeIcon)`
  width: 1.875rem;
  height: 1.875rem;

  color: var(--yellow_500);
`;

export const Container = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 4rem;
  margin: 0rem 8.688rem;
  min-height: 13rem;

  button {
    grid-column: 2;
    place-self: end center;

    :nth-child(2) {
      grid-row: 2;
    }
  }

  @media (max-width: 940px) {
    grid-template-columns: 1fr 1fr;
    margin: 4rem;
    grid-gap: 4rem;
    justify-items: center;

    button {
      grid-column: 2;
    }
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    margin: 3rem;
    grid-gap: 3rem;
    justify-items: center;

    button {
      grid-column: 1;
    }
  }
`;

export const CardContainer = styled.li`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: var(--shape);
  list-style: none;
  width: 100%;
  max-width: 31.328rem;
  min-height: 20.188rem;
  border-radius: 0.5rem;
  padding: 1.5rem;

  h2 {
    color: var(--orange_500);
    font-size: 1.25rem;
  }

  p {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.625rem;
    text-align: left;
    color: var(--text-body);
    margin-left: 0.75rem;
  }
`;

export const BallonEffect = styled.div`
  position: absolute;
  width: 0;
  height: 0;

  border-left: 2rem solid transparent;
  border-right: 2rem solid transparent;
  border-top: 2rem solid var(--shape);

  bottom: -1rem;
  right: 1rem;
`;
