import axios from 'axios';

const apiUrl =
  process.env.REACT_APP_ENV === 'prod'
    ? process.env.REACT_APP_BASE_URL
    : process.env.REACT_APP_BASE_TESTE_URL;

const api = axios.create({
  baseURL: apiUrl,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
});

export default api;
