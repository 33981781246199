import { Link } from 'react-router-dom';

import animationData from '../../assets/animation/404-page-not-found.json';

import { Button } from '../../components/button';

import { Container, Main, Animation } from './style';

const NotFoundPage: React.FC = () => {
  const defaultOptions = {
    autoplay: true,
    animationData,
  };

  return (
    <Container>
      <Main>
        <Animation options={defaultOptions} />
        <h1>Opa! Não era para você ter visto isso...</h1>
        <Link to="/">
          <Button type="submit" ButtonColor="orange">
            Me tira daqui!
          </Button>
        </Link>
      </Main>
    </Container>
  );
};

export default NotFoundPage;
