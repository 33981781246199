import { useCallback, useState } from 'react';
import { faX } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { Button } from '../button';

import {
  Container,
  CloseModalButton,
  Main,
  Footer,
  CloseIcon,
  AnimationContainer,
} from './style';

interface ResetPasswordModalProps {
  openModal: boolean;
  onCloseModal: () => void;
  avatarImage: string;
}

const ImageResizerModal: React.FC<ResetPasswordModalProps> = ({
  openModal,
  onCloseModal,
  avatarImage,
}) => {
  const [newImage, setNewImage] = useState('');
  const [crop, setCrop] = useState<Crop>({
    height: 350,
    width: 350,
    unit: 'px',
    x: 190,
    y: 60,
  });

  const handleCropImagePreview = useCallback(
    (cropProp) => {
      const canvas = document.createElement('canvas');
      canvas.height = cropProp.height;
      canvas.width = cropProp.width;
      const ctx = canvas.getContext('2d');
      const img = new Image();
      img.src = avatarImage;
      const scaleX = img.naturalWidth / img.width;
      const scaleY = img.naturalHeight / img.height;

      ctx?.drawImage(
        img,
        cropProp.x * scaleX,
        cropProp.y * scaleY,
        cropProp.width * scaleX,
        cropProp.height * scaleY,
        0,
        0,
        cropProp.width,
        cropProp.height,
      );

      const vaiqueda = canvas.toDataURL('image/jpeg', 1);

      setNewImage(vaiqueda);
    },
    [avatarImage],
  );

  const newAvatarHandler = () => {
    const teste = new File([newImage], 'teste.jpeg', { type: 'image/jpeg' });
    return console.log(teste);
  };

  function handleCloseResizeImageModal() {
    onCloseModal();
  }

  return (
    <Modal
      isOpen={openModal}
      onRequestClose={handleCloseResizeImageModal}
      closeTimeoutMS={200}
      className="react-modal-content"
      overlayClassName="react-modal-overlay"
    >
      <AnimationContainer initial={{ scale: 0.9 }} animate={{ scale: 1 }}>
        <Container>
          <CloseModalButton type="button" onClick={handleCloseResizeImageModal}>
            <CloseIcon icon={faX} />
          </CloseModalButton>
          <Main>
            <ReactCrop
              crop={crop}
              onChange={(e) => setCrop(e)}
              ruleOfThirds
              onComplete={handleCropImagePreview}
              aspect={1}
              circularCrop
            >
              <img src={avatarImage} alt="Avatar" id="userImage" />
            </ReactCrop>
          </Main>
          <Footer>
            <Button
              type="button"
              onClick={newAvatarHandler}
              ButtonColor="blue500"
            >
              Redefinir
            </Button>
          </Footer>
        </Container>
      </AnimationContainer>
    </Modal>
  );
};

export default ImageResizerModal;
