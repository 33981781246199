import { useLayoutEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';

import Dashboard from '../pages/dashboard';
import Profile from '../pages/profile';

import Partners from '../pages/partners';

import SignIn from '../pages/signIn';
import Leeds from '../pages/leeds';
import RememberPassword from '../pages/rememberPassword';
import SignUp from '../pages/signup';

import ResetPassword from '../pages/rememberPassword/resetPassword';
import NotFoundPage from '../pages/404Page';
import AdminPage from '../pages/admin';

const Router = () => {
  const Wrapper = ({ children }: any) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };
  return (
    <BrowserRouter>
      <Wrapper>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/profile/:id" element={<Profile />} />

          <Route path="/partners" element={<Partners />} />

          <Route path="/signin" element={<SignIn />} />
          {/* <Route path="/register" element={<Leeds />} /> */}

          <Route path="/signup" element={<SignUp />}>
            <Route path=":token" element={<SignUp />} />
          </Route>
          <Route path="/password" element={<RememberPassword />} />
          <Route path="/resetPassword/:token" element={<ResetPassword />} />
          <Route path="/admin/painel" element={<AdminPage />}>
            <Route path=":token" element={<AdminPage />} />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Wrapper>
    </BrowserRouter>
  );
};

export default Router;
