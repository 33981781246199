import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Container = styled.footer`
  background-color: var(--blue_500);
  margin-top: auto;
  width: 100%;

  @media (max-width: 800px) {
    align-items: center;
  }
`;

export const MainContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  padding: 2.125rem 3.438rem;

  h2 {
    font-weight: 600;
    font-size: 1.125rem;
    color: var(--white-text);
  }

  @media (max-width: 800px) {
    align-items: stretch;
    justify-content: center;
    flex-direction: column;
    padding: 2.225rem 1.5rem;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;

  div {
    & + div {
      margin-left: 2.875rem;
    }
  }

  button,
  a {
    font-size: 0.875rem;
    font-weight: 400;
    margin-top: 0.25rem;
    color: var(--white-text);
    background: none;
    border: none;

    text-align: start;
  }
  @media (max-width: 800px) {
    justify-content: space-between;
  }
`;

export const TermsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const SocialContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h2 {
    margin-bottom: 0.25rem;
  }

  @media (max-width: 800px) {
    margin-top: 2rem;
  }
`;

export const InstagramIcon = styled(FontAwesomeIcon)`
  width: 1.875rem;
  height: 1.875rem;
  margin-top: 0.5rem;
  color: var(--white-text);
`;

export const BlueLine = styled.div`
  background-color: var(--blue_300);
  height: 1.125rem;
  width: 100%;
`;
export const YellowLine = styled.div`
  background-color: var(--yellow_500);
  height: 1.125rem;
  width: 100%;
`;
export const OrangeLine = styled.div`
  background-color: var(--orange_500);
  height: 1.125rem;
  width: 100%;
`;
