const PrivacyTerm = `HEALTH TO GO INTERMEDIAÇÃO DE SERVIÇOS LTDA., inscrita no CNPJ/MF sob o n° 44.233.296/0001-66, com sede na Rua Paulo Ziliotto, 85, 6º andar, apt 605 - Campina do Siqueira - CEP 80740-310, na cidade de Curitiba, Estado do Paraná, é uma pessoa jurídica de direito privado responsável pela plataforma doravante denominada Health to go (“Plataforma” ou “Health to go”).

Por intermédio desta Política de Privacidade (“Política”), a Health to Go apresenta aos Usuários e Profissionais, as informações essenciais sobre a forma que os seus dados pessoais são utilizados e tratados.

A coleta, o uso e o compartilhamento dos dados pessoais dos Usuários e Profissionais da Health to Go estão sujeitos a esta Política de Privacidade e suas atualizações.

Para contato com a Health to Go, envie um e-mail para atendimento@healthtogo.com.br.

1 - Objetivo desta Política:
    A Health to Go toma as medidas que estão ao seu alcance para proteger a privacidade do Usuário e Profissional, em atendimento à legislação em vigor. Esta Política de Privacidade detalha a forma que a Health to Go realiza a coleta, guarda, utilização, compartilhamento e divulgação dos dados pessoais dos Usuários e Profissionais, bem como aponta claramente as medidas tomadas para assegurar a proteção dos dados coletados.

2 - Momento de coleta dos dados pessoais:
    Os dados e informações pessoais são coletados quando inseridos ou submetidos voluntariamente pelos Usuários ou Profissionais, durante a navegação da Plataforma e por meio de cookies.

3 - Podemos utilizar outras tecnologias para coletar os dados de navegação de nossos Usuários e dos Profissionais, o que inclui, mas não se limita a cookies. No entanto, tais tecnologias respeitarão sempre os termos desta Política de Privacidade e as opções de nosso Usuário e de nosso Profissional referente ao tratamento do dado.
    Dados pessoais coletados:
    Dados pessoais dos Profissionais e Usuários coletados pela Health to Go:
    a) Nome;
    b) CPF;
    c) CNPJ (no caso de profissionais);
    d) Endereço postal;
    e) Data de nascimento;
    f) Endereço de e-mail;
    g) Telefone para contato;
    h) Informações sobre o navegador e o sistema operacional do dispositivo, para que a Plataforma da Health to Go funcione corretamente a depender do navegador utilizado pelo Usuário;
    i) Endereço de IP;
    j) Links e botões clicados na Plataforma pelo Usuário;
    k) Foto para cadastro e identificação no caso dos Profissionais.

4 - Dados pessoais coletados durante o uso da Plataforma:
    a) Endereço IP do Usuário e do Profissional;
    b) Acesso e ações realizadas pelo Usuário e pelo Profissional na Plataforma;
    c) Páginas e telas acessadas pelo Usuário e pelo Profissional na Plataforma da Health to Go;
    d) Datas e horários de cada ação do Usuário e do Profissional na Plataforma da Health to Go, além do acesso que fizer às páginas e telas das ferramentas e funcionalidades que utilizar;
    e) Informações sobre o dispositivo utilizado pelo Usuário e do Profissional, como: versão de sistema operacional, localização do dispositivo, navegador, dentre outros aplicativos e softwares instalados que possam afetar o funcionamento da Plataforma diretamente solicitados ao Usuário ou Profissional solicitados pela equipe de suporte;
5 - Política de Cookies
    Cookies são pequenos arquivos de texto ou fragmentos de informação que são baixados no computador, smartphone ou qualquer outro dispositivo com acesso à internet dos Usuários e Profissional da Plataforma. Os cookies armazenam, leem e executam os dados necessários para lembrarmos das configurações escolhidas.
    A Health to Go utiliza cookies com as preferências dos Usuários e dos Profissionais da Plataforma para armazenar e recuperar os dados sobre os hábitos de navegação.
    A Health to Go utiliza Cookies para fornecer a melhor experiência de uso, tornando nossas aplicações mais fáceis e personalizadas, tendo por base suas escolhas e comportamento de navegação.
    Respeitamos o direito à privacidade dos Usuários e dos Profissionais, que poderão, a qualquer momento, optar pela desativação dos cookies. Essa ação, contudo, pode afetar a disponibilidade de algumas ferramentas e funcionalidades do site ou de nossos serviços, comprometendo seu correto e esperado funcionamento e, eventualmente, comprometendo uma experiência mais personalizada.
    Importante esclarecer que não nos responsabilizamos pelo uso de cookies por terceiros. Os cookies colocados por terceiros podem, eventualmente, continuar a monitorar as suas atividades online, mesmo depois de ter saído do nosso site, sendo recomendável que você limpe seus dados de navegação regularmente.
    A Health to Go utiliza cookies dos seguintes tipos:
    a) Cookies necessários: são cookies essenciais que possibilitam a navegação em nossas aplicações e o acesso a todos os recursos e garantem funcionalidades básicas e recursos de segurança do site, de forma anônima. Sem estes, nossos serviços podem apresentar mau desempenho ou não funcionar.
    b) Cookies de desempenho: são cookies que ajudam a Health to Go a entender e analisar os principais índices de desempenho da Plataforma para otimização dessa e para oferecer uma melhor experiência aos Usuários e Profissional, coletando informações anônimas sobre as páginas acessadas.
    c) Cookies Analíticos : são cookies usados para entender como os visitantes interagem com o site. Estes cookies ajudam a fornecer informações sobre métricas, número de visitantes, taxa de rejeição e fonte de tráfego.
    d) Cookies de publicidade: são cookies que direcionam anúncios em função dos seus interesses e limitam a quantidade de vezes que o anúncio aparece. Esses cookies rastreiam visitantes em sites e coletam informações para fornecer anúncios personalizados.
    Os Usuários e os Profissionais da Plataforma poderão rever as permissões de cookies a qualquer tempo, inclusive para bloqueá-los, aceitá-los ou ativar notificações para quando alguns cookies forem enviados ao seu dispositivo. A revogação do consentimento de determinados Cookies pode inviabilizar o funcionamento correto de alguns recursos da plataforma.
    Para gerenciar os cookies do navegador, basta fazê-lo diretamente nas configurações do navegador, na área de gestão de Cookies, seja, o Safari, Chrome, Firefox, Internet Explorer e IOS.

6 - Responsabilidade da Health to Go:
    A Health to go não é responsável pela veracidade ou falta dela nas informações prestadas pelos nossos Usuário e/ou Profissionais, bem como pela sua desatualização, quando é de sua responsabilidade prestá-las com exatidão ou atualizá-las.

7 - Responsabilidade dos Profissionais:
    O Profissional é responsável pela guarda e coleta dos Prontuários, conforme disposições do Código de Ética do Nutricionista do Conselho Federal de Nutrição (CFN). O profissional é responsável pelo agendamento e adequado atendimento do paciente na modalidade presencial ou online.
    Dessa forma, o Profissional será o único e exclusivo responsável pelo sigilo, segurança e armazenamento das informações, documentos e/ou relatórios dos Usuários, comprometendo-se a mantê-los protegidos por, no mínimo 20 (vinte) anos, conforme determinação do CFN, sendo ainda responsável pelas indenizações por eventuais danos causados.


8 - Finalidades da coleta de dados pessoais dos Usuários e Profissionais:
    a) Identificá-los adequadamente na Plataforma;
    b) Prestar os serviços e cumprir as obrigações descritas no Termos de Uso;
    c) Atender adequadamente à solicitações e dúvidas dos Usuários e dos Profissionais;
    d) Manter atualizados os cadastros dos Usuários e Profissionais para fins de contato por telefone, aplicativos de comunicação instantânea, correio eletrônico, SMS, ou por outros meios de comunicação;
    e) Promover os nossos serviços e produtos, informar sobre novidades, funcionalidades, conteúdos, notícias e demais eventos relevantes. Somente serão enviados e-mails de relacionamento aos Usuários e Profissionais que optarem por recebê-los. Se, porventura e em qualquer momento, o Usuário deseje cancelar o recebimento destas correspondências, o seu pedido será prontamente atendido;
    f) Nos resguardar de direitos e obrigações relacionadas ao uso da nossa Plataforma;
    g) Colaborar e/ou cumprir ordem judicial ou requisição por autoridade administrativa;
    h) Para o exercício e defesa de quaisquer direitos da Health to Go, ao seu critério, incluindo no âmbito de processos judiciais ou administrativos;
    i) Aperfeiçoar o uso e experiência de navegação das nossas Plataformas;
    j) Efetuar estatísticas, estudos, pesquisas e levantamentos pertinentes às atividades de seus comportamentos ao utilizarem a Plataforma, para a manutenção do relacionamento com os nossos Usuários e nosso Profissional;
    k) Compartilhar os dados coletados utilizando a tecnologia de cookies e ferramentas de personalização e recomendação de produtos e ofertas, para permitir que parceiros de negócios tenham acesso, além de oferecer uma experiência mais adequada aos nossos Usuários e/ou Profissionais;
    l) Coletar e analisar dados a partir de softwares de terceiros incluindo, sem limitação, Google Analytics, para parametrização e criação de métricas.
    Desde já o Usuário e o Profissional declaram-se cientes de que podemos realizar o agrupamento e organização dos dados e informações em nossa base, a partir das informações e dados pessoais inseridos em nossa Plataforma.
    O uso, acesso e compartilhamento dos dados pessoais coletados pela Health to Go quando necessários, serão feitos dentro dos limites e propósitos dos negócios da Health to Go, podendo ser disponibilizada para consulta e cedidas aos nossos Profissionais, fornecedores e autoridades, desde que obedecido ao disposto na presente Política de Privacidade.
    Notificações aos Usuários: Caso ocorra alguma mudança nas finalidades para as quais utilizamos os dados dos Usuários ou Profissionais, estes serão notificados, nos termos descritos na presente Política de Privacidade.

9 - Compartilhamento e divulgação de dados pessoais
    Para execução das finalidades descritas acima e funcionamento da Plataforma, quando necessários, a Health to Go compartilha dados pessoais de seus Usuários e Profissionais com prestadores de serviços, parceiros, órgãos reguladores ou autoridades judiciais. Enfatizamos que não comercializamos dados pessoais.
    O compartilhamento com prestadores de serviço acontece para que a Health to Go possa prestar os serviços disponibilizados por meio de sua Plataforma, que tratam os dados pessoais de acordo com as instruções da Health to Go. O compartilhamento acontece para as seguintes finalidades:(i) intermediação de pagamentos; (ii) serviços de suporte e atendimento; (iii) serviços de envio de comunicação e gerenciamento de marketing para Usuários e Profissionais; (iv) serviços de engajamento com os Usuários e Profissionais; (v) armazenamento em nuvem da base de dados da Health to Go.
    A Health to Go também poderá realizar o compartilhamento dos dados pessoais com autoridades judiciais, policiais ou governamentais, nos seguintes casos: ordens ou solicitações judiciais, solicitações administrativas ou em razão de obrigações legais ou regulatórias.
    Os dados pessoais dos Usuários e dos Profissionais também poderão ser compartilhados com outras empresas do mesmo grupo da Health to Go que operam no mesmo nível de segurança da informação e privacidade, tais como afiliadas, coligadas, controladas e controladoras. Esse compartilhamento poderá ocorrer para desenvolvimento de novos produtos e para gestão dos seus serviços atuais.

10 - Segurança dos dados
    Sigilo de senha: A Health to Go recomenda que os Usuários e Profissionais mantenham suas senhas de acesso da Plataforma sob total sigilo, evitando a sua divulgação a terceiros.
    Suspeita de violação de dados: No caso de suspeita de violação de dados pessoais, a Health to Go se compromete a notificar os Usuários e Profissionais e que possam ter sido afetados e a rastrear todas as atividades realizadas no último período desde a última suspeita.

11 - Dos Direitos Dos Titulares De Dados Pessoais
    Direitos: Os Usuários e Profissionais poderão exercer seus direitos em relação aos dados coletados entrando em contato através do e-mail atendimento@healthtogo.com.br.
    Apenas os dados identificáveis são passíveis do exercício de tais direitos.
    Os direitos dos Usuários e Profissionais compreendem:
    a) Direito de acesso: permite que o Usuário ou Profissional conheça e obtenha informações sobre os dados pessoais sujeitos ao processamento;
    b) Direito de retificação ou exclusão: permite a correção de erros e a modificação de dados imprecisos e incompletos;
    c) Direito de cancelamento: permite a exclusão de dados inadequados ou excessivos;
    d) Direito de oposição: direito Usuários e Profissional de impedir o tratamento dos seus dados pessoais ou de cessar o processamento em curso;
    e) Direito de restringir o processamento: envolve a marcação de dados pessoais armazenados para restringir seu tratamento futuro;
    f) Portabilidade de dados: fornecimento de dados do Usuários e Profissionais que estiver sujeito a processamento para que possa ser transferida para outro controlador sem impedimentos; e
    g) Direito de retirar o consentimento dado a qualquer momento.
    A Health to Go reserva-se o direito de utilizar todos os meios lícitos necessários para aferir a identidade daqueles que solicitam o exercício dos direitos de modo a comprovar tratar-se, de fato, dos respectivos titulares de dados. Além disso, sempre que realizada alguma das solicitações descritas acima, a Health to Go analisará a necessidade ou possibilidade de manutenção dos dados pessoais dos Usuários para cumprimento de obrigações legais ou regulatórias de sua parte.
    Em algumas hipóteses, a Health to Go pode ter motivos legítimos para não atender a alguma solicitação de exercício de direitos dos Usuários. Estas situações incluem, por exemplo, casos em que uma revelação de informações específicas poderia violar direitos de propriedade intelectual ou segredos de negócio da Plataforma ou da Health to Go bem como casos em que pedidos de exclusão de dados não possam ser atendidos em razão da existência de obrigação de retenção de dados, seja para cumprir obrigações legais, regulatórias ou para possibilitar a defesa da Health to Go ou de terceiros em disputas de qualquer natureza.
    A Health to Go se compromete a responder todas as requisições em prazo razoável e sempre de acordo com a legislação aplicável.
    Retenção e Exclusão Dos Dados Pessoais
    Retenção dos dados dos Usuários e Profissionais: A Health to Go mantém os dados pessoais dos Usuários e Profissionais para atendimento às finalidades para as quais os recolhemos e para fins de cumprimento de obrigações legais e regulatórias.
    Exibição, retificação, ratificação ou exclusão dos dados pessoais: O Usuário ou Profissionais podem solicitar a exibição, retificação ou ratificação de seus dados pessoais, por meio das ferramentas de atendimento disponibilizadas pela Health to Go. Caso deseje solicitar a exclusão de seus dados pessoais coletados e registrados pela Health to go, poderá, ainda, entrar em contato através do e-mail atendimento@healthtogo.com.br.
    Os Usuários e Profissionais que solicitarem a exclusão de suas informações antes de decorrido o prazo estabelecido pela Health to Go, terão o seu pedido avaliado pela Health to Go para que os seus dados pessoais sejam deletados permanentemente da Plataforma.
    A Health to Go esclarece, desde já, que em algumas situações não será possível realizar a exclusão integral de dados, como por exemplo nos casos de contratação eletrônica de serviços e emissões de notas fiscais vinculadas aos dados de registro da pessoa física ou jurídica.

12 - Das Alterações e Modificações Desta Política
    Podemos alterar o teor desta Política de Privacidade a qualquer momento, conforme a finalidade ou necessidade, tal qual para adequação e conformidade legal de disposição de lei ou norma que tenha força jurídica equivalente, cabendo ao nosso Usuário verificá-la sempre que efetuar o acesso à Plataforma.
    Ocorrendo atualizações significativas neste documento, a Health to Go notificará o Usuário com pelo menos 10 (dez) dias de antecedência à modificação, sendo publicada nova versão na Plataforma com a data da última atualização e lhe notificaremos através do e-mail informado em sua conta na Plataforma. Ao continuar a utilizar o Site ou Serviços após a data em que uma alteração entrar em vigor, você indica a aceitação da nova versão em sua totalidade.
    Do Foro
    Todos os itens desta Política são regidos pelas leis vigentes na República Federativa do Brasil. Para todos os assuntos referentes à interpretação, ao cumprimento ou a qualquer outro questionamento relacionado a estes Termos, as partes concordam em se submeter ao Foro da Comarca de Curitiba-PR.

    Definições

    Para os fins deste documento, devem se considerar as seguintes definições e descrições para seu melhor entendimento:
    1 - Conteúdo: todo e qualquer texto, gráficos, imagens, música, áudio, vídeo, informação ou outros materiais disponibilizados na Plataforma, independentemente de ser Conteúdo de Usuário ou Conteúdo da Health to Go.
    2 - Conteúdo de Usuário: todo e qualquer conteúdo que um Usuário postar, transferir, publicar, apresentar ou transmitir para ser disponibilizado através da Plataforma.
    3 - Conteúdo de Profissional: todo e qualquer conteúdo que um Profissional postar, transferir, publicar, apresentar ou transmitir para ser disponibilizado através da Plataforma.
    4 - Conteúdo da Health to Go: todo e qualquer conteúdo que a Health to Go disponibiliza através do site, incluindo qualquer conteúdo licenciado de terceiros, mas excluindo Conteúdo de Usuários.
    5 - Link: Terminologia para endereço de Internet.
    7 - Plataforma: Designa a Plataforma Health to Go disponível nos endereços eletrônicos www.healthtogo.com.br e quaisquer outros sites, aplicativos móveis ou canais de atendimento oferecidos pela Health to Go.
    8 - Profissionais/Nutricionistas: pessoa física ou jurídica, plenamente capaz de acordo com o Código Civil, profissional de nutrição, devidamente registrado junto ao Conselho Federal de Nutrição, que deseja ofertar seus serviços aos Usuários junto à Plataforma.
    9 - Serviços: Refere-se a todo e qualquer serviço prestado pela Health to Go através da Plataforma ou não.
    11 - Sistema de Pagamento: sistema utilizado pela Health to Go em parceria com empresas especializadas em serviços financeiros que permite o pagamento dos serviços ofertados por meio da Plataforma, vinculado à conta bancária do Profissional. O Profissional interessado deverá cadastrar os dados de seu cartão de crédito ou débito diretamente na plataforma do sistema de pagamento para utilização deste serviço.
    12 - Usuários: pessoa física, plenamente capaz de acordo com o Código Civil, apta a buscar serviços e usufruir dos serviços de atendimento nutricional disponibilizados por meio da Plataforma, independentemente de ter se cadastrado. Salvo quando expressamente indicado em contrário, os termos “você”, “seu” ou “sua” se referem ao Usuário.
    13. Dados pessoais: qualquer informação relacionada a uma pessoa natural identificada ou identificável, como, por exemplo, nome, RG, CPF, data de nascimento, endereço, número de telefone e e-mail.

Atualização 18 de fevereiro de 2022.
`;

export default PrivacyTerm;
