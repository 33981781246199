import { useCallback, useEffect, useState } from 'react';
import { faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { AnimatePresence } from 'framer-motion';
import { useParams } from 'react-router-dom';

import Map from '../../map';
import ResetPasswordFormModal from '../../../../components/resetPasswordModal';

import { useAuth } from '../../../../hooks/AuthContext';
import { useMetrics } from '../../../../hooks/MetricsContext';

import {
  Container,
  CardContainer,
  ProfileHeader,
  ProfileOptions,
  CircleBlue,
  CircleYellow,
  CircleOrange,
  ProfileOptionsDropDown,
  ProfessionalInfoContainer,
  ProfessionalDetails,
  SocialMedia,
  InstagramIcon,
  MoreInfo,
  ProfileBody,
  ContactContainer,
  Contact,
  WhatsAppButton,
  StatisticsInfoContainer,
  StatisticsContainer,
} from './style';

import { ProfessionalsProfile } from '../../../../types/hooksInterfaceTypes';

interface ProfileViewProps {
  profileData: ProfessionalsProfile;
  isProfileOwner: boolean;
}

export const ProfileView: React.FC<ProfileViewProps> = ({
  profileData,
  isProfileOwner,
}) => {
  const [isProfileOptionOpen, setIsProfileOptionOpen] = useState(false);
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] =
    useState(false);
  const [editedWhatsapp, setEditedWhatsapp] = useState('');
  const { StartEditingProfile, user } = useAuth();
  const { id } = useParams();
  const { metrics, GetMetrics, SetProfileMetrics, SetAnalyticsEvent } =
    useMetrics();

  useEffect(() => {
    window.scroll({ top: 0 });
    if (profileData.whatsApp) {
      const editedString = profileData.whatsApp
        .replace('(', ' ')
        .replace(')', ' ')
        .replace('-', ' ')
        .replaceAll(' ', '');
      setEditedWhatsapp(editedString);
    }
  }, [profileData]);

  const HandleLoadMetrics = async () => {
    await GetMetrics(Number(id), user.token);
  };

  useEffect(() => {
    if (isProfileOwner) {
      HandleLoadMetrics();
    }
  }, [isProfileOwner]);

  useEffect(() => {
    if (user) return;
    SetProfileMetrics(Number(id), 'profile');
    if (profileData.name) {
      SetAnalyticsEvent(
        `profile_view`,
        `conversions`,
        `profile`,
        `Interect with ${profileData.name} ${profileData.lastName} profile`,
      );
    }
  }, [id, profileData, user]);

  const HandleSetProfileMetricsButtons = useCallback(
    (action: string) => {
      if (user) return;
      SetProfileMetrics(Number(id), action);
      SetAnalyticsEvent(
        `${action}_click`,
        `conversions`,
        `profile ${action} button`,
        `Interect with ${
          action === 'instagram'
            ? `${profileData.socialMedia[0]?.replace('@', '')} instagram`
            : `${profileData.name + profileData.lastName} whatsapp`
        }`,
      );
    },
    [id, profileData, user, SetAnalyticsEvent, SetProfileMetrics],
  );

  const HandleOpenResetPasswordModal = useCallback(() => {
    setIsResetPasswordModalOpen(true);
  }, []);
  return (
    <Container>
      <CardContainer>
        <ProfileHeader>
          {isProfileOwner && (
            <StatisticsContainer>
              <h2>Meus Acessos</h2>
              <StatisticsInfoContainer>
                <h3>
                  Perfil: <span>{metrics.profile}</span>
                </h3>
                <h3>
                  Whatsapp: <span>{metrics.whats}</span>
                </h3>
                <h3>
                  Instagram: <span>{metrics.instagram}</span>
                </h3>
              </StatisticsInfoContainer>
            </StatisticsContainer>
          )}
          <ProfessionalInfoContainer>
            <ProfessionalDetails>
              <img src={profileData.image} alt="Avatar" />
              <div>
                <h1>
                  {profileData.name} {profileData.lastName}
                </h1>
                <h2>
                  CRN: {profileData.crn} - {profileData.city},{' '}
                  {profileData.state}
                </h2>
                <h3>
                  Especialidade:
                  {profileData.expertise
                    ? profileData.expertise.map((item, index) => (
                        <span key={index}>{item}</span>
                      ))
                    : ['']}
                </h3>
              </div>
            </ProfessionalDetails>

            <SocialMedia>
              <a
                id="instagram-button-click"
                href={`https://instagram.com/${
                  profileData.socialMedia
                    ? profileData.socialMedia[0].replace('@', '')
                    : ''
                }`}
                target="__blank"
                onClick={() => HandleSetProfileMetricsButtons('instagram')}
              >
                <InstagramIcon icon={faInstagram} />
                <h3>
                  @
                  {profileData.socialMedia
                    ? profileData.socialMedia[0]?.replace('@', '')
                    : ''}
                </h3>
              </a>
            </SocialMedia>

            <MoreInfo>
              <div>
                <h3>Consulta Online?</h3>
                <span>{profileData.onlineAppt}</span>
              </div>
              <div>
                <h3>Aceita plano de saúde?</h3>
                <span>{profileData.healthInsurance}</span>
              </div>
              <a
                id="whatsapp-button-click"
                href={`https://api.whatsapp.com/send?phone=55${editedWhatsapp}&text=Ol%C3%A1!%20Encontrei%20seu%20perfil%20na%20Health%20to%20Go%20e%20queria%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20atendimento!`}
                target="__blank"
                onClick={() => HandleSetProfileMetricsButtons('whats')}
              >
                Entrar em Contato
                <WhatsAppButton icon={faWhatsapp} />
              </a>
            </MoreInfo>
          </ProfessionalInfoContainer>

          <p>{profileData.description}</p>

          {isProfileOwner && (
            <>
              <ProfileOptions
                onClick={() => setIsProfileOptionOpen(!isProfileOptionOpen)}
              >
                <CircleBlue />
                <CircleYellow />
                <CircleOrange />
              </ProfileOptions>
              <ResetPasswordFormModal
                openModal={isResetPasswordModalOpen}
                onCloseModal={() => setIsResetPasswordModalOpen(false)}
              />
            </>
          )}
          <AnimatePresence>
            {isProfileOptionOpen && (
              <ProfileOptionsDropDown
                initial={{ y: -10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -5, opacity: 0 }}
                transition={{ type: 'tween' }}
              >
                <button type="button" onClick={() => StartEditingProfile()}>
                  Editar Perfil
                </button>
                {/* <button
                  type="button"
                  onClick={() => HandleOpenResetPasswordModal()}
                >
                  Trocar Senha
                </button> */}
              </ProfileOptionsDropDown>
            )}
          </AnimatePresence>
        </ProfileHeader>
      </CardContainer>

      <CardContainer>
        <ProfileBody>
          <div>
            <h1>Quem Sou?</h1>
            <p>{profileData.whoAmI}</p>
          </div>
          <h1>Como me Encontrar?</h1>
          <ContactContainer
            style={{
              marginTop: 0,
            }}
          >
            <Contact>
              <div>
                <h2>Endereço</h2>
                <p>
                  {profileData.street_address}, {profileData.street_number} -{' '}
                  {profileData.additional_address}{' '}
                </p>
                <p>{profileData.postal_code}</p>
              </div>
              {profileData.telephone !== '(__) ____-____' ||
                (!profileData.telephone && (
                  <div>
                    <h2>Telefone</h2>
                    <p>{profileData.telephone}</p>
                  </div>
                ))}
              <div>
                <h2>WhatsApp</h2>
                <p>{profileData.whatsApp}</p>
              </div>
              <div>
                <h2>Email</h2>
                <p>{profileData.email}</p>
              </div>
            </Contact>
            <Map
              address={`Brasil, ${profileData.postal_code}, ${profileData.city} -
              ${profileData.state}, ${profileData.street_address}, ${profileData.street_number}`}
            />
          </ContactContainer>
          <div>
            <h1>Experiência</h1>
            <pre>{profileData.experience}</pre>
          </div>
          <div>
            <h1>Área de atuação</h1>
            {profileData.workArea
              ? profileData.workArea.map((item, index) => (
                  <p key={index}>{item}</p>
                ))
              : ['']}
          </div>
          <div>
            <h1>Formações</h1>
            {profileData.education
              ? profileData.education.map((item, index) => (
                  <p key={index}>{item}</p>
                ))
              : ['']}
          </div>
          <div>
            <h1>Como será nossa consulta?</h1>
            <pre>{profileData.medical_appt_procedure}</pre>
          </div>
        </ProfileBody>
      </CardContainer>
    </Container>
  );
};
