import { useCallback, useState } from 'react';
import { faStar } from '@fortawesome/free-solid-svg-icons';

import { Container, StarIcon } from './style';
import { useSignUpInfo } from '../../hooks/SignUpContext';

interface StarRateProps {
  rate?: number;
  disabled?: boolean;
}

export const StarRate: React.FC<StarRateProps> = ({ rate = 0, disabled }) => {
  const [rating, setRating] = useState(rate - 1);
  const { HandleStarRate } = useSignUpInfo();

  const handleSetStarRating = useCallback(
    (rateIndex: number) => {
      setRating(rateIndex);
      HandleStarRate(rateIndex);
    },
    [HandleStarRate],
  );
  return (
    <Container>
      {[...Array(5)].map((star, index) => (
        <button
          disabled={disabled}
          type="button"
          key={index}
          onClick={() => handleSetStarRating(index)}
        >
          <StarIcon $rate={rating >= index} icon={faStar} />
        </button>
      ))}
    </Container>
  );
};
