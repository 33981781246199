import { SelectInput, LabelContainer } from './style';

interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  label?: string;
}

const UncontrolledSelect: React.FC<SelectProps> = ({
  label,
  children,
  ...rest
}) => {
  return (
    <div>
      <LabelContainer>{label}</LabelContainer>
      <SelectInput {...rest}>{children}</SelectInput>
    </div>
  );
};

export default UncontrolledSelect;
