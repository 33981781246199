export const experienceAreaOptions = [
  'Selecione...',
  'Alergias alimentares',
  'Diabetes',
  'Distúrbios alimentares',
  'Doenças autoimunes',
  'Doença celíaca',
  'Doenças inflamatórias intestinais',
  'Emagrecimento',
  'Fertilidade',
  'Hipertensão',
  'Hipertrofia',
  'Intolerância a lactose',
  'Nutrição Comportamental',
  'Nutrição clínica',
  'Nutrição em oncologia',
  'Nutrição esportiva',
  'Nutrição fitoterápica',
  'Nutrição funcional',
  'Nutrição materno-infantil',
  'Nutrição ortomolecular',
  'Ortomolecular',
  'Pediátrica',
  'Reeducação alimentar',
  'Saúde da mulher',
  'Saúde intestinal',
  'Síndrome do intestino irritável',
  'Transtornos alimentares',
  'Vegetarianismo/Veganismo',
];

export const advancedOptions = ['Não', 'Sim'];

export const healthInsuranceFilter = ['Plano de Saude', 'Sim', 'Não'];
export const onlineAppointmentFilter = ['Consulta Online', 'Sim', 'Não'];

export const HowMeetUsOptions = [
  'Selecione...',
  'Indicação',
  'Google',
  'Instagram',
];
