import { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import { Container, LabelContainer } from './style';

interface props extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  label?: string;
  height?: number;
}

export const ControllerTextArea: React.FC<props> = ({
  name,
  label,
  height,
  ...rest
}) => {
  const TextAreaRef = useRef<HTMLTextAreaElement>(null);

  const { defaultValue, fieldName, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: TextAreaRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container haveError={!!error} containerHeight={height}>
      <div>
        <LabelContainer>{label}</LabelContainer>
        <textarea defaultValue={defaultValue} ref={TextAreaRef} {...rest} />
        {error && <span>{error}</span>}
      </div>
    </Container>
  );
};
