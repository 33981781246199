import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';

interface NavigatorContainerProps {
  isnavclose: 'true' | undefined;
}

interface NavigationTargetProps {
  isfocus: 'true' | undefined;
}

interface NavigationThemeTargetProps {
  isfocus: 'true' | undefined;
}

interface PainelContainerProps {
  isnavclose: 'true' | undefined;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;

  overflow: hidden;

  img {
    height: 5rem;
    width: 5rem;
    object-fit: contain;
  }

  h1 {
    margin-bottom: 0.75rem;
    font-size: 1.4rem;
    color: var(--text-title);
  }
`;

export const NavContainer = styled.aside<NavigatorContainerProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 8.5rem;
  height: 100%;
  border: 1px solid var(--card-border);
  position: fixed;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.2);
  left: 0;
  top: 0;
  padding-bottom: 0.5rem;

  transition: 0.3s ease-in-out;
  margin-left: ${(props) => (props.isnavclose ? -8.5 : 0)}rem;
`;

export const BarsIcon = styled(FontAwesomeIcon)`
  width: 0.9rem;
  color: var(--text-title);
`;

export const NavigationTarget = styled.button<NavigationTargetProps>`
  background: none;
  height: 1.9rem;
  width: 90%;
  position: relative;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0.4rem;
  border: none;
  border-bottom: 1px solid var(--card-border);

  h3 {
    font-size: 0.9rem;
    color: var(--text-title);
  }

  div {
    position: absolute;
    height: 1.9rem;
    width: 0.2rem;
    border-radius: 0.5rem;
    top: 0;
    left: 0;

    transition: 0.3s;
  }

  ${(props) =>
    props.isfocus &&
    css`
      padding: 0 0.6rem;

      div {
        background-color: var(--blue_500);
      }
    `}

  &:hover {
    padding: 0 0.7rem;
    color: var(--blue_500);

    filter: none;
    div {
      background-color: var(--blue_500);
    }
  }

  &:last-child {
    margin-top: auto;
    border: none;
  }
`;

export const ThemeTargetContainer = styled.div<NavigationThemeTargetProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 90%;
  transition: 0.3s ease-in-out;
  overflow: hidden;

  height: ${(props) => (props.isfocus ? 6.3 : 0)}rem;
`;

export const ThemeTarget = styled.button<NavigationTargetProps>`
  background: none;
  width: 90%;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.3rem 0.4rem;
  border: none;
  border-bottom: 1px solid var(--card-border);

  h3 {
    color: var(--text-title);
    font-size: 0.7rem;
  }

  ${(props) =>
    props.isfocus &&
    css`
      border-bottom: 1px solid var(--blue_500);
    `}

  &:hover {
    border-bottom: 1px solid var(--blue_500);
    filter: none;
  }
`;

export const PainelContainer = styled.main<PainelContainerProps>`
  width: 100%;
  height: 100%;
  transition: 0.3s ease-in-out;

  .painel-header {
    width: 100%;
    height: 3.5rem;
    background: var(--background);
    position: fixed;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;

    margin-left: ${(props) => (props.isnavclose ? 0 : 9)}rem;
    padding-left: ${(props) => (props.isnavclose ? 2.7 : 0.8)}rem;
  }

  .painel-title {
    margin-top: 1.1rem;
  }

  .toggle-nav-button {
    transition: 0.3s ease-in-out;

    position: fixed;
    top: 0.5rem;
    left: ${(props) => (props.isnavclose ? 0 : -0.3)}rem;
    border: none;
    padding: 1rem;
    background: none;
  }

  padding: 4rem 1.5rem 1.5rem ${(props) => (props.isnavclose ? 1.5 : 9.5)}rem;
`;
