import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Container = styled.div`
  display: flex;
  position: relative;

  background-color: var(--yellow_500);
  height: 100%;
  width: 100%;
  max-width: 100vw;
  height: 100svh;

  flex-wrap: wrap;
  flex-direction: row;
  padding-left: 5%;

  a {
    position: absolute;

    top: 5%;
    left: 2%;
  }

  @media (min-width: 800px) {
    flex-wrap: nowrap;
  }

  @media (max-width: 800px) {
    justify-content: center;
    align-items: center;
    padding-left: 0;
    h1 {
      font-size: 2.5rem;
    }

    a {
      top: 3%;
      left: 5%;
    }
  }
`;

export const ArrowIcon = styled(FontAwesomeIcon)`
  width: 1.5rem;
  height: 1.5rem;
  color: var(--white-text);

  transition: filter 0.2s;
  &:hover {
    filter: brightness(0.8);
  }
`;

export const SideContainer = styled.div`
  width: 29%;
  background-color: var(--shape);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-top: 0.5rem;
  border-top-left-radius: 3rem;
  padding: 0 1.5rem;

  img {
    width: 100%;
    max-width: 24rem;
    height: 23.938rem;
    object-fit: contain;
  }

  @media (max-width: 800px) {
    width: 100%;
    margin-left: 0;
    margin-top: auto;

    border-top-left-radius: 0;

    img {
      max-height: 12rem;
      max-width: 12rem;
    }
  }
`;
