import { useCallback, useEffect, useState } from 'react';
import { Link, redirect, useParams } from 'react-router-dom';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import AdminPainel from './painel';

import Logo from '../../assets/health_to_go_logo.png';

import { Button } from '../../components/button';

import { Container, ArrowIcon } from './style';
import { LoadingIcon } from '../../components/button/loadingComponent';
import api from '../../services/api';
import ErrorModal from '../../components/errorModal';

const AdminPage: React.FC = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const { token } = useParams();

  const handleAWSLogIn = useCallback(async () => {
    setIsLoading(true);
    const response = await api.get('/admin/saml2');
    window.location.href = response.data.redirect_url;
  }, []);

  useEffect(() => {
    if (!token) {
      api.defaults.headers.common.Authorization = '';
      setIsLoading(false);
      setAuthenticated(false);
    }

    if (token) {
      setIsLoading(true);
      api
        .get('/admin/isauthenticated', {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            api.defaults.headers.common.Authorization = token;
            setIsLoading(false);
            setAuthenticated(true);
          }
        })
        .catch(() => {
          setShowErrorModal(true);
          api.defaults.headers.common.Authorization = '';
          setIsLoading(false);
          setAuthenticated(false);
        });
    }
  }, [token]);

  return (
    <Container>
      {authenticated ? (
        <AdminPainel />
      ) : (
        <div className="admin-login">
          <Link to="/">
            <ArrowIcon icon={faArrowLeft} />
          </Link>
          <img src={Logo} alt="" />
          {isLoading ? (
            <>
              <h2>{token ? 'Autenticando' : 'Redirecionando'}</h2>
              <LoadingIcon loadingColor="orange" />
            </>
          ) : (
            <Button
              ButtonColor="blue"
              type="button"
              onClick={() => handleAWSLogIn()}
            >
              Continuar com AWS
            </Button>
          )}
        </div>
      )}
      <ErrorModal
        isModalOpen={showErrorModal}
        message="Token de autorização inválido. Tente novamente"
        functionProp={() => setShowErrorModal(false)}
      />
    </Container>
  );
};

export default AdminPage;
