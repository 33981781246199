import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface StarProps {
  $rate: boolean;
}

export const Container = styled.div`
  margin-top: auto;
  button {
    background: none;
    border: none;

    :disabled {
      :hover {
        filter: brightness(1);
        cursor: default;
      }
    }
  }
`;

export const StarIcon = styled(FontAwesomeIcon)<StarProps>`
  width: 1.875rem;
  height: 1.875rem;

  color: var(--text-title);

  background: none;
  border: none;

  ${(props) =>
    props.$rate &&
    css`
      color: var(--yellow_500);
    `}
`;
