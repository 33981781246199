import Lottie from 'react-lottie';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: var(--background);
  height: 100vh;

  display: flex;
  position: relative;
  padding: 0 2rem;

  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button {
    margin-top: 6rem;
    padding: 0 2rem;
  }

  h1 {
    font-weight: bold;
    font-size: 2rem;
    color: var(--text-title);
    margin-top: 3rem;
    text-align: center;
  }
  @media (max-width: 800px) {
    justify-content: flex-start;

    h1 {
      font-size: 1.2rem;
    }
  }
`;

export const Animation = styled(Lottie)``;
