import styled from 'styled-components';
import { Form } from '@unform/web';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const AnimationContainer = styled(motion.div)`
  width: 200rem;
`;

export const Container = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 1rem;
  position: relative;

  @media (max-width: 800px) {
    justify-content: center;
    padding: 2rem 0;
  }
`;

export const CloseModalButton = styled.button`
  position: absolute;
  right: -4%;
  top: -4%;

  width: 2rem;
  height: 2rem;

  display: flex;
  align-items: center;
  justify-content: center;

  background: none;
  border: none;
`;

export const CloseIcon = styled(FontAwesomeIcon)`
  width: 1rem;
  height: 1rem;

  color: var(--text-title);
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;

  h1 {
    font-size: 2rem;
    font-weight: 700;
    color: var(--orange_500);
    margin-bottom: 2rem;
  }

  input {
    background-color: var(--card-border);
    color: var(--text-body);
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1.188rem;
  border-top: 1px solid var(--card-border);

  button {
    margin-top: 1.188rem;
    font-size: 1.1rem;
  }

  @media (max-width: 800px) {
    button {
      display: block;
      margin: 2.188rem auto 0;
    }
  }
`;
