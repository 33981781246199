import { LoadingContext } from '../../hooks/LoadingContext';
import { LoadingIcon } from './loadingComponent';
import { Container } from './style';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  ButtonColor: string;
}

export const Button: React.FC<ButtonProps> = ({
  ButtonColor,
  children,
  ...rest
}) => {
  const { isLoading } = LoadingContext();
  return (
    <Container type="button" Colors={ButtonColor} {...rest}>
      {isLoading ? <LoadingIcon /> : children}
    </Container>
  );
};
