import { useState } from 'react';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

import PrivicyPolicyModal from '../privicyPolicy';
import TermModal from '../termsModal';

import {
  Container,
  MainContent,
  InfoContainer,
  TermsContainer,
  SocialContainer,
  InstagramIcon,
  BlueLine,
  OrangeLine,
  YellowLine,
} from './style';

export const Footer: React.FC = () => {
  const [privicyModal, setPrivicyModal] = useState(false);
  const [termsModal, setTermsModal] = useState(false);

  function openModal(value: string) {
    switch (value) {
      case 'privicy':
        setPrivicyModal(true);
        break;
      case 'terms':
        setTermsModal(true);
        break;
      default:
        break;
    }
  }

  function closeModal(value: string) {
    switch (value) {
      case 'privicy':
        setPrivicyModal(false);
        break;
      case 'terms':
        setTermsModal(false);
        break;
      default:
        break;
    }
  }

  return (
    <Container>
      <MainContent>
        <InfoContainer>
          <div>
            <h2>Ajuda</h2>
            <TermsContainer>
              <button type="button" onClick={() => openModal('privicy')}>
                Política de Privacidade
              </button>
              <button type="button" onClick={() => openModal('terms')}>
                Termos de Uso
              </button>
            </TermsContainer>
          </div>
          <div>
            <h2>Contato</h2>
            <a href="mailto: atendimento@healthtogo.com.br">
              atendimento@healthtogo.com.br
            </a>
          </div>
        </InfoContainer>
        <SocialContainer>
          <h2>Nos Acompanhe</h2>
          <a
            href="https://www.instagram.com/healthtogo_oficial"
            target="_blank"
            rel="noreferrer"
          >
            <InstagramIcon icon={faInstagram} />
          </a>
        </SocialContainer>
      </MainContent>

      <BlueLine />
      <YellowLine />
      <OrangeLine />

      <PrivicyPolicyModal
        isOpen={privicyModal}
        onClick={() => closeModal('privicy')}
      />
      <TermModal isOpen={termsModal} onClick={() => closeModal('terms')} />
    </Container>
  );
};
