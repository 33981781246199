import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useMemo,
} from 'react';
import api from '../services/api';

import {
  FilterSearchParms,
  GetProfessionalsContextProvider,
  ProfessionalsListData,
  ProfessionalsListDataResponse,
} from '../types/hooksInterfaceTypes';

const ProfessionalsContext = createContext<GetProfessionalsContextProvider>(
  {} as GetProfessionalsContextProvider,
);

export const GetProfessionalsProvider: React.FC = ({ children }) => {
  const [nutritionistsList, setNutritionistsList] = useState<
    ProfessionalsListData[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [notFoundModalOpen, setNotFoundModalOpen] = useState(false);

  const GetProfessionalsList = useCallback(async () => {
    setIsLoading(true);
    setNotFoundModalOpen(false);

    api.get<ProfessionalsListDataResponse>('nutritionists').then((response) => {
      const userData = response.data.nutritionists.map((item) => ({
        ...item,
        healthInsurance: item.healthInsurance === true ? 'Sim' : 'Não',
        onlineAppt: item.onlineAppt === true ? 'Sim' : 'Não',
      }));
      setNutritionistsList(userData);
      setIsLoading(false);
    });
  }, []);

  const GetFilteredProfessionalsList = useCallback(
    async (parms: FilterSearchParms) => {
      setIsLoading(true);
      api
        .get<ProfessionalsListDataResponse>('nutritionists', {
          params: {
            workArea: parms.workArea,
            city: parms.city,
            onlineAppt: parms.onlineAppt,
            healthInsurance: parms.healthInsurance,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            if (response.data.nutritionists.length <= 0) {
              setNotFoundModalOpen(true);
              return;
            }

            const userData = response.data.nutritionists.map((item) => ({
              ...item,
              healthInsurance: item.healthInsurance === true ? 'Sim' : 'Não',
              onlineAppt: item.onlineAppt === true ? 'Sim' : 'Não',
            }));
            setNutritionistsList(userData);
            setIsLoading(false);
          }
        });
    },
    [],
  );

  const providerHooks = useMemo(
    () => ({
      nutritionistsList,
      isLoading,
      notFoundModalOpen,
      GetProfessionalsList,
      GetFilteredProfessionalsList,
    }),
    [
      nutritionistsList,
      isLoading,
      notFoundModalOpen,
      GetProfessionalsList,
      GetFilteredProfessionalsList,
    ],
  );

  return (
    <ProfessionalsContext.Provider value={providerHooks}>
      {children}
    </ProfessionalsContext.Provider>
  );
};

export function GetProfessionals(): GetProfessionalsContextProvider {
  const context = useContext(ProfessionalsContext);

  if (!context) {
    console.log('Provider mus be used in a ContextProvider');
  }

  return context;
}
