import { useCallback, useRef } from 'react';
import { FormHandles } from '@unform/core';
import Modal from 'react-modal';
import * as Yup from 'yup';

import Logo from '../../../../assets/health_to_go_logo.png';

import { useSignUpInfo } from '../../../../hooks/SignUpContext';

import { ControllerInput } from '../../../../components/controllerInput';
import { ControllerMaskedInput } from '../../../../components/controllerInput/controllerMaskedInput';
import { Button } from '../../../../components/button';

import { PaymentProps } from '../../../../types/hooksInterfaceTypes';

import {
  Container,
  PaymentContainer,
  Main,
  PaymentInfo,
  NumberInputsContainer,
  Footer,
} from './style';
import getValidationErrors from '../../../../utils/validateErrors';

const FormSignUpPayment: React.FC = () => {
  const { SignUpConfirmationOut } = useSignUpInfo();
  const formRef = useRef<FormHandles>(null);

  const handleProfessionalPayment = useCallback(
    async (payment: PaymentProps) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          cardNumber: Yup.string().required('Numero do cartão é obrigatório.'),
          expire: Yup.string().required('Validade é obrigatório.'),
          cv: Yup.string().required('CV é obrigatório.'),
          cardOwnerName: Yup.string().required(
            'Nome do titular é obrigatório.',
          ),
        });
        await schema.validate(payment, {
          abortEarly: false,
        });
        // await SignUp();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const err = getValidationErrors(error);

          formRef.current?.setErrors(err);
        }
      }
    },
    [],
  );

  const handleCloseModal = useCallback(() => {
    SignUpConfirmationOut();
  }, [SignUpConfirmationOut]);

  return (
    <Modal
      isOpen
      onRequestClose={handleCloseModal}
      className="react-modal-content"
      overlayClassName="react-modal-overlay"
    >
      <Container initial={{ scale: 0.9 }} animate={{ scale: 1 }}>
        <PaymentContainer>
          <Main ref={formRef} onSubmit={handleProfessionalPayment}>
            <h1>Pagamento</h1>
            <ControllerMaskedInput
              mask="9999 9999 9999 9999"
              type="text"
              name="cardNumber"
              label="Número do Cartão"
            />

            <ControllerInput
              type="text"
              name="cardOwnerName"
              label="Nome do Titular"
            />

            <NumberInputsContainer>
              <ControllerMaskedInput
                mask="99/99"
                type="text"
                name="expire"
                label="Validade"
              />

              <ControllerMaskedInput
                mask="999"
                type="text"
                name="cv"
                label="CV"
              />
            </NumberInputsContainer>
          </Main>
          <PaymentInfo>
            <h2>Sobre sua assinatura:</h2>
            <p>
              Após o preenchimento do cadastro e pagamento, nossa equipe fará
              uma autenticação dos dados para garantir a sua segurança e a
              segurança dos pacientes. <br /> <br />
              Dessa forma, confirmaremos se o seu registro no{' '}
              <strong>CRN</strong> condiz com seus dados pessoais. O tempo de
              autenticação leva até <strong>3 dias úteis</strong>. Caso haja
              qualquer problema durante a autenticação dos dados,
              impossibilitando a criação de um perfil, reembolsaremos
              integralmente o valor pago.
              <br />
              <br />
              Fazemos tudo isso para que profissionais e pacientes possam ter a
              melhor experiência possível com a <span>Health to Go!</span> 😊
            </p>
          </PaymentInfo>
        </PaymentContainer>
        <Footer>
          <img src={Logo} alt="HealthToGo" />
        </Footer>
        <Button
          type="button"
          ButtonColor="blue500"
          onClick={() => formRef.current?.submitForm()}
        >
          Go!
        </Button>
      </Container>
    </Modal>
  );
};

export default FormSignUpPayment;
