import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
  width: 100%;
  h1 {
    font-size: 2rem;
    font-weight: 700;
    color: var(--orange_500);
    margin-bottom: 2rem;
  }

  h2 {
    font-size: 1.5rem;
    color: var(--text-title);
    max-width: 25rem;
    margin-left: 0.625rem;
  }

  div {
    width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: flex-end;

    border-bottom: 1px solid var(--card-border);

    img {
      width: 8.75rem;
      height: 8.75rem;
      object-fit: contain;
      margin-right: 0.625rem;
    }

    @media (max-width: 800px) {
      img {
        width: 5.75rem;
        height: 5.75rem;
      }
    }
  }

  button {
    margin: 1.188rem auto 0;
  }
`;
