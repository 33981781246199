import { useCallback, useEffect, useMemo, useState } from 'react';

import { useMetrics } from '../../../hooks/MetricsContext';
import { GetProfessionals } from '../../../hooks/GetProfessionalsList';

import { Button } from '../../../components/button';
import Select from '../../../components/select/uncontrolledSelect';
import NotFoundModal from './notFoundModal';

import { experienceAreaOptions } from '../../../utils/selectOptions';

import {
  Container,
  Content,
  DetailsContainer,
  SelectContainer,
  FiltersContainer,
  AdvancedContainer,
  FilterAdvancedButtons,
} from './style';

export const Search: React.FC = () => {
  const [localeItems, setLocaleItems] = useState(['Localidade']);
  const [onlineAppointment, setOnlineAppointment] = useState(false);
  const [healthInsureance, setHealthInsureance] = useState(false);
  const [selectedCity, setSelectedCity] = useState('Localidade');
  const [selectedSpecialist, setSelectedSpecialist] = useState('Especialidade');
  const {
    GetFilteredProfessionalsList,
    nutritionistsList,
    notFoundModalOpen,
    GetProfessionalsList,
  } = GetProfessionals();
  const { SetAnalyticsEvent } = useMetrics();

  useEffect(() => {
    if (localeItems.length > 1) return;
    if (nutritionistsList) {
      if (nutritionistsList.length > 0) {
        const localeItemsArray = nutritionistsList.map((item) => {
          return item.city;
        });
        setLocaleItems(localeItemsArray);
      }
    }
  }, [nutritionistsList, localeItems]);

  const localeItemsFiltered = useMemo(() => {
    const filteredSetItems = new Map();
    localeItems.forEach((city) => {
      if (!filteredSetItems.has(city)) {
        filteredSetItems.set(city, city);
      }
    });
    const localeSearch = Array.from(filteredSetItems.values());
    localeSearch.unshift('Localidade');
    return localeSearch;
  }, [localeItems]);

  const SpecialistItemsFiltered = useMemo(() => {
    const filteredOptions = experienceAreaOptions.filter(
      (item) => item !== 'Selecione...',
    );

    filteredOptions.unshift('Especialidade');
    return filteredOptions;
  }, []);

  const HandleFilteredSearch = useCallback(async () => {
    if (
      selectedSpecialist === 'Especialidade' &&
      selectedCity === 'Localidade' &&
      onlineAppointment === false &&
      healthInsureance === false
    )
      return;

    const params = {
      workArea:
        selectedSpecialist === 'Especialidade' ? undefined : selectedSpecialist,
      city: selectedCity === 'Localidade' ? undefined : selectedCity,
      onlineAppt: onlineAppointment === false ? undefined : onlineAppointment,
      healthInsurance:
        healthInsureance === false ? undefined : healthInsureance,
    };

    GetFilteredProfessionalsList(params);
    SetAnalyticsEvent(
      'search_form',
      'search',
      'filterd',
      `user searched for: ${JSON.stringify(params)}`,
    );
  }, [
    GetFilteredProfessionalsList,
    onlineAppointment,
    healthInsureance,
    selectedCity,
    selectedSpecialist,
    SetAnalyticsEvent,
  ]);

  const HandleCleanFilteredSearch = useCallback(() => {
    if (
      selectedSpecialist === 'Especialidade' &&
      selectedCity === 'Localidade' &&
      !onlineAppointment &&
      !healthInsureance
    )
      return;

    GetProfessionalsList();
    setSelectedSpecialist('Especialidade');
    setSelectedCity('Localidade');
    setOnlineAppointment(false);
    setHealthInsureance(false);
  }, [
    GetProfessionalsList,
    selectedSpecialist,
    selectedCity,
    onlineAppointment,
    healthInsureance,
  ]);

  return (
    <>
      <Container>
        <Content>
          <DetailsContainer>
            <div>
              <h1 className="hero-title">
                Nós acreditamos que uma alimentação saudável pode transformar
                vidas
              </h1>
              <h2>Encontre um nutricionista agora mesmo!</h2>
            </div>
          </DetailsContainer>
          <FiltersContainer>
            <SelectContainer>
              <div>
                <Select
                  name="workArea"
                  value={selectedSpecialist}
                  onChange={(e) => setSelectedSpecialist(e.target.value)}
                >
                  {SpecialistItemsFiltered.map((areaItem, itemIndex) => (
                    <option value={areaItem} key={itemIndex}>
                      {areaItem}
                    </option>
                  ))}
                </Select>
              </div>
              <div>
                <Select
                  name="city"
                  value={selectedCity}
                  onChange={(e) => setSelectedCity(e.target.value)}
                >
                  {localeItemsFiltered.map((cityItem, itemIndex) => (
                    <option value={cityItem} key={itemIndex}>
                      {cityItem}
                    </option>
                  ))}
                </Select>
              </div>
              <div className="buttonsDiv">
                <button
                  type="button"
                  className="cleanButton"
                  onClick={HandleCleanFilteredSearch}
                >
                  Limpar
                </button>
                <Button
                  id="search-form-button"
                  type="button"
                  ButtonColor="blue500"
                  onClick={HandleFilteredSearch}
                >
                  Filtrar
                </Button>
              </div>
            </SelectContainer>
            <AdvancedContainer>
              <FilterAdvancedButtons
                type="button"
                onClick={() => setOnlineAppointment(!onlineAppointment)}
                isSelected={onlineAppointment}
              >
                Consulta Online
              </FilterAdvancedButtons>
              <FilterAdvancedButtons
                type="button"
                onClick={() => setHealthInsureance(!healthInsureance)}
                isSelected={healthInsureance}
              >
                Plano de Saude
              </FilterAdvancedButtons>
            </AdvancedContainer>
          </FiltersContainer>
        </Content>
      </Container>
      <NotFoundModal
        functionProp={HandleCleanFilteredSearch}
        isOpen={notFoundModalOpen}
      />
    </>
  );
};
