import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 2.688rem 5.5rem;

  h1 {
    font-weight: 700;
    font-size: 2rem;
    margin-top: 2.688rem;

    color: var(--blue_500);

    @media (max-width: 800px) {
      font-size: 1.5rem;
      margin: 0 repeat(2, 1.5rem);
    }
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 2.688rem;

  @media (max-width: 800px) {
    flex-wrap: wrap;
    margin-top: 1.5rem;
  }
`;

export const ListContainer = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5rem;
  margin-top: 2.688rem;

  button {
    width: 10.625rem;
    height: 3.188rem;

    font-size: 1.125rem;
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-gap: 3rem;
    margin-top: 1.5rem;
  }
`;

export const CardContainer = styled.li`
  list-style: none;
  background-color: var(--shape);
  max-width: 31.328rem;
  min-height: 25.188rem;
  border-radius: 0.5rem;

  display: flex;
  flex-direction: column;
`;

export const Main = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1.5rem;

  img {
    width: 16.813rem;
    height: 9.313rem;
    object-fit: cover;
  }

  p {
    margin-top: 1.688rem;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.625rem;
    text-align: left;

    color: var(--text-body);
  }
`;

export const CardFooter = styled.footer`
  background-color: var(--card-footer-background);
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  padding: 1.5rem;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: auto;
`;
