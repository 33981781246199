/* eslint-disable camelcase */
import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useMemo,
} from 'react';

import {
  MetricsContextProvider,
  ProfileMetrics,
} from '../types/hooksInterfaceTypes';
import api from '../services/api';

const MetricsCreateContext = createContext<MetricsContextProvider>(
  {} as MetricsContextProvider,
);

export const ProfileMetricsContextProvider: React.FC = ({ children }) => {
  const [metrics, setMetrics] = useState<ProfileMetrics>({} as ProfileMetrics);

  const GetMetrics = useCallback(async (id: number, token: string) => {
    const response = await api.get<ProfileMetrics>(
      `nutritionist/${id}/metrics`,
      {
        headers: {
          Authorization: token,
        },
      },
    );
    setMetrics(response.data);
  }, []);

  const SetProfileMetrics = useCallback(
    (nutritionist_id: number, button_name: string) => {
      api.post(`metrics`, {
        button_name,
        nutritionist_id,
      });
    },
    [],
  );

  const SetAnalyticsEvent = useCallback(
    (event: string, category: string, action: string, label: string) => {
      window.dataLayer?.push({
        event,
        eventProps: {
          category,
          action,
          label,
        },
      });
    },
    [],
  );

  const providerHooks = useMemo(
    () => ({
      metrics,
      GetMetrics,
      SetProfileMetrics,
      SetAnalyticsEvent,
    }),
    [metrics, GetMetrics, SetProfileMetrics, SetAnalyticsEvent],
  );

  return (
    <MetricsCreateContext.Provider value={providerHooks}>
      {children}
    </MetricsCreateContext.Provider>
  );
};

export function useMetrics(): MetricsContextProvider {
  const context = useContext(MetricsCreateContext);

  if (!context) {
    console.log('Provider mus be used in a ContextProvider');
  }

  return context;
}
