import Modal from 'react-modal';

import Logo from '../../assets/health_to_go_logo.png';

import { Button } from '../button';

import { Container } from './style';

interface ErrorProps {
  message: string;
  isModalOpen?: boolean;
  functionProp(): void;
}

const ErrorModal: React.FC<ErrorProps> = ({
  message,
  isModalOpen = true,
  functionProp,
}) => {
  return (
    <Modal
      isOpen={isModalOpen}
      closeTimeoutMS={200}
      className="react-modal-content"
      overlayClassName="react-modal-overlay"
    >
      <Container initial={{ scale: 0.9 }} animate={{ scale: 1 }}>
        <h1>Ops! Health, temos um problema...</h1>
        <h2>{message}</h2>
        <div>
          <img src={Logo} alt="HealthToGo" />
        </div>
        <Button type="button" ButtonColor="blue500" onClick={functionProp}>
          OK
        </Button>
      </Container>
    </Modal>
  );
};

export default ErrorModal;
