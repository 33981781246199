import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

import { useMetrics } from '../../../hooks/MetricsContext';
import { useAuth } from '../../../hooks/AuthContext';

import { Button } from '../../../components/button';
import SkeletonProfessionalListLoad from '../../../components/SkeletonLoad/ProfessionalListLoad';
import { GetProfessionals } from '../../../hooks/GetProfessionalsList';

import {
  Container,
  CardContainer,
  Main,
  ProfessionalInfoContainer,
  ProfessionalDetails,
  SocialMedia,
  InstagramIcon,
  CardFooter,
} from './style';

export const ProfessionalCard: React.FC = () => {
  const navigate = useNavigate();
  const { GetProfessionalsList, nutritionistsList, isLoading } =
    GetProfessionals();
  const { user } = useAuth();
  const { SetProfileMetrics, SetAnalyticsEvent } = useMetrics();

  const [pagination, setPagination] = useState(20);

  useEffect(() => {
    async function GetList() {
      await GetProfessionalsList();
    }
    GetList();
  }, [GetProfessionalsList]);

  const HandleSetProfileMetricsButtons = useCallback(
    (id: number, action: string, userSocialMedia: string) => {
      if (user) return;
      SetProfileMetrics(id, action);
      SetAnalyticsEvent(
        `instagram_click`,
        `conversions`,
        `dashboard instagram button`,
        `Interect with ${userSocialMedia} instagram`,
      );
    },
    [user, SetAnalyticsEvent, SetProfileMetrics],
  );

  function NavigateToProfile(position: number, name: string, lastname: string) {
    navigate(`/profile/${position}`);
    SetAnalyticsEvent(
      `profile_view`,
      `conversions`,
      `profile`,
      `Interect with ${name} ${lastname} profile`,
    );
  }

  const randomList = useMemo(() => {
    return nutritionistsList.sort(() => Math.random() - 0.5);
  }, [nutritionistsList]);

  return (
    <Container>
      {isLoading ? (
        <SkeletonProfessionalListLoad />
      ) : (
        <>
          {randomList
            .map((item, nutriIndex) => (
              <CardContainer key={item.nutritionist_id}>
                <Main>
                  <ProfessionalInfoContainer>
                    <ProfessionalDetails>
                      <button
                        type="button"
                        className="profileButton"
                        onClick={() =>
                          NavigateToProfile(
                            item.nutritionist_id,
                            item.name,
                            item.lastName,
                          )
                        }
                      >
                        <img
                          id="profile-image-button"
                          src={item.image}
                          alt={item.name}
                          loading={nutriIndex === 0 ? undefined : 'lazy'}
                        />
                      </button>
                      <div>
                        <button
                          id="profile-name-button"
                          type="button"
                          className="profileButton"
                          onClick={() =>
                            NavigateToProfile(
                              item.nutritionist_id,
                              item.name,
                              item.lastName,
                            )
                          }
                        >
                          <h1 id="profile-name-button">
                            {item.name} {item.lastName}
                          </h1>
                        </button>
                        <h2>
                          CRN: {item.crn.toString().replaceAll('_', '')} -{' '}
                          {item.city}, {item.state}
                        </h2>
                        <h3>
                          Especialidade:{' '}
                          {item.expertise.map((area, index) => (
                            <span key={index}>
                              {area}
                              {item.expertise.length - 1 === index
                                ? '.'
                                : ','}{' '}
                            </span>
                          ))}
                        </h3>
                      </div>
                    </ProfessionalDetails>

                    <SocialMedia>
                      <a
                        id="instagram-button-click"
                        href={`https://instagram.com/${item.socialMedia[0].replace(
                          '@',
                          '',
                        )}`}
                        target="__blank"
                        onClick={() =>
                          HandleSetProfileMetricsButtons(
                            item.nutritionist_id,
                            'instagram',
                            item.socialMedia[0]?.replace('@', ''),
                          )
                        }
                      >
                        <InstagramIcon icon={faInstagram} />
                        <h3>@{item.socialMedia[0].replace('@', '')}</h3>
                      </a>
                    </SocialMedia>
                  </ProfessionalInfoContainer>

                  <p>{item.description}</p>
                </Main>
                <CardFooter>
                  <div>
                    <h3>Atende Online? </h3>
                    <span>{item.onlineAppt}</span>
                  </div>
                  <div>
                    <h3>Aceita Plano de Saúde? </h3>
                    <span>{item.healthInsurance}</span>
                  </div>
                  <Button
                    id="profile-click"
                    ButtonColor="blue500"
                    onClick={() =>
                      NavigateToProfile(
                        item.nutritionist_id,
                        item.name,
                        item.lastName,
                      )
                    }
                  >
                    Conhecer +
                  </Button>
                </CardFooter>
              </CardContainer>
            ))
            .slice(0, pagination)}
          <Button
            type="button"
            ButtonColor="orange"
            onClick={() =>
              setPagination((prev) =>
                prev >= randomList.length ? prev : prev + 20,
              )
            }
          >
            {pagination >= randomList.length
              ? 'Não há mais Nutris'
              : 'Carregar mais Nutris'}
          </Button>
        </>
      )}
    </Container>
  );
};
