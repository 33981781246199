import { useCallback, useEffect, useRef, useState } from 'react';
import { faX, faCamera } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FormHandles } from '@unform/core';
import { useParams } from 'react-router-dom';
import imageCompression from 'browser-image-compression';

import { ControllerInput } from '../../../../components/controllerInput';
import { ControllerMaskedInput } from '../../../../components/controllerInput/controllerMaskedInput';
import { ControllerTextArea } from '../../../../components/controllerTextArea';
import Select from '../../../../components/select';
import { Button } from '../../../../components/button';
import UncontrolledSelect from '../../../../components/select/uncontrolledSelect';
import ModelSuccessConfirmation from '../../../../components/successModal';
import { UncontrollerInput } from '../../../../components/controllerInput/uncontrollerInput';

import { useAuth } from '../../../../hooks/AuthContext';
import { LoadingContext } from '../../../../hooks/LoadingContext';

import {
  ProfessionalsProfile,
  UserProfileUpdateData,
} from '../../../../types/hooksInterfaceTypes';

import { HandleGetCity, HandleGetState } from '../../../../services/locale';

import api from '../../../../services/api';

import {
  advancedOptions,
  experienceAreaOptions,
} from '../../../../utils/selectOptions';

import {
  Container,
  FormContainer,
  ProfileHeader,
  AvatarContainer,
  CloseModalButton,
  CloseIcon,
  LocaleContainer,
  ProfessionalInfoContainer,
  ProfessionalDetails,
  EspecialistContainerEdit,
  SelectContainerEdit,
  SocialMedia,
  InstagramIcon,
  MoreInfo,
  ProfileBody,
  ContactContainer,
  Contact,
  AddressContainer,
  FiCamera,
} from './style';
import ImageResizerModal from '../../../../components/imageResizerModal';

interface ProfileEditProps {
  user: ProfessionalsProfile;
  token: string;
}
export const ProfileEdit: React.FC<ProfileEditProps> = ({ user, token }) => {
  const formRef = useRef<FormHandles>(null);
  const { id } = useParams();
  const { SetIsLoading } = LoadingContext();

  const [especialistItem, setEspecialistItem] = useState(['']);
  const [workAreaItem, setWorkAreaItem] = useState(['']);
  const [graduationItem, setGraduationItem] = useState(['']);

  const [avatarImage, setAvatarImage] = useState<HTMLInputElement>();
  const [avatarImagePreview, setAvatarImagePreview] = useState<any>();
  const [isResizeImageModalOpen, setIsResizeImageModalOpen] = useState(false);

  const { SaveProfileChanges, CancelEditingProfile, UpdateAvatar } = useAuth();

  const [uf, setuf] = useState<string[]>([]);
  const [city, setcity] = useState<string[]>([]);
  const [selectedUf, setSelectedUf] = useState('0');
  const [selectedCity, setSelectedCity] = useState('0');

  const [onlineAppt, setOnlineAppt] = useState<string>(
    user.onlineAppt as string,
  );
  const [healthInsurance, setHealthInsurance] = useState<string>(
    user.healthInsurance as string,
  );

  const [openModal, setOpenModal] = useState(false);

  // Reset the state, if navigate off the profile page, prevent the unauthorizated access to others profiles.

  useEffect(() => {
    return () => {
      CancelEditingProfile();
    };
  }, [CancelEditingProfile]);

  //Set empty Array if they're null.

  useEffect(() => {
    setEspecialistItem(user.expertise ? user.expertise : ['']);
    setWorkAreaItem(user.workArea ? user.workArea : ['']);
    setGraduationItem(user.education ? user.education : ['']);
  }, [user]);

  //Set UF and City from IBGE API. Load cities, based on the selected UF.

  useEffect(() => {
    HandleGetState().then((value) => {
      if (user.state) {
        setSelectedUf(user.state);
        setSelectedCity(user.city);
      }

      setuf(value);
    });
  }, [user.state, user.city]);

  useEffect(() => {
    if (selectedUf === '0') {
      return;
    }

    HandleGetCity(selectedUf).then((value) => {
      setcity(value);
    });
  }, [selectedUf]);

  //Functions to add, change, and delete items from: expertise, workArea and graduation selects Array;

  const handleNewlistItem = useCallback(
    (ItemsArray: Array<string>, MaxItems: number, ArrayName: string) => {
      if (ArrayName !== 'graduation' && ItemsArray.length >= MaxItems) return;

      switch (ArrayName) {
        case 'expertise':
          setEspecialistItem([...ItemsArray, '']);
          break;

        case 'workArea':
          setWorkAreaItem([...ItemsArray, '']);
          break;

        case 'graduation':
          setGraduationItem([...ItemsArray, '']);
          break;
        default:
          break;
      }
    },
    [],
  );

  const handleNewListItemValue = useCallback(
    (
      ItemsArray: Array<string>,
      newValue: string,
      itemIndex: number,
      ArrayName: string,
    ) => {
      const newItemList = ItemsArray.map((item, index) => {
        if (index === itemIndex) {
          return newValue;
        }
        return item;
      });

      switch (ArrayName) {
        case 'expertise':
          setEspecialistItem(newItemList);
          break;

        case 'workArea':
          setWorkAreaItem(newItemList);
          break;

        case 'graduation':
          setGraduationItem(newItemList);
          break;
        default:
          break;
      }
    },
    [],
  );

  const handleDeletelistItem = useCallback(
    (ItemsArray: Array<string>, position: number, ArrayName: string) => {
      if (ItemsArray.length === 1) {
        return;
      }

      const newArray = ItemsArray.filter((item, index) => index !== position);

      switch (ArrayName) {
        case 'expertise':
          setEspecialistItem(newArray);
          break;

        case 'workArea':
          setWorkAreaItem(newArray);
          break;

        case 'graduation':
          setGraduationItem(newArray);
          break;
        default:
          break;
      }
    },
    [],
  );

  // Set Image from the fileInput, and preview before send it;

  const handleNewImageOnInput = useCallback((inputFile: HTMLInputElement) => {
    setAvatarImage(inputFile);
    // setIsResizeImageModalOpen(true);
  }, []);

  useEffect(() => {
    if (avatarImage?.files) {
      const file = avatarImage?.files[0];
      console.log(file);
      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        setAvatarImagePreview(fileReader.result);
      };

      fileReader.readAsDataURL(file);
    }
  }, [avatarImage]);

  // Send the profile changes to the Server
  const handleUpdateUser = useCallback(
    async (form: UserProfileUpdateData) => {
      try {
        formRef.current?.setErrors({});
        SetIsLoading(true);

        const profileEdit = {
          additional_address: form.additional_address,
          description: form.description,
          experience: form.description,
          healthInsurance: healthInsurance === 'Sim',
          onlineAppt: onlineAppt === 'Sim',
          medical_appt_procedure: form.medical_appt_procedure,
          postal_code: form.postal_code,
          socialMedia: new Array(form.socialMedia),
          street_address: form.street_address,
          street_number: Number(form.street_number),
          telephone: form.telephone,
          whatsApp: form.whatsApp,
          whoAmI: form.whoAmI,
          workArea: workAreaItem,
          expertise: especialistItem,
          education: graduationItem,
          state: selectedUf,
          city: selectedCity,
        };

        if (avatarImage?.files) {
          const data = new FormData();
          data.append(
            'IMAGE',
            await imageCompression(avatarImage?.files[0], {
              maxSizeMB: 1,
              maxWidthOrHeight: 320,
              useWebWorker: true,
            }),
            `${user.name}-${user.lastName}-${new Date().getMilliseconds()}`,
          );

          await api.post(`nutritionist/${id}/picture`, data);
        }

        const response = await api
          .put(`nutritionist/${id}`, profileEdit, {
            headers: {
              Authorization: token,
            },
          })
          .catch((error) => {
            throw new Error(error);
          });

        if (avatarImage?.files) {
          UpdateAvatar(response?.data.image);
        }

        SetIsLoading(false);
        setOpenModal(true);
      } catch (err) {
        SetIsLoading(false);
        console.log(err);
      }
    },
    [
      token,
      avatarImage,
      especialistItem,
      graduationItem,
      selectedCity,
      selectedUf,
      workAreaItem,
      onlineAppt,
      healthInsurance,
      SetIsLoading,
      id,
      UpdateAvatar,
      user,
    ],
  );

  return (
    <Container ref={formRef} onSubmit={handleUpdateUser}>
      <FormContainer>
        <ProfileHeader>
          <ProfessionalInfoContainer>
            <ProfessionalDetails>
              <AvatarContainer>
                <img
                  src={!avatarImagePreview ? user.image : avatarImagePreview}
                  alt="Avatar"
                  id="userImage"
                />
                <label htmlFor="avatar">
                  <FiCamera icon={faCamera} />
                  <input
                    type="file"
                    id="avatar"
                    accept="image/*"
                    onChange={(e) => handleNewImageOnInput(e.target)}
                  />
                </label>
              </AvatarContainer>
              <ImageResizerModal
                avatarImage={avatarImagePreview}
                openModal={isResizeImageModalOpen}
                onCloseModal={() => setIsResizeImageModalOpen(false)}
              />
              <div>
                <ControllerInput
                  type="text"
                  name="name"
                  label="Nome"
                  disabled
                  value={`${user.name} ${user.lastName}`}
                />
                <ControllerInput
                  type="text"
                  name="crn"
                  label="CRN"
                  disabled
                  defaultValue={user.crn}
                />
                <LocaleContainer>
                  <UncontrolledSelect
                    name="state"
                    label="UF"
                    value={selectedUf}
                    onChange={(e) => setSelectedUf(e.target.value)}
                  >
                    {uf.map((item) => (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    ))}
                  </UncontrolledSelect>
                  <UncontrolledSelect
                    name="city"
                    label="Cidade"
                    value={selectedCity}
                    onChange={(e) => setSelectedCity(e.target.value)}
                  >
                    {city.map((item) => (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    ))}
                  </UncontrolledSelect>
                </LocaleContainer>
                <EspecialistContainerEdit>
                  <div>
                    <h3>Especialidade</h3>
                    <button
                      type="button"
                      onClick={() =>
                        handleNewlistItem(especialistItem, 3, 'expertise')
                      }
                    >
                      +
                    </button>
                  </div>
                  {especialistItem.map((item, index) => (
                    <SelectContainerEdit key={index}>
                      <UncontrolledSelect
                        value={item}
                        onChange={(e) =>
                          handleNewListItemValue(
                            especialistItem,
                            e.target.value,
                            index,
                            'expertise',
                          )
                        }
                      >
                        {experienceAreaOptions.map((areaItems, itensIndex) => (
                          <option value={areaItems} key={itensIndex}>
                            {areaItems}
                          </option>
                        ))}
                      </UncontrolledSelect>
                      <button
                        type="button"
                        style={{
                          color: 'var(--error-text)',
                          marginTop: 0,
                          marginBottom: 0,
                        }}
                        onClick={() =>
                          handleDeletelistItem(
                            especialistItem,
                            index,
                            'expertise',
                          )
                        }
                      >
                        -
                      </button>
                    </SelectContainerEdit>
                  ))}
                </EspecialistContainerEdit>
              </div>
            </ProfessionalDetails>

            <SocialMedia>
              <InstagramIcon icon={faInstagram} />
              <div>
                <h3>@</h3>
                <ControllerInput
                  type="text"
                  name="socialMedia"
                  defaultValue={user.socialMedia ? user.socialMedia[0] : ['']}
                />
              </div>
            </SocialMedia>
            <MoreInfo>
              <div>
                <h3>Consulta Online?</h3>
                <Select
                  name="onlineAppt"
                  options={advancedOptions}
                  value={onlineAppt}
                  onChange={(e) => setOnlineAppt(e.target.value)}
                />
              </div>
              <div>
                <h3>Aceita plano de saúde?</h3>
                <Select
                  name="healthInsurance"
                  options={advancedOptions}
                  value={healthInsurance}
                  onChange={(e) => setHealthInsurance(e.target.value)}
                />
              </div>
            </MoreInfo>
          </ProfessionalInfoContainer>

          <ControllerTextArea
            label="Descrição"
            name="description"
            maxLength={250}
            defaultValue={user.description}
          />
        </ProfileHeader>

        <CloseModalButton type="button" onClick={CancelEditingProfile}>
          <CloseIcon icon={faX} />
        </CloseModalButton>
      </FormContainer>

      <FormContainer>
        <ProfileBody>
          <div>
            <h1>Quem Sou?</h1>

            <ControllerTextArea
              name="whoAmI"
              maxLength={500}
              defaultValue={user.whoAmI}
              height={10}
            />
          </div>
          <h1>Como me Encontrar?</h1>
          <ContactContainer>
            <Contact>
              <AddressContainer>
                <h2>Endereço</h2>
                <ControllerInput
                  type="text"
                  label="Rua/Avenida"
                  name="street_address"
                  defaultValue={user.street_address}
                />
                <ControllerInput
                  type="number"
                  label="Número"
                  name="street_number"
                  defaultValue={user.street_number}
                />
                <ControllerInput
                  type="text"
                  label="Complemento"
                  name="additional_address"
                  defaultValue={user.additional_address}
                />
                <ControllerMaskedInput
                  mask="99999-999"
                  type="text"
                  label="CEP"
                  name="postal_code"
                  defaultValue={user.postal_code}
                  alwaysShowMask
                />
              </AddressContainer>
              <div>
                <h2>Telefone</h2>
                <ControllerMaskedInput
                  mask="(99) 9999-9999"
                  type="text"
                  name="telephone (opcional)"
                  defaultValue={user.telephone}
                  alwaysShowMask
                />
              </div>
              <div>
                <h2>WhatsApp</h2>
                <ControllerMaskedInput
                  mask="(99) 9 9999-9999"
                  type="text"
                  name="whatsApp"
                  defaultValue={user.whatsApp}
                  alwaysShowMask
                />
              </div>
              <div>
                <h2>Email</h2>
                <ControllerInput
                  type="email"
                  name="email"
                  defaultValue={user.email}
                />
              </div>
            </Contact>
          </ContactContainer>
          <div>
            <h1>Experiência</h1>
            <ControllerTextArea
              name="experience"
              defaultValue={user.experience}
              height={10}
            />
          </div>
          <EspecialistContainerEdit>
            <div>
              <h1>Area de atuação</h1>
              <button
                type="button"
                onClick={() => handleNewlistItem(workAreaItem, 27, 'workArea')}
                style={{ marginTop: 0 }}
              >
                +
              </button>
            </div>
            {workAreaItem.map((item, index) => (
              <SelectContainerEdit key={index}>
                <UncontrolledSelect
                  value={item}
                  onChange={(e) =>
                    handleNewListItemValue(
                      workAreaItem,
                      e.target.value,
                      index,
                      'workArea',
                    )
                  }
                >
                  {experienceAreaOptions.map((areaItem, itemIndex) => (
                    <option value={areaItem} key={itemIndex}>
                      {areaItem}
                    </option>
                  ))}
                </UncontrolledSelect>
                <button
                  type="button"
                  style={{
                    color: 'var(--error-text)',
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                  onClick={() =>
                    handleDeletelistItem(workAreaItem, index, 'workArea')
                  }
                >
                  -
                </button>
              </SelectContainerEdit>
            ))}
          </EspecialistContainerEdit>
          <EspecialistContainerEdit>
            <div>
              <h1>Formações</h1>
              <button
                type="button"
                onClick={() =>
                  handleNewlistItem(graduationItem, 0, 'graduation')
                }
                style={{ marginTop: 0 }}
              >
                +
              </button>
            </div>
            {graduationItem?.map((item, index) => (
              <SelectContainerEdit key={index}>
                <UncontrollerInput
                  type="text"
                  onChange={(e) =>
                    handleNewListItemValue(
                      graduationItem,
                      e.target.value,
                      index,
                      'graduation',
                    )
                  }
                  value={item}
                />
                <button
                  type="button"
                  style={{
                    color: 'var(--error-text)',
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                  onClick={() =>
                    handleDeletelistItem(graduationItem, index, 'graduation')
                  }
                >
                  -
                </button>
              </SelectContainerEdit>
            ))}
          </EspecialistContainerEdit>
          <div>
            <h1>Como será nossa consulta?</h1>
            <ControllerTextArea
              name="medical_appt_procedure"
              defaultValue={user.medical_appt_procedure}
              height={20}
            />
          </div>
          <Button type="submit" ButtonColor="blue-500">
            Salvar
          </Button>
        </ProfileBody>
      </FormContainer>
      <ModelSuccessConfirmation
        title="Tudo certo!"
        description="Seu perfil foi atualizado."
        isOpen={openModal}
        onClick={SaveProfileChanges}
      />
    </Container>
  );
};
