import { useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useSignUpInfo } from '../../../../hooks/SignUpContext';
import { SignUpProps } from '../../../../types/hooksInterfaceTypes';
import getValidationErrors from '../../../../utils/validateErrors';

import { ControllerInput } from '../../../../components/controllerInput';
import { ControllerMaskedInput } from '../../../../components/controllerInput/controllerMaskedInput';
import Select from '../../../../components/select';
import { Button } from '../../../../components/button';

import { Container, Main, NumberInputsContainer } from './style';
import {
  experienceAreaOptions,
  HowMeetUsOptions,
} from '../../../../utils/selectOptions';

const FormSignUp: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { token } = useParams();
  const [firstStep, setFirstStep] = useState(true);

  const { SetSignUpData, SignUp } = useSignUpInfo();

  async function SignUpFormFirst(form: SignUpProps) {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string().required('Nome é obrigatório.'),
        lastName: Yup.string().required('Sobrenome é obrigatório.'),
        howAboutUs: Yup.string().required('Como nos conheceu é obrigatório.'),
        experience: Yup.string().required('Experiência é obrigatório.'),
      });
      await schema.validate(form, {
        abortEarly: false,
      });

      await SetSignUpData(form);
      formRef.current?.reset();
      setFirstStep(false);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const err = getValidationErrors(error);

        formRef.current?.setErrors(err);

        return;
      }
      console.log(error);
    }
  }

  async function SignUpFormSecond(form: SignUpProps) {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        email: Yup.string()
          .required('Email é obrigatório.')
          .email('Digite um Email válido.'),
        cpf: Yup.string().length(14, 'Informe um CPF válido'),
        crn: Yup.string().length(5, 'Informe um CPF válido.'),
        whatsApp: Yup.string().length(16, 'Informe um Whatsapp válido.'),
        password: Yup.string().min(6, 'No minimo 6 caracteres'),
        confirmPassword: Yup.string().oneOf(
          [Yup.ref('password'), null],
          'Confirmação inválida',
        ),
      });
      await schema.validate(form, {
        abortEarly: false,
      });

      await SignUp(form, token as string);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const err = getValidationErrors(error);

        formRef.current?.setErrors(err);

        return;
      }
      console.log(error);
    }
  }

  return (
    <Container>
      {firstStep === true ? (
        <Main ref={formRef} onSubmit={SignUpFormFirst}>
          <h1>Cadastrar</h1>
          <ControllerInput type="text" name="name" label="Nome" />

          <ControllerInput type="text" name="lastName" label="Sobrenome" />

          <Select
            label="Como nos Conheceu?"
            name="howAboutUs"
            options={HowMeetUsOptions}
          />

          <Select
            label="Especialidade"
            name="expertise"
            options={experienceAreaOptions}
          />

          <ControllerInput
            type="text"
            name="experience"
            label="Tempo de Experiência"
          />
          <Button type="submit" ButtonColor="blue">
            Continuar
          </Button>
        </Main>
      ) : (
        <Main ref={formRef} onSubmit={SignUpFormSecond}>
          <h1>Cadastrar</h1>
          <ControllerInput type="email" name="email" label="Email" />

          <NumberInputsContainer>
            <ControllerMaskedInput
              mask="999.999.999-99"
              type="text"
              name="cpf"
              label="CPF"
            />

            <ControllerMaskedInput
              mask="99999"
              type="text"
              name="crn"
              label="CRN"
            />
          </NumberInputsContainer>

          <ControllerMaskedInput
            mask="(99) 9 9999-9999"
            type="text"
            name="whatsApp"
            label="Whatsapp"
          />

          <ControllerInput type="password" name="password" label="Senha" />

          <ControllerInput
            type="password"
            name="confirmPassword"
            label="Confirmar Senha"
          />
          <Button type="submit" ButtonColor="blue">
            Criar
          </Button>
        </Main>
      )}
    </Container>
  );
};

export default FormSignUp;
