import Modal from 'react-modal';

import PrivacyTerm from '../../terms/PrivacyTerm';

import { Button } from '../button';

import { Container, Footer } from './style';

interface PlataformModalProps {
  isOpen: boolean;
  onClick: () => void;
}

const PlataformPrivicyTerms: React.FC<PlataformModalProps> = ({
  isOpen,
  onClick,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClick}
      closeTimeoutMS={200}
      className="react-modal-content"
      overlayClassName="react-modal-overlay"
    >
      <Container initial={{ scale: 0.9 }} animate={{ scale: 1 }}>
        <pre>{PrivacyTerm}</pre>
        <Footer>
          <Button type="button" ButtonColor="blue500" onClick={onClick}>
            Fechar
          </Button>
        </Footer>
      </Container>
    </Modal>
  );
};

export default PlataformPrivicyTerms;
