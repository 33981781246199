import { useCallback, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { faX } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import * as Yup from 'yup';

import { LoadingContext } from '../../hooks/LoadingContext';

import { ResetPasswordFormProps } from '../../types/hooksInterfaceTypes';
import getValidationErrors from '../../utils/validateErrors';

import { ControllerInput } from '../controllerInput';
import { Button } from '../button';
import ModelSuccessConfirmation from '../successModal';
import ErrorModal from '../errorModal';

import {
  Container,
  CloseModalButton,
  Main,
  Footer,
  CloseIcon,
  AnimationContainer,
} from './style';
import api from '../../services/api';

interface ResetPasswordModalProps {
  openModal: boolean;
  onCloseModal: () => void;
}

const ResetPasswordFormModal: React.FC<ResetPasswordModalProps> = ({
  openModal,
  onCloseModal,
}) => {
  const formRef = useRef<FormHandles>(null);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const { SetIsLoading } = LoadingContext();

  const handleChangeUserPassword = useCallback(
    async (resetPasswordForm: ResetPasswordFormProps) => {
      try {
        SetIsLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          currentPassword: Yup.string().min(6, 'No minimo 6 caracteres'),
          newPassword: Yup.string().min(6, 'No minimo 6 caracteres'),
          confirmationPassword: Yup.string().oneOf(
            [Yup.ref('newPassword'), null],
            'Confirmação inválida',
          ),
        });
        await schema.validate(resetPasswordForm, {
          abortEarly: false,
        });

        setIsSuccessModalOpen(true);
        SetIsLoading(false);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const err = getValidationErrors(error);

          formRef.current?.setErrors(err);
          SetIsLoading(false);
          return;
        }
        setIsErrorModalOpen(true);
      }
    },
    [SetIsLoading],
  );

  function handleCloseResetPasswordModal() {
    setIsSuccessModalOpen(false);
    onCloseModal();
  }

  return (
    <Modal
      isOpen={openModal}
      onRequestClose={handleCloseResetPasswordModal}
      closeTimeoutMS={200}
      className="react-modal-content"
      overlayClassName="react-modal-overlay"
    >
      <AnimationContainer initial={{ scale: 0.9 }} animate={{ scale: 1 }}>
        <Container ref={formRef} onSubmit={handleChangeUserPassword}>
          <CloseModalButton
            type="button"
            onClick={handleCloseResetPasswordModal}
          >
            <CloseIcon icon={faX} />
          </CloseModalButton>
          <Main>
            <h1>Redefinir Senha</h1>
            <ControllerInput
              type="password"
              name="currentPassword"
              label="Senha Atual"
            />
            <ControllerInput
              type="password"
              name="newPassword"
              label="Nova Senha"
            />

            <ControllerInput
              type="password"
              name="confirmationPassword"
              label="Confirmar Senha"
            />
          </Main>
          <Footer>
            <Button type="submit" ButtonColor="blue500">
              Redefinir
            </Button>
          </Footer>
        </Container>
      </AnimationContainer>

      <ModelSuccessConfirmation
        title="Senha Redefinida!"
        description="Seu senha foi alterada com sucesso."
        isOpen={isSuccessModalOpen}
        onClick={handleCloseResetPasswordModal}
      />

      <ErrorModal
        message="Verifique os dados e tente novamente. Caso o erro persista, entre em contato com o nosso suporte."
        isModalOpen={isErrorModalOpen}
        functionProp={() => setIsErrorModalOpen(false)}
      />
    </Modal>
  );
};

export default ResetPasswordFormModal;
