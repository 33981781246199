import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useMemo,
} from 'react';

import { SetLoadingContextProvider } from '../types/hooksInterfaceTypes';

const LoadingCreateContext = createContext<SetLoadingContextProvider>(
  {} as SetLoadingContextProvider,
);

export const LoadingContextProvider: React.FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const SetIsLoading = useCallback((isSomethingLoading: boolean) => {
    setIsLoading(isSomethingLoading);
  }, []);

  const providerHooks = useMemo(
    () => ({
      isLoading,
      SetIsLoading,
    }),
    [isLoading, SetIsLoading],
  );

  return (
    <LoadingCreateContext.Provider value={providerHooks}>
      {children}
    </LoadingCreateContext.Provider>
  );
};

export function LoadingContext(): SetLoadingContextProvider {
  const context = useContext(LoadingCreateContext);

  if (!context) {
    console.log('Provider mus be used in a ContextProvider');
  }

  return context;
}
