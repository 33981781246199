import { useState } from 'react';
import Modal from 'react-modal';

import ProfessionalTerms from '../../terms/ProfessionalTerms';
import UserTerms from '../../terms/UserTerms';

import { Button } from '../button';

import { Container, Footer } from './style';

interface PlataformModalProps {
  isOpen: boolean;
  onClick: () => void;
}

const PlataformModalTerms: React.FC<PlataformModalProps> = ({
  isOpen,
  onClick,
}) => {
  const [terms, setTerms] = useState(true);
  const [termsTitle, setTermsTitle] = useState(true);

  function HandleChangeTerms() {
    setTerms(!terms);
    setTermsTitle(!terms);
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClick}
      closeTimeoutMS={200}
      className="react-modal-content"
      overlayClassName="react-modal-overlay"
    >
      <Container initial={{ scale: 0.9 }} animate={{ scale: 1 }}>
        {terms ? <pre>{ProfessionalTerms}</pre> : <pre>{UserTerms}</pre>}
        <Footer>
          <Button
            type="button"
            ButtonColor="orange"
            onClick={HandleChangeTerms}
          >
            {termsTitle ? 'Usuário' : 'Profissional'}
          </Button>
          <Button type="button" ButtonColor="blue500" onClick={onClick}>
            Fechar
          </Button>
        </Footer>
      </Container>
    </Modal>
  );
};

export default PlataformModalTerms;
