import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import BackgroundImage from '../../assets/images/admin-background.jpg';

export const Container = styled.main`
  height: 100svh;
  width: 100vw;

  .admin-login {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    background: url(${BackgroundImage}) no-repeat center;
    background-size: cover;

    h2 {
      color: var(--text-title);
      margin-bottom: -1rem;
    }

    a {
      position: absolute;

      top: 5%;
      left: 2%;
    }

    img {
      height: 6rem;
      object-fit: contain;
      margin-bottom: 1.5rem;
    }

    button {
      height: 2.7rem;
      font-size: 1rem;
    }
  }
`;

export const ArrowIcon = styled(FontAwesomeIcon)`
  width: 1.5rem;
  height: 1.5rem;
  color: var(--orange_500);

  transition: filter 0.2s;
  &:hover {
    filter: brightness(0.8);
  }
`;
