import { useEffect, useState } from 'react';

import api from '../../../services/api';

import { Container } from './style';

interface nutrisData {
  id: number;
  name: string;
  lastName: string;
  crn: string;
  city: string;
}

const Dashboard: React.FC = () => {
  const [nutritionists, setNutritionists] = useState<nutrisData[]>([]);

  useEffect(() => {
    api
      .get('/nutritionists')
      .then((response) => {
        setNutritionists(response.data.nutritionists);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Container>
      <thead>
        <tr>
          <th className="name-colum">Nutricionista</th>
          <th>CRN</th>
          <th>Cidade</th>
          <th>Perfil Completo</th>
        </tr>
      </thead>
      <tbody>
        {nutritionists.map((item) => (
          <tr key={item.id}>
            <td
              className="name-colum"
              key={item.name}
            >{`${item.name} ${item.lastName}`}</td>
            <td key={item.crn}>{item.crn}</td>
            <td key={item.city}>{item.city}</td>
            <td key={item.lastName}>Sim</td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td className="name-colum">
            Total de Nutris: {nutritionists.length}
          </td>
          <td className="name-colum" />
          <td className="name-colum">Página 1-1 ⬅️➡️</td>
        </tr>
      </tfoot>
    </Container>
  );
};

export default Dashboard;
