import styled, { css } from 'styled-components';

interface ButtonCssProps {
  ButtonColor: 'orange' | 'blue';
}

export const Container = styled.header`
  background: var(--shape);
  width: 100%;
  height: 4.5rem;

  @media (max-width: 800px) {
    display: flex;
    align-items: center;
    height: 7rem;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1420px;
  margin: 0 auto;
  padding: 0.625rem 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    height: 3.375rem;
    object-fit: contain;
  }

  @media (max-width: 800px) {
    display: grid;
    grid-template-areas:
      'profile logo'
      'none buttons';

    img {
      grid-area: 'logo';

      height: 2.575rem;
      margin-bottom: 1rem;
    }

    a {
      display: flex;
      justify-self: flex-end;
    }
  }
`;

export const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    width: 3.125rem;
    height: 3.125rem;
  }

  img {
    width: 3.125rem;
    height: 3.125rem;
    border: solid 0.25rem var(--blue_300);
    border-radius: 50%;
    object-fit: cover;
  }

  div {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin-left: 0.625rem;
  }

  h3 {
    font-size: 0.75rem;
    color: var(--blue_300);
  }

  h2 {
    font-size: 0.875rem;
    color: var(--orange_500);
  }

  @media (max-width: 800px) {
    grid-area: 'profile';
    justify-content: flex-start;
  }
`;

export const NoneContainer = styled.div`
  display: none;
  @media (max-width: 800px) {
    display: block;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  a {
    & + a {
      margin-left: 0.75rem;
    }
  }

  @media (max-width: 800px) {
    grid-area: 'buttons';
    margin-left: auto;
  }
`;

export const HeaderButton = styled.button<ButtonCssProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5.813rem;
  height: 1.875rem;
  color: var(--white-text);
  font-size: 0.8rem;
  border: none;
  border-radius: 0.5rem;

  background-color: ${(props) =>
    props.ButtonColor === 'orange'
      ? css`var(--orange_500)`
      : css`var(--blue_300)`};
`;
