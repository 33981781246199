import styled from 'styled-components';
import { Form } from '@unform/web';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
  button {
    margin-left: auto;
    margin-top: 1.188rem;
    font-size: 1rem;
  }

  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  @media (min-width: 800px) {
    flex-wrap: nowrap;
  }

  @media (max-width: 800px) {
    justify-content: center;
  }
`;

export const PaymentContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const Main = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 50%;
  padding-right: 10%;

  border-right: 1px solid var(--card-border);

  h1 {
    font-size: 2rem;
    font-weight: 700;
    color: var(--orange_500);
    margin-bottom: 2rem;
  }

  input {
    background-color: var(--card-border);
    color: var(--text-body);
  }

  @media (max-width: 800px) {
    border-bottom: 1px solid var(--card-border);
    border-right: none;
    padding-right: 0;
    margin-top: -2.5rem;
    width: 100%;
    height: 22.25rem;

    align-items: center;
  }
`;

export const NumberInputsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 1.5rem;

  div {
    margin: 0;

    width: 70%;
  }
`;

export const PaymentInfo = styled.div`
  padding-left: 10%;
  width: 50%;

  h2 {
    font-weight: 700;
    font-size: 1.25rem;
    color: var(--text-title);
  }

  p {
    margin-top: 1rem;
    font-size: 1rem;
    line-height: 1.3rem;
    color: var(--text-body);

    strong {
      font-weight: 700;
    }

    span {
      font-weight: 700;
      color: var(--orange_500);
    }
  }

  @media (max-width: 800px) {
    padding-left: 0;
    width: 100%;
    height: 10.25rem;
    margin-top: 2rem;

    overflow-y: scroll;
  }
`;

export const Footer = styled.div`
  width: 100%;
  margin-top: -3rem;
  display: flex;
  align-items: baseline;
  justify-content: flex-end;

  border-bottom: 1px solid var(--card-border);

  img {
    width: 8.75rem;
    height: 8.75rem;
    object-fit: contain;
    margin-right: 0.625rem;
  }

  @media (max-width: 800px) {
    margin-top: -1rem;

    img {
      width: 5.75rem;
      height: 5.75rem;
    }
  }
`;
