import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
  pre {
    font-size: 1rem;
    line-height: 1.2rem;
    color: var(--text-body);
    overflow-y: scroll;
    white-space: pre-wrap;
    height: 30.625rem;
  }

  button {
    margin-left: auto;
    margin-top: 1.188rem;
    width: 13rem;
    font-size: 1rem;
  }
`;

export const Footer = styled.footer`
  border-top: 1px solid var(--card-border);
  margin-top: 1.5rem;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const Terms = styled.div`
  display: flex;
  align-items: center;

  h3 {
    font-weight: 600;
    font-size: 0.875rem;
    margin-left: 0.5rem;
    color: var(--text-body);
  }

  span {
    font-weight: 700;
    font-size: 1rem;
    color: var(--orange_500);
  }

  div {
    input {
      height: 1.35rem;
      max-width: 1.35rem;
    }
  }

  @media (max-width: 800px) {
    margin-top: 1.5rem;
  }
`;
