import styled, { css } from 'styled-components';

interface InputContainerProps {
  haveError?: boolean;
}

export const Container = styled.div<InputContainerProps>`
  width: 100%;
  position: relative;

  & + div {
    margin-top: 1.5rem;
  }

  input {
    display: flex;
    align-items: center;
    width: 100%;
    height: 2.2rem;
    border: none;
    border-radius: 0.5rem;
    padding: 0.5rem;
    margin-top: 0.3rem;

    font-size: 1.1rem;
    font-weight: 500;
    color: var(--text-title);

    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type='number'] {
      -moz-appearance: textfield;
    }

    ${(props) =>
      props.haveError &&
      css`
        border: 2px solid var(--error-text);
      `}
  }
  span {
    position: absolute;
    color: var(--error-text);
    font-weight: 500;
    bottom: -1.5rem;
    right: 0;
  }
`;

export const LabelContainer = styled.label`
  font-weight: 600;
  font-size: 1.1rem;
  color: var(--text-body);
`;
