import axios from 'axios';

interface IBGEuFData {
  sigla: string;
}

interface IBGECityData {
  nome: string;
}

export const HandleGetState = async () => {
  const response = await axios.get<IBGEuFData[]>(
    'https://servicodados.ibge.gov.br/api/v1/localidades/estados',
  );

  const ufInitial = response.data.map((item) => item.sigla);

  return ufInitial;
};

export const HandleGetCity = async (selectedUf: string) => {
  const response = await axios.get<IBGECityData[]>(
    `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedUf}/municipios`,
  );

  const citiesName = response.data.map((item) => item.nome);

  return citiesName;
};
