import styled, { keyframes } from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--yellow_500);
  width: 100%;
  max-width: 30.188rem;
`;

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Main = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2.25rem 4.5rem;

  animation: ${appearFromRight} 1s;

  button {
    margin-top: 3rem;
    height: 3.938rem;
    width: 100%;
    max-width: inherit;
  }

  h1 {
    font-weight: bold;
    font-size: 2rem;
    color: var(--white-text);
    margin-bottom: 1rem;
    align-self: flex-start;
  }

  select {
    margin-bottom: 1.5rem;
    width: 100%;
  }

  @media (max-width: 800px) {
    align-items: center;
    justify-content: flex-start;
    margin-left: 0;

    h1 {
      font-size: 1.8rem;
      align-self: center;
    }
  }
`;

export const NumberInputsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 0.5rem;
  margin-bottom: 1.5rem;

  div {
    margin: 0;
  }
`;
