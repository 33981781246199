import { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import { SelectInput, LabelContainer, Container } from './style';

interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  label?: string;
  options?: Array<string>;
}

const Select: React.FC<SelectProps> = ({
  name,
  label,
  options,
  children,
  ...rest
}) => {
  const SelectRef = useRef<HTMLSelectElement>(null);

  const { defaultValue, fieldName, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: SelectRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      <LabelContainer>{label}</LabelContainer>
      <SelectInput ref={SelectRef} {...rest}>
        {options?.map((value) => (
          <option key={Math.random() + value} defaultValue={defaultValue}>
            {value}
          </option>
        ))}
        {children}
      </SelectInput>
    </Container>
  );
};

export default Select;
