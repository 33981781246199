import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import CompleteProfileModal from './completeProfileModal';
import Logo from '../../assets/health_to_go_logo.png';
import { useAuth } from '../../hooks/AuthContext';

import {
  Container,
  Content,
  ProfileContainer,
  NoneContainer,
  ButtonsContainer,
  HeaderButton,
} from './style';

export const Header: React.FC = () => {
  const { user, SignOut } = useAuth();
  const [userLoged, setUserLoged] = useState(false);
  const [salute, setSalute] = useState('');

  useEffect(() => {
    user !== undefined && setUserLoged(true);
    const hour = new Date().toLocaleTimeString().slice(0, 2);

    let saluteMessage = '';
    if (Number(hour) >= 6) {
      saluteMessage = 'Bom dia';
    }
    if (Number(hour) >= 12) {
      saluteMessage = 'Boa tarde';
    }
    if (Number(hour) >= 18 || (Number(hour) >= 0 && Number(hour) < 6)) {
      saluteMessage = 'Boa noite';
    }

    return setSalute(saluteMessage);
  }, [user]);

  const handleUserSignOut = useCallback(() => {
    setUserLoged(false);
    SignOut();
  }, [SignOut]);

  return (
    <Container>
      <Content>
        {user ? (
          <ProfileContainer>
            <Link to={`/profile/${user.nutritionist_id}`}>
              <img src={user.image} alt="Profile Avatar" />
            </Link>

            <div>
              <h3>{salute},</h3>
              <h2>
                {user.name} {user.lastName}
              </h2>
            </div>
          </ProfileContainer>
        ) : (
          <ProfileContainer>
            <div>
              <h3>{salute}!</h3>
              <h2>Seja muito Bem-Vindo.</h2>
            </div>
          </ProfileContainer>
        )}

        <Link to="/">
          <img src={Logo} alt="Health to Go" />
        </Link>
        <NoneContainer />
        <ButtonsContainer>
          <Link to="/partners">
            <HeaderButton type="button" ButtonColor="blue">
              Parceiros
            </HeaderButton>
          </Link>

          {userLoged === true ? (
            <>
              <Link to="/">
                <HeaderButton
                  type="button"
                  ButtonColor="orange"
                  onClick={handleUserSignOut}
                >
                  Sair
                </HeaderButton>
              </Link>
              <CompleteProfileModal
                id={user.nutritionist_id}
                isOpen={!user.completedProfile}
              />
            </>
          ) : (
            <Link to="/signin">
              <HeaderButton type="button" ButtonColor="orange">
                Entrar
              </HeaderButton>
            </Link>
          )}
        </ButtonsContainer>
      </Content>
    </Container>
  );
};
