import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  max-width: 31.328rem;
  min-height: 25.188rem;
  background-color: var(--shape);
  border-radius: 0.5rem;
  padding: 1.5rem;

  & + div {
    margin-left: 5rem;
  }

  @media (max-width: 800px) {
    margin: 1.5rem 0 0 0;

    & + div {
      margin-left: 0;
      margin-top: 3rem;
    }
  }
`;
