/* eslint-disable array-callback-return */
import { useEffect, useMemo, useState } from 'react';

import api from '../../../services/api';

import { Container } from './style';

interface MetricsData {
  name: string;
  lastName: string;
  button_name: string;
  count: number;
}

interface OrganizedListData {
  name: string;
  metricsData: MetricsData[];
}

const NutritionistsClicks: React.FC = () => {
  const [metrics, setMetrics] = useState<OrganizedListData[]>([]);

  useEffect(() => {
    api
      .get('/admin/metrics')
      .then((response) => {
        const metricsDataResponse = response.data
          .nutritionists as MetricsData[];
        const memory = {
          temp: [],
          result: [],
        };

        metricsDataResponse.map((item) => {
          const userName = `${item.name} ${item.lastName}`;

          if (!memory.temp.includes(userName as never)) {
            memory.temp.push(userName as never);
            const metricsData = metricsDataResponse.filter(
              (item2) => item2.name === item.name,
            );
            memory.result.push({
              name: userName,
              metricsData,
            } as never);
          }
        });

        setMetrics(memory.result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Container>
      <thead>
        <tr>
          <th className="name-colum">Nutricionista</th>
          <th>Perfil</th>
          <th>Whatsapp</th>
          <th>Instagram</th>
        </tr>
      </thead>
      <tbody>
        {metrics?.map((item) => (
          <tr key={item.name}>
            <td className="name-colum">{item.name}</td>
            <td key={item.metricsData[1]?.button_name}>
              {item?.metricsData[1] ? item.metricsData[1].count : 0}
            </td>
            <td key={item.metricsData[2]?.button_name}>
              {item?.metricsData[2] ? item.metricsData[2].count : 0}
            </td>
            <td key={item.metricsData[0]?.button_name}>
              {item?.metricsData[0] ? item.metricsData[0].count : 0}
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td className="name-colum">Total de Nutris: {metrics.length}</td>
          <td className="name-colum" />
          <td className="name-colum">Página 1-1</td>
        </tr>
      </tfoot>
    </Container>
  );
};

export default NutritionistsClicks;
