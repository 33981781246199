import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2.688rem;
  flex-direction: column;

  @media (max-width: 800px) {
    margin: 1.5rem;
  }
`;

export const Header = styled.div`
  width: 100%;
  max-width: 69.188rem;
  min-height: 25.188rem;
  background-color: var(--shape);
  border-radius: 0.5rem;
  padding: 1.5rem;

  .avatar {
    width: 15rem;
    height: 15rem;
    display: block;
    margin: 0 auto;
  }

  div {
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    grid-column-gap: 1rem;
  }

  @media (max-width: 800px) {
    margin: 1.5rem 0 0 0;

    .avatar {
      width: 8rem;
      height: 8rem;
    }
  }
`;

export const Body = styled.div`
  width: 100%;
  max-width: 69.188rem;
  min-height: 25.188rem;
  background-color: var(--shape);
  border-radius: 0.5rem;
  padding: 1.5rem;

  margin-top: 2.688rem;

  @media (max-width: 800px) {
    margin-top: 1.5rem;
  }
`;
