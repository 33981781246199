import { useEffect } from 'react';
import Modal from 'react-modal';
import TagManager from 'react-gtm-module';
import { ContextProvider } from './hooks';
import Router from './routes';

import { GlobalStyle } from './styles/global';

Modal.setAppElement('#root');

const tagKey =
  process.env.REACT_APP_ENV === 'prod'
    ? process.env.REACT_APP_GOOGLE_TAG_ID
    : process.env.REACT_APP_GOOGLE_TAG_ID_TESTE;

function App() {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: tagKey as string,
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <>
      <ContextProvider>
        <Router />
      </ContextProvider>
      <GlobalStyle />
    </>
  );
}

export default App;
