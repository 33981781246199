import { MapContainer } from './style';

interface MapProps {
  address: string;
}

const Map: React.FC<MapProps> = ({ address }) => {
  return (
    <MapContainer>
      <img
        src={`https://maps.googleapis.com/maps/api/staticmap?center=${address}&zoom=15&size=600x400&markers=color:red|${address}&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`}
        alt="locale"
      />
    </MapContainer>
  );
};

export default Map;
