import styled from 'styled-components';
import { Form } from '@unform/web';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Container = styled(Form)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 2.688rem;

  @media (max-width: 800px) {
    margin: 1.5rem;
  }
`;

export const FormContainer = styled.div`
  position: relative;
  display: flex;

  background-color: var(--shape);
  width: 100%;
  max-width: 69.188rem;
  padding: 1.5rem 0;
  border-radius: 0.5rem;

  margin-bottom: 2.688rem;

  input {
    background-color: var(--card-border);
  }

  label {
    color: var(--orange_500);
    font-size: 1rem;
  }

  select {
    font-size: 1.02rem;

    color: var(--text-title);

    background-color: var(--card-border);

    option {
      background: var(--card-border);
      color: var(--text-title);
    }
  }

  @media (max-width: 800px) {
    margin-bottom: 1.5rem;
  }
`;

export const ProfileHeader = styled.header`
  padding: 1.5rem 3.938rem;
  width: 100%;

  @media (max-width: 800px) {
    padding: 2% 5%;
  }
`;

export const CloseModalButton = styled.button`
  position: absolute;
  right: 1.5%;
  top: 2%;

  width: 2rem;
  height: 2rem;

  display: flex;
  align-items: center;
  justify-content: center;

  background: none;
  border: none;
`;

export const CloseIcon = styled(FontAwesomeIcon)`
  width: 1rem;
  height: 1rem;

  color: var(--text-title);
`;

export const ProfessionalInfoContainer = styled.div`
  display: grid;
  grid-template-areas:
    'profile profile social'
    'profile profile info';
  margin-bottom: 1.5rem;

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
  }
`;

export const ProfessionalDetails = styled.div`
  grid-area: profile;
  display: flex;
  padding-right: 1.5rem;

  align-items: flex-start;

  div {
    h1 {
      font-weight: 700;
      font-size: 1.75rem;
      line-height: 1.563rem;

      color: var(--blue_500);
    }

    h2,
    h3 {
      font-size: 1.25rem;
      line-height: 1.625rem;

      color: var(--orange_500);
    }
  }

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 0;

    div {
      h1 {
        font-size: 1.25rem;
        line-height: 1.263rem;
      }

      h2,
      h3 {
        font-size: 0.95rem;
        line-height: 1.225rem;
      }
    }
  }
`;

export const AvatarContainer = styled.div`
  position: relative;
  margin-right: 1.5rem;

  img {
    width: 11rem;
    height: 11rem;
    border: solid 0.375rem var(--blue_500);
    border-radius: 50%;
    object-fit: cover;
  }

  label {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: var(--blue_500);
    right: 1rem;
    bottom: 0;
    border: 0;
    transition: filter 0.3s;
    cursor: pointer;

    input {
      display: none;
    }

    &:hover {
      filter: brightness(0.8);
    }
  }
  @media (max-width: 800px) {
    margin-right: 0;

    label {
      right: 1rem;
      width: 30px;
      height: 30px;
    }
    img {
      width: 8rem;
      height: 8rem;
    }
  }
`;

export const FiCamera = styled(FontAwesomeIcon)`
  width: 20px;
  height: 20px;
  color: var(--white-text);

  @media (max-width: 800px) {
    width: 1rem;
    height: 1rem;
  }
`;

export const EspecialistContainerEdit = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1.5rem;

  div {
    display: flex;
    align-items: center;
    width: 100%;

    h3 {
      align-self: center;
    }
  }

  & + div {
    margin-top: 1rem;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5rem;
    max-width: 0.9rem;

    color: var(--blue_300);
    background: none;
    border: none;
    font-size: 1.5rem;
  }
`;

export const SelectContainerEdit = styled.div`
  display: flex;
  align-items: center;

  & + div {
    margin-top: 1rem;
  }
`;

export const LocaleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 1.5rem;

  div + div {
    margin-top: 0;
  }

  select {
    option {
      height: 4rem;
    }
  }
`;

export const SocialMedia = styled.div`
  grid-area: social;
  display: flex;
  flex-direction: column;
  margin-top: 1.25rem;
  align-items: flex-end;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  h3 {
    color: var(--text-title);
    margin-right: 0.5rem;
  }

  input {
    width: 100%;
    max-width: 15rem;
  }

  @media (max-width: 800px) {
    flex-direction: row;
    align-items: center;
  }
`;

export const InstagramIcon = styled(FontAwesomeIcon)`
  width: 1.875rem;
  height: 1.875rem;
  margin-bottom: 1rem;

  color: var(--text-title);

  @media (max-width: 800px) {
    width: 1rem;
    height: 1rem;
    margin: 0 1rem 0 0;
  }
`;

export const MoreInfo = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  grid-area: info;

  div {
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;

    & + div {
      margin-top: 1rem;
    }

    h3 {
      font-weight: 500;
      font-size: 1rem;
      color: var(--blue_500);
      text-align: end;
    }

    label {
      height: 0;
    }
    select {
      width: 4.5rem;
      margin-left: 0.5rem;
    }
  }

  @media (max-width: 800px) {
    align-items: flex-start;
    margin-top: 1rem;
    div {
      display: flex;
      justify-content: left;
      width: 100%;

      h3 {
        text-align: start;
        width: 100%;
        max-width: 12.5rem;
        min-width: 12.5rem;
      }

      select {
        margin-left: 0;
      }
    }
  }
`;

export const ProfileBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2.563rem 3.938rem;
  width: 100%;

  h1 {
    margin: 1.5rem 0;
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 1.563rem;
    color: var(--blue_500);
  }

  button {
    align-self: flex-end;
    width: 13rem;
    height: 3.5rem;
    font-size: 1.2rem;
    margin-top: 4rem;
  }

  @media (max-width: 800px) {
    padding: 0 1.5rem;

    h1 {
      font-size: 1.35rem;
      line-height: 1.363rem;
    }

    textarea {
      font-size: 1rem;
      line-height: 1.3rem;
    }

    button {
      align-self: center;
      margin-top: 3rem;
    }
  }
`;

export const ContactContainer = styled.div`
  display: flex;

  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;

export const Contact = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  div {
    width: 100%;

    border: none;

    h2 {
      margin-top: 0.5rem;
      font-weight: 700;
      font-size: 1.125rem;
      line-height: 1.625rem;
      margin-left: 1%;
      color: var(--text-title);
    }

    input {
      margin-top: 0;
      margin-left: 3%;
      width: 60%;
    }

    @media (max-width: 800px) {
      h2 + div {
        margin-top: 0;
      }
      input {
        margin-left: 0%;
        width: 100%;
      }
    }
  }
`;

export const AddressContainer = styled.div`
  label {
    margin-left: 3%;
  }
  div {
    margin-bottom: 1rem;
  }
  @media (max-width: 800px) {
    label {
      margin-left: 1%;
    }
  }
`;
