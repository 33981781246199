import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Container = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 2.688rem 2.688rem 4rem;
  min-height: 20rem;

  button {
    width: 17.813rem;
    height: 3.438rem;

    font-size: 1.125rem;
  }
  @media (max-width: 800px) {
    margin: 1.5rem;
  }
`;

export const CardContainer = styled.li`
  display: flex;
  flex-direction: column;
  list-style: none;
  background-color: var(--shape);
  width: 100%;
  max-width: 69.188rem;
  height: 100%;
  min-height: 27.188rem;
  border-radius: 0.5rem;

  margin-bottom: 4rem;

  .profileButton {
    background: none;
    border: none;
    width: auto;
    height: auto;
  }

  @media (max-width: 800px) {
    margin-bottom: 1.5rem;

    p {
      font-size: 1rem;
      line-height: 1.3rem;
    }
  }
`;

export const Main = styled.main`
  padding: 1.5rem 3.938rem;

  p {
    margin-top: 1.688rem;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.625rem;

    color: var(--text-body);
  }

  @media (max-width: 800px) {
    padding: 5% 5%;
  }
`;

export const ProfessionalInfoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const ProfessionalDetails = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 9.563rem;
    height: 9.563rem;
    border: solid 0.375rem var(--blue_500);
    border-radius: 50%;
    object-fit: cover;
  }

  div {
    margin-left: 2rem;

    h1 {
      font-weight: 700;
      font-size: 1.75rem;
      line-height: 1.563rem;
      text-align: start;

      color: var(--blue_500);
    }

    h2,
    h3,
    a {
      font-size: 1.25rem;
      line-height: 1.625rem;
      margin-top: 0.5rem;

      color: var(--orange_500);
    }

    h3,
    a {
      font-weight: 700;
      color: var(--text-title);

      span {
        color: var(--blue_500);
      }
    }
  }

  @media (max-width: 800px) {
    img {
      width: 8rem;
      height: 8rem;
    }

    div {
      margin-left: 1rem;
      h1 {
        font-size: 1.25rem;
        line-height: 1.263rem;
      }

      h2,
      h3 {
        font-size: 0.95rem;
        line-height: 1.225rem;
        margin-top: 0.5rem;
      }

      h3 {
        font-weight: 700;
        color: var(--text-title);

        span {
          color: var(--blue_500);
        }
      }
    }
  }
`;

export const SocialMedia = styled.div`
  margin-top: 1.25rem;

  a {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: end;
  }

  h3 {
    font-size: 1rem;
    margin-top: 0.5rem;
    color: var(--text-title);
  }
  @media (max-width: 800px) {
    a {
      flex-direction: row;
      justify-content: center;
    }
    h3 {
      margin: 0 0 0 0.2rem;
    }
  }
`;

export const InstagramIcon = styled(FontAwesomeIcon)`
  width: 1.875rem;
  height: 1.875rem;
  color: var(--text-title);
  @media (max-width: 800px) {
    width: 1rem;
    height: 1rem;
  }
`;

export const CardFooter = styled.footer`
  background-color: var(--card-footer-background);
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  padding: 1.5rem 3.938rem;

  display: grid;
  grid-template-columns: 2fr 1fr;
  margin-top: auto;

  div {
    display: flex;
    align-items: center;

    & + div {
      grid-column: 1;
    }

    h3 {
      font-weight: 400;
      font-size: 0.875rem;
      color: var(--text-body);
    }
    span {
      font-weight: 700;
      font-size: 1.25rem;

      color: var(--orange_500);

      margin-left: 0.5rem;
    }
  }

  button {
    max-width: 19.5rem;
    height: 2.625rem;
  }

  @media (max-width: 800px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 1.5rem 1.5rem;

    div {
      width: 100%;
      span {
        margin-left: auto;
      }
    }
    button {
      margin-top: 1.5rem;
    }
  }
`;
