import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  :root {
    --yellow_500: #FFB53A;
    --orange_500: #F47745;

    --blue_500: #00A5E4;
    --blue_300: #85D7F7;

    --text-title: #828282;
    --text-body: #555555;
    --white-text: #fefefe;
    --error-text: #c53050;

    --background: #efefef;
    --scroll-background: #a9a9a9;
    --card-footer-background: #fafafc;
    --card-border: #d3d3d3;
    --shape: #ffffff;
  }
  html {
    @media (max-width: 1080px) {
      font-size: 93.75%;
    }
    @media (max-width: 720px) {
      font-size: 87.5%
    }
  }
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;

    a {
      text-decoration: none;
    }

    scrollbar-color: var(--scroll-background);
    scrollbar-width: thin;
    scroll-behavior: smooth;

    ::-webkit-scrollbar {
      width: 0.5rem;
    }
    ::-webkit-scrollbar-track{
      background: none;
    }
    ::-webkit-scrollbar-thumb{
      background-color: var(--scroll-background);
      border-radius: 1.2rem;
    }
  }

  body {
    background: var(--background);
    -webkit-font-smoothing: antialiased;
  }
  body, input, textarea, label, span, select, button, pre {
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
  }
  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }
  button, a {
    cursor: pointer;
    font-weight: 600;

    transition: filter 0.3s;

    &:hover {
      filter: brightness(0.8)
    };
}

.react-modal-overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    z-index: 2;
  }
  .ReactModal__Overlay--after-open{
    opacity: 1;
  }

  .ReactModal__Overlay--before-close{
    opacity: 0;
  }

  .react-modal-content {
    max-width: 800px;
    background: var(--shape);
    padding: 2rem 2rem 1.188rem;
    position: relative;
    border-radius: 0.25rem;

     display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    overflow-y: scroll;

    scale: 0.9;
    transition: scale 200ms;

    @media (min-width: 800px) {
      flex-wrap: nowrap;
    }

      @media (max-width: 800px) {
      justify-content: center;
      align-items: center;
      max-height: 90vh;
      max-width: 90vw;

      button {
        max-width: 16.438rem;
        margin-right: auto;
        height: 2.938rem;
        font-size: 1.2rem;

      }
    }
  }

.ReactModal__Content--after-open{
    scale: 1;
  }

  .ReactModal__Content--before-close{
    scale: 0.9;
  }`;
