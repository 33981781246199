import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
  pre {
    font-size: 1rem;
    line-height: 1.2rem;
    color: var(--text-body);
    overflow-y: scroll;
    white-space: pre-wrap;
    height: 28.625rem;
  }

  button {
    margin-top: 1.188rem;
    width: 8rem;
    font-size: 1rem;

    & + button {
      margin-left: 1.188rem;
    }
  }
`;

export const Footer = styled.footer`
  border-top: 1px solid var(--card-border);
  margin-top: 1.5rem;
  width: 100%;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 800px) {
    justify-content: space-between;
    button {
      margin: 1.5rem 0 0 0;
    }
  }
`;
