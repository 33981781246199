import styled, { css } from 'styled-components';

interface ButtonCssProps {
  Colors: string;
}

interface LoadingProps {
  loadingcolor: 'orange' | 'white' | undefined;
}

export const Container = styled.button<ButtonCssProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 15.438rem;

  height: 3.2rem;
  color: var(--white-text);
  border: none;
  border-radius: 0.5rem;

  font-size: 1.2rem;
  font-weight: bold;

  background-color: ${(props) => {
    switch (props.Colors) {
      case 'yellow':
        return css`var(--yellow_500)`;
      case 'blue':
        return css`var(--blue_300)`;
      case 'blue500':
        return css`var(--blue_500)`;
      case 'orange':
        return css`var(--orange_500)`;

      default:
        return css`var(--blue_500)`;
    }
  }};

  :disabled {
    background-color: var(--card-border);
  }
`;

export const LoadingContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
`;

export const Ball = styled.div<LoadingProps>`
  position: absolute;
  top: 36px;
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 50%;
  background: ${(props) =>
    props.loadingcolor === 'orange' ? 'var(--orange_500)' : '#fff'};
  animation-timing-function: cubic-bezier(0, 1, 1, 0);

  :nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }

  :nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  :nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  :nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
`;
