import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from '@unform/web';

export const Container = styled.div`
  background-image: linear-gradient(
    45deg,
    var(--shape) 0%,
    var(--background) 100%
  );

  height: 100svh;

  display: flex;
  position: relative;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2.25rem 3.5rem;

  img {
    height: 16rem;
    width: 16rem;
    object-fit: contain;
  }

  a {
    position: absolute;
    top: 5%;
    left: 2%;

    @media (max-width: 800px) {
      left: 10%;

      background-image: linear-gradient(
        180deg,
        var(--background) 0% var(--shape) 100%
      );
      a {
        top: 3%;
        left: 5%;
      }
    }
  }
`;

export const ArrowIcon = styled(FontAwesomeIcon)`
  width: 1.5rem;
  height: 1.5rem;
  color: var(--orange_500);

  transition: filter 0.2s;
  &:hover {
    filter: brightness(0.8);
  }
`;

const appearFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const appearFromCenter = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Main = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 25rem;

  animation: ${appearFromTop} 1s;

  button {
    margin-top: 3rem;
    width: 100%;
    max-width: inherit;
  }

  h1 {
    font-weight: bold;
    font-size: 1.4rem;
    color: var(--text-title);
    margin-bottom: 3rem;
  }

  @media (max-width: 800px) {
    justify-content: flex-start;

    h1 {
      font-size: 1.6rem;
    }
    button {
      margin-top: 7rem;
    }
  }
`;

export const ResponseContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 25rem;

  animation: ${appearFromCenter} 1s;

  button {
    margin-top: 3rem;
  }

  h1,
  h2 {
    font-weight: bold;
    font-size: 1.4rem;
    color: var(--text-title);
  }

  h2 {
    font-size: 1.2rem;
    margin-top: 3rem;
    text-align: center;
  }

  @media (max-width: 800px) {
    justify-content: flex-start;

    h1 {
      font-size: 1.6rem;
    }

    h2 {
      font-size: 1.2rem;
    }

    button {
      margin-top: 7rem;
    }
  }
`;
