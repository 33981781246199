import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';

import Hero from '../../../assets/images/hero-tela-inicio.jpg';

interface FilterAdvancedButtonsProps {
  isSelected: boolean;
}

export const Container = styled.div`
  background: url(${Hero}) no-repeat center;
  background-size: cover;

  width: 100%;
  height: 31rem;

  padding: 1.625rem 5%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 400px) {
    height: 40rem;
  }
`;

export const Content = styled.div`
  max-width: 1220px;
  width: 100%;
`;

export const DetailsContainer = styled.div`
  div {
    max-width: 800px;

    .hero-title {
      font-weight: 600;
      line-height: 2.1rem;
      color: var(--white-text);
      font-size: 1.875rem;

      margin-bottom: 1.625rem;
      max-width: 26rem;
    }

    h2 {
      font-weight: 500;
      font-size: 1.3rem;

      line-height: 1.875rem;
      color: var(--white-text);
    }
  }
`;

export const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1% 2%;
  flex-direction: column;

  margin-top: 4rem;

  @media (max-width: 800px) {
    background: none;
  }
`;

export const SelectContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  width: 100%;
  padding: 0.75rem;
  border-radius: 0.5rem;

  div {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;

    & + div {
      margin-left: 1rem;
    }
  }

  .buttonsDiv {
    flex-direction: row;
    justify-content: end;
  }

  button {
    height: 2.877rem;
    max-width: 25%;
    margin-left: 0.75rem;
    font-size: 0.8rem;
  }

  .cleanButton {
    color: var(--text-body);
    background: none;
    border: none;
    font-size: 0.8rem;
    font-weight: 600;
  }

  @media (max-width: 800px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    background: none;
    padding: 0;

    div {
      & + div {
        margin-left: 0;
        border: none;
      }
    }
    button {
      margin-left: 0;
      max-width: 100%;
      font-size: 1rem;
    }

    .cleanButton {
      display: none;
    }
  }
`;

export const AdvancedContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;

  @media (max-width: 800px) {
    background-color: var(--shape);
    border: none;
    border-radius: 0.5rem;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 1rem;

    button {
      margin: 0;
      border: none;
      max-width: 100%;
    }
  }
`;

export const FilterAdvancedButtons = styled.button<FilterAdvancedButtonsProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1%;
  width: fit-content;
  margin-top: 0.5rem;
  border-radius: 0.5rem;
  border: none;
  background-color: var(--shape);
  font-size: 0.8rem;
  color: var(--blue_300);
  height: 2.5rem;

  ${(props) =>
    props.isSelected &&
    css`
      background-color: var(--blue_500);
      color: var(--shape);
    `}

  & + button {
    margin-left: 0.5rem;
  }

  @media (max-width: 800px) {
    width: 100%;
    max-width: 100%;

    border-radius: 0.5rem 0 0 0.5rem;
    & + button {
      margin-left: 0;
      border-radius: 0 0.5rem 0.5rem 0;
    }
  }
`;

export const ArrowIcon = styled(FontAwesomeIcon)`
  width: 1.2rem;
  height: 1.2rem;
  color: var(--blue_300);

  margin-left: auto;
`;
