import styled from 'styled-components';

export const SelectInput = styled.select`
  width: 100%;
  height: 2.4rem;

  border-radius: 0.5rem;
  font-size: 1.2rem;
  color: var(--blue_300);
  font-weight: 600;
  background-color: var(--shape);
  margin-top: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding: 0.5rem;

  border: none;

  option {
    margin: 40px;
    font-weight: 600;
  }
`;

export const LabelContainer = styled.label`
  font-weight: 600;
  font-size: 1.1rem;
  color: var(--text-body);
`;
