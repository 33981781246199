import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { Container, Header } from './style';

const SkeletonProfessionalListLoad: React.FC = () => {
  return (
    <Container>
      <Header>
        <div>
          <Skeleton circle className="avatar" />
          <Skeleton count={6} height={26} />
        </div>
        <Skeleton count={5} height={26} />
      </Header>
    </Container>
  );
};

export default SkeletonProfessionalListLoad;
