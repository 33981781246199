import { useCallback, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import * as Yup from 'yup';

import { useAuth } from '../../hooks/AuthContext';
import { LoadingContext } from '../../hooks/LoadingContext';

import Logo from '../../assets/health_to_go_logo.png';

import { ControllerInput } from '../../components/controllerInput';
import { Button } from '../../components/button';
import getValidationErrors from '../../utils/validateErrors';
import ErrorModal from '../../components/errorModal';

import {
  Container,
  SideContainer,
  ArrowIcon,
  Main,
  LogInOptions,
  ReminderContainer,
  ForgotPasswordContainer,
} from './style';

interface FormData {
  username: string;
  password: string;
}

const SignInComponent: React.FC = () => {
  const { SignIn } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [reminderMe, setReminderMe] = useState(false);
  const { SetIsLoading } = LoadingContext();

  const navigate = useNavigate();

  async function SignInForm(form: FormData) {
    try {
      formRef.current?.setErrors({});
      SetIsLoading(true);

      const schema = Yup.object().shape({
        username: Yup.string()
          .required('Email é obrigatório.')
          .email('Digite um Email válido.'),
        password: Yup.string().required('Senha é obrigatória.'),
      });

      await schema.validate(form, {
        abortEarly: false,
      });

      const userLoginData = {
        ...form,
        email: form.username,
        reminderMe,
      };

      SignIn(userLoginData)
        .then(() => {
          SetIsLoading(false);
          navigate('/');
        })
        .catch(() => {
          SetIsLoading(false);
          setIsOpenModal(true);
        });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const err = getValidationErrors(error);

        formRef.current?.setErrors(err);
        SetIsLoading(false);
      }
    }
  }

  const handleCloseModal = useCallback(() => {
    formRef.current?.clearField('password');
    setIsOpenModal(false);
  }, []);

  return (
    <Container>
      <SideContainer>
        <Link to="/">
          <ArrowIcon icon={faArrowLeft} />
        </Link>
        <img src={Logo} alt="Health to Go" />
      </SideContainer>

      <Main ref={formRef} onSubmit={SignInForm}>
        <h1>Entrar</h1>

        <ControllerInput type="email" name="username" label="Email" />

        <ControllerInput type="password" name="password" label="Senha" />

        <LogInOptions>
          <ReminderContainer>
            <input
              type="checkbox"
              onChange={(e) => setReminderMe(e.target.checked)}
            />
            <h3>Lembrar-me</h3>
          </ReminderContainer>
          <Link to="/password">Esqueci minha senha</Link>
        </LogInOptions>

        <Button type="submit" ButtonColor="yellow">
          Entrar
        </Button>

        <ForgotPasswordContainer>
          <h3>Venha para o nosso time!</h3>
          <Link to="/signup">Cadastre-se</Link>
        </ForgotPasswordContainer>
      </Main>
      <ErrorModal
        message="Não foi possível encontrar sua conta."
        isModalOpen={isOpenModal}
        functionProp={handleCloseModal}
      />
    </Container>
  );
};

export default SignInComponent;
