import styled from 'styled-components';

export const Container = styled.table`
  display: grid;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0.75rem 1.5rem 1.5rem;
  border: 1px solid var(--card-border);
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;

  tr {
    display: grid;
    grid-template-columns: 4fr 1fr 1fr 1fr;
    align-content: center;
    justify-items: flex-end;
    width: 100%;
    height: 2rem;
    color: var(--text-body);
    border-radius: 0.5rem;
    font-size: 1rem;

    &:nth-child(even) {
      background-color: #e2e2e2;
    }

    @media (max-width: 800px) {
      grid-template-columns: 2fr 1fr 1fr 1fr;
    }
  }

  .name-colum {
    justify-self: flex-start;
  }

  thead {
    width: 100%;
    height: 3rem;

    tr {
      border-bottom: none;

      @media (max-width: 800px) {
        font-size: 0.78rem;
      }
    }
    th {
      &:first-child {
        color: var(--orange_500);
      }
      color: var(--blue_300);
    }
  }

  tbody {
    tr {
      padding-inline: 0.75rem 0.3rem;
    }
  }

  tfoot {
    margin-top: 1rem;
    tr {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 500;
    }
  }
`;
