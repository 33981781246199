import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from '@unform/web';

export const Container = styled.div`
  position: relative;
  width: 100%;
  background-image: linear-gradient(
    90deg,
    var(--blue_300) 50%,
    var(--blue_500) 100%
  );
  height: 100svh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  @media (min-width: 800px) {
    flex-wrap: nowrap;
  }

  @media (max-width: 800px) {
    justify-content: center;
    background-image: linear-gradient(
      180deg,
      var(--blue_300) 50%,
      var(--blue_500) 100%
    );
  }
`;

export const SideContainer = styled.div`
  width: 29%;
  background-color: var(--shape);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
  border-top-right-radius: 3rem;
  padding: 0 1.5rem;

  a {
    position: absolute;

    top: 5%;
    left: 2%;
  }

  img {
    width: 100%;
    max-width: 24rem;
    height: 100%;
    max-height: 23.938rem;
    object-fit: contain;
  }

  @media (max-width: 800px) {
    max-height: 200px;
    width: 100%;
    margin-left: 0;
    margin-top: 0;
    border-top-right-radius: 0;

    img {
      max-height: 12rem;
      max-width: 12rem;
    }

    a {
      top: 3%;
      left: 5%;
    }
  }
`;

export const ArrowIcon = styled(FontAwesomeIcon)`
  width: 1.5rem;
  height: 1.5rem;
  color: var(--orange_500);

  transition: filter 0.2s;
  &:hover {
    filter: brightness(0.8);
  }
`;

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Main = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 2.25rem 3.5rem;
  width: 100%;
  max-width: 29.25rem;

  animation: ${appearFromRight} 1s;

  h1 {
    font-weight: bold;
    font-size: 2rem;
    color: var(--white-text);
    margin-bottom: 2.5rem;
  }

  button {
    align-self: center;
    width: 100%;
    max-width: inherit;
  }

  @media (max-width: 800px) {
    align-items: center;
    justify-content: flex-start;
    margin-left: 0;
    width: 100vw;

    h1 {
      font-size: 1.8rem;
    }
    button {
      margin: 2rem 0;
    }
  }

  @media (max-width: 400px) {
    button {
      margin: 0;
    }
  }
`;

export const LogInOptions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 1.5rem 0;

  h3 {
    font-weight: 600;
    font-size: 0.875rem;
    margin-left: 0.3rem;

    color: var(--text-body);
  }

  a {
    font-weight: 600;
    font-size: 0.875rem;
    color: var(--text-body);

    margin-left: 0.5rem;
    transition: color 0.2s;

    :hover {
      color: var(--orange_500);
    }
  }
`;

export const ReminderContainer = styled.div`
  display: flex;
  align-items: center;

  div {
    width: 1.35rem;
  }
  input {
    width: 1.35rem;
    height: 1.35rem;
  }
`;

export const ForgotPasswordContainer = styled.div`
  margin-top: 1.5rem;
  border-top: 0.05rem solid var(--blue_500);
  width: 100%;

  h3 {
    color: var(--white-text);
    margin-top: 1.5rem;
  }

  a {
    font-weight: 600;
    font-size: 1.375rem;
    color: var(--text-body);

    transition: color 0.2s;

    :hover {
      color: var(--orange_500);
    }
  }
`;
